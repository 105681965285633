import Badge from "@ui/Badge.tsx";
import Button from "@ui/Button.tsx";
import { Modal } from "@ui/Modal.tsx";
import { useTranslation } from "react-i18next";

import useAuth from "../hooks/useAuth.ts";
import useManageCard from "../hooks/useManageCard.ts";
import { ModalPropsType } from "../types";

import AddCardForm from "./SecondaryCardOrNumber/AddCardForm.tsx";

interface ConfirmRemoveProps extends ModalPropsType {
    card: string;
    onConfirm: () => void;
    loading: boolean;
}

const ConfirmRemove = (props: ConfirmRemoveProps) => {
    const { t } = useTranslation();
    const { isOpen, closeModal, card, loading, onConfirm } = props;

    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            className="rounded-md relative px-2 py-5"
        >
            <h5 className="font-semibold text-center mb-3">
                {t("Supprimer la carte")}
            </h5>

            <p className="text-center text-sm">
                {t("Etes-vous sûr de vouloir supprimer cette carte?")}
            </p>

            <p className="text-sm text-center font-medium my-5">{card}</p>

            <div className="flex items-center space-x-5 mt-5 justify-center">
                <Button withAuto loading={loading} onClick={onConfirm}>
                    {t("Confirmer")}
                </Button>

                <Button withAuto variant="outline" onClick={closeModal}>
                    {t("Annuler")}
                </Button>
            </div>
        </Modal>
    );
};

const ManageCard = () => {
    const { t } = useTranslation();
    const {
        selectCard,
        setSelectCard,
        showAddForm,
        setShowAddForm,
        cardName,
        cardNumber,
        setCardNumber,
        setCardName,
        adding,
        removing,
        handleAdd,
        handleRemove,
        errors,
        cardAlreadyRegisteredMyAccount,
        cardAlreadyRegisteredOtherAccount,
        cardAlreadyBlocked,
        limitCardsPerMonth
    } = useManageCard();
    const { user } = useAuth();

    return (
        <>
            {(user?.account?.transfer_cards || []).map((item, index) => (
                <div
                    key={index}
                    className="flex items-center justify-between mb-3"
                >
                    <p className="text-sm">{item.card_number}</p>

                    {!item.activated_at && (
                        <Badge color="red">{t("Bloquée")}</Badge>
                    )}

                    <button
                        className="text-sm text-red-500 underline"
                        onClick={() => setSelectCard(item)}
                    >
                        {t("Supprimer")}
                    </button>
                </div>
            ))}

            {user?.account?.transfer_cards &&
                user?.account?.transfer_cards?.length < 3 && (
                    <>
                        {showAddForm ? (
                            <>
                                <AddCardForm
                                    cardName={cardName}
                                    setCardName={setCardName}
                                    cardNumber={cardNumber}
                                    setCardNumber={setCardNumber}
                                    errors={errors}
                                    cardAlreadyRegisteredMyAccount={
                                        cardAlreadyRegisteredMyAccount
                                    }
                                    cardAlreadyRegisteredOtherAccount={
                                        cardAlreadyRegisteredOtherAccount
                                    }
                                    cardAlreadyBlocked={cardAlreadyBlocked}
                                    limitCardsPerMonth={limitCardsPerMonth}
                                />

                                <div className="flex items-center space-x-5 mt-5">
                                    <Button
                                        type="submit"
                                        withAuto
                                        size="xs"
                                        className="py-2 px-8"
                                        loading={adding}
                                        onClick={() => {
                                            handleAdd();
                                        }}
                                    >
                                        {t("Valider")}
                                    </Button>

                                    <Button
                                        type="button"
                                        withAuto
                                        size="xs"
                                        className="py-2 px-8"
                                        variant="outline"
                                        onClick={() => {
                                            if (!adding) {
                                                cardNumber && setCardNumber("");
                                                cardName && setCardName("");
                                                setShowAddForm(false);
                                            }
                                        }}
                                    >
                                        {t("Annuler")}
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <Button
                                withAuto
                                size="xs"
                                className="py-2 px-8 mt-8"
                                onClick={() => setShowAddForm(true)}
                            >
                                {t("Ajouter")}
                            </Button>
                        )}
                    </>
                )}

            <ConfirmRemove
                loading={removing}
                isOpen={!!selectCard}
                closeModal={() => !removing && setSelectCard(null)}
                card={selectCard?.card_number || ""}
                onConfirm={() => handleRemove()}
            />
        </>
    );
};

export default ManageCard;
