import { Modal } from "@ui/Modal.tsx";
import Tab, { TabItem } from "@ui/tab";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth.ts";
import useModal from "../../hooks/useModal.ts";
import LoCloseIcon from "../icons/LoCloseIcon.tsx";

import AddCard from "./AddCard.tsx";
import AddNumber from "./AddNumber.tsx";
import SelectCardOrNumber from "./SelectCardOrNumber.tsx";

const AddCardOrNumberButton = () => {
    const { t } = useTranslation();

    const [choice, setChoice] = useState("1");

    const { isLogin, user, kycActionData } = useAuth();
    const { isOpen, openModal, closeModal } = useModal();

    const canAdd = useMemo(() => {
        const limitNumber =
            !!user?.account?.transfer_phone_numbers &&
            user?.account?.transfer_phone_numbers?.length < 2;

        const limitCard =
            !!user?.account?.transfer_cards &&
            user?.account?.transfer_cards?.length < 3;

        return {
            transfer: limitNumber && kycActionData.transferValidate,
            card: limitCard && kycActionData.cardValidate,
            limit: {
                card: limitCard,
                number: limitNumber
            }
        };
    }, [
        kycActionData.cardValidate,
        kycActionData.transferValidate,
        user?.account?.transfer_cards,
        user?.account?.transfer_phone_numbers
    ]);

    return (
        <>
            {isLogin && (
                <>
                    {(canAdd.transfer || canAdd.card) && (
                        <button
                            type="button"
                            className="flex items-center space-x-1 text-xs text-gray-600"
                            onClick={openModal}
                        >
                            <span className="border rounded-full flex items-center justify-center w-3.5 h-3.5 border-gray-600">
                                <LoCloseIcon size="xs" className="rotate-45" />
                            </span>

                            <span>{t("Ajout carte/numéro")}</span>
                        </button>
                    )}

                    <Modal
                        isOpen={isOpen}
                        closeModal={closeModal}
                        className="overflow-visible"
                    >
                        <h5 className="font-semibold text-center mb-3">
                            {t("Ajouter un numéro")} <br />
                            {t("ou une carte pour les transferts")}
                        </h5>

                        <Tab activeIndex="1">
                            <TabItem index="1">
                                <p className="text-center text-sm mb-3">
                                    {t(
                                        "Saisir un numéro différent pour les transferts en sélectionnant l'indicatif"
                                    )}
                                </p>
                            </TabItem>

                            <TabItem index="2">
                                <p className="text-center text-sm mb-3">
                                    {t(
                                        "Veuillez ajouter les informations de votre carte. La validation pourrait prendre 48H ouvré."
                                    )}
                                </p>
                            </TabItem>

                            <SelectCardOrNumber
                                className="justify-center"
                                choice={choice}
                                setChoice={setChoice}
                            />

                            <TabItem index={"1"}>
                                {canAdd.transfer ? (
                                    <AddNumber closeModal={closeModal} />
                                ) : (
                                    <>
                                        {canAdd.limit.number ? (
                                            <p className="text-center text-gray-600 text-sm">
                                                {t(
                                                    "Vous avez atteint le nombre de numéro secondaire ou votre KYC transfert n'est pas validée"
                                                )}
                                            </p>
                                        ) : (
                                            <>
                                                {!kycActionData.transferValidate && (
                                                    <p className="text-center text-gray-600 text-sm">
                                                        {t(
                                                            "Votre profil est en cours de vérification."
                                                        )}
                                                    </p>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </TabItem>

                            <TabItem index={"2"}>
                                {canAdd.card ? (
                                    <AddCard
                                        closeModal={() => {
                                            setChoice("1");
                                            closeModal();
                                        }}
                                    />
                                ) : (
                                    <>
                                        {!kycActionData.cardValidate ? (
                                            <p className="text-center text-gray-600 text-sm">
                                                {t(
                                                    "Votre profil est en cours de vérification."
                                                )}
                                            </p>
                                        ) : (
                                            <>
                                                {canAdd.limit.card && (
                                                    <p className="text-center text-gray-600 text-sm">
                                                        {t(
                                                            "Vous avez déjà atteint le maximum de carte"
                                                        )}
                                                    </p>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </TabItem>
                        </Tab>
                    </Modal>
                </>
            )}
        </>
    );
};

export default AddCardOrNumberButton;
