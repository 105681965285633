import Button from "@ui/Button.tsx";
import { Modal } from "@ui/Modal.tsx";
import PinInput from "@ui/PinInput";
import {
    FormEvent,
    Fragment,
    useCallback,
    useLayoutEffect,
    useMemo,
    useState
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { DEFAULT_COUNTRY_INDICATIVE } from "../constants";
import ENDPOINTS from "../constants/endpoint.ts";
import {
    cn,
    getIndicativeByCountryCode,
    loadAuthServiceHost
} from "../helpers";
import useAuth from "../hooks/useAuth.ts";
import useManageAddNumber from "../hooks/useManageAddNumber.tsx";
import useToast from "../hooks/useToast.ts";
import { RootState } from "../store";
import { updateAuthUser } from "../store/authSlice.ts";
import { resetSenderData } from "../store/transferSlice.ts";
import { ModalPropsType, SecondaryPhoneType } from "../types";

import PhoneInput from "./PhoneInput.tsx";

interface Props extends ModalPropsType {
    number: string;
    onConfirm: () => void;
    loading: boolean;
}

interface FormProps {
    handleSubmit: (e: FormEvent) => void;
    showAddForm: boolean;
    indicative: string;
    setIndicative: (value: string) => void;
    phone: string;
    setPhone: (value: string) => void;
    errors: { [key: string]: string };
    showOtpForm: boolean;
    setOtp: (value: string) => void;
    resendOtp: () => void;
    loading: boolean;
    setShowAddForm: (value: boolean) => void;
    setShowOtpForm: (value: boolean) => void;
}

const ConfirmRemove = (props: Props) => {
    const { t } = useTranslation();
    const { isOpen, closeModal, number, loading, onConfirm } = props;

    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            className="rounded-md relative p-2"
        >
            <h5 className="font-semibold text-center mb-3">
                {t("Supprimer le numéro d'envoi")}
            </h5>

            <p className="text-center text-sm">
                {t("Vous être sur le point de supprimer ce numéro.")}
            </p>

            <p className="text-center text-sm mb-3">{t("Etes-vous sûr?")}</p>

            <p className="text-sm text-center font-medium">{number}</p>

            <div className="flex items-center space-x-5 mt-5 justify-center">
                <Button withAuto loading={loading} onClick={onConfirm}>
                    {t("Confirmer")}
                </Button>

                <Button withAuto variant="outline" onClick={closeModal}>
                    {t("Annuler")}
                </Button>
            </div>
        </Modal>
    );
};

const Form = (props: FormProps) => {
    const { t } = useTranslation();
    const {
        handleSubmit,
        showAddForm,
        indicative,
        setIndicative,
        phone,
        setPhone,
        showOtpForm,
        errors,
        setOtp,
        resendOtp,
        loading,
        setShowAddForm,
        setShowOtpForm
    } = props;

    return (
        <form action="" className="mb-4" onSubmit={handleSubmit}>
            {showAddForm && (
                <PhoneInput
                    placeholder={t("Numéro")}
                    indicative={indicative}
                    onIndicativeChange={value => setIndicative(value)}
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    error={errors?.phone_with_indicative}
                />
            )}

            {showOtpForm && (
                <>
                    <p className="mb-2 text-sm">{t("Code OTP reçu par sms")}</p>

                    <PinInput
                        pinItemClassName="w-1/4"
                        length={4}
                        onChange={value => setOtp(value)}
                        errorMessage={errors?.otp}
                    />

                    <p className="text-xs mt-1 text-end">
                        {t("Code non reçu?")}{" "}
                        <button
                            type="button"
                            className="text-primary underline"
                            onClick={resendOtp}
                        >
                            {t("Renvoyer")}
                        </button>
                    </p>
                </>
            )}

            <div className="flex items-center space-x-5 mt-5">
                <Button
                    type="submit"
                    withAuto
                    size="xs"
                    className="py-2 px-8"
                    loading={loading}
                >
                    {showAddForm ? t("Valider") : t("Confirmer")}
                </Button>

                <Button
                    type="button"
                    withAuto
                    size="xs"
                    className="py-2 px-8"
                    variant="outline"
                    onClick={() => {
                        phone.length && setPhone("");
                        showAddForm && setShowAddForm(false);
                        showOtpForm && setShowOtpForm(false);
                    }}
                >
                    {t("Annuler")}
                </Button>
            </div>
        </form>
    );
};

const ManageNumber = () => {
    const { t } = useTranslation();
    const [selectNumber, setSelectNumber] = useState<SecondaryPhoneType | null>(
        null
    );
    const [removeLoading, setRemoveLoading] = useState(false);

    const {
        handleSubmit,
        showAddForm,
        indicative,
        phone,
        errors,
        showOtpForm,
        resendOtp,
        setShowAddForm,
        setShowOtpForm,
        setOtp,
        loading,
        setIndicative,
        handleResendOtp,
        setPhone
    } = useManageAddNumber();

    const { successToast } = useToast();
    const { position } = useSelector((state: RootState) => state.position);
    const dispatch = useDispatch();
    const { user } = useAuth();

    const canAdd = useMemo(() => {
        return (
            !!user?.account?.transfer_phone_numbers &&
            user?.account?.transfer_phone_numbers?.length < 2
        );
    }, [user?.account?.transfer_phone_numbers]);

    useLayoutEffect(() => {
        if (position) {
            const positionIndicative = getIndicativeByCountryCode(
                position.countryCode
            );

            setIndicative(positionIndicative || DEFAULT_COUNTRY_INDICATIVE);
        }
    }, [position, setIndicative]);

    const refreshUserData = useCallback(() => {
        loadAuthServiceHost();
        window.axios
            .get(ENDPOINTS.ME)
            .then(response => {
                dispatch(updateAuthUser({ user: response.data.data }));
                successToast(t("Number delete successfully"));
                setRemoveLoading(false);
                setSelectNumber(null);
                dispatch(resetSenderData());
            })
            .finally(() => setRemoveLoading(false));
    }, [dispatch, successToast, t]);

    const handleClickConfirm = useCallback(() => {
        setRemoveLoading(true);
        loadAuthServiceHost();
        window.axios
            .delete(
                ENDPOINTS.REMOVE_SECONDARY_NUMBER.replace(
                    ":id",
                    selectNumber?.id.toString() || ""
                )
            )
            .then(() => {
                setSelectNumber(null);
                refreshUserData();
            })
            .finally(() => setRemoveLoading(false));
    }, [refreshUserData, selectNumber?.id]);

    const secondaryPhoneNumberList = useMemo(() => {
        if (!user?.account?.transfer_phone_numbers) return [];

        return user?.account?.transfer_phone_numbers.map(
            item => item.phone_with_indicative.split(" ")[1] || ""
        );
    }, [user?.account?.transfer_phone_numbers]);

    const checkPhoneMatchOneSecondaryPhone = useMemo(() => {
        const foundElement = secondaryPhoneNumberList.find(
            item => item === phone
        );

        return !!foundElement;
    }, [phone, secondaryPhoneNumberList]);

    return (
        <>
            <p className="text-sm mb-3">{user?.phone_with_indicative}</p>

            {user?.account?.transfer_phone_numbers?.map((item, index) => (
                <Fragment key={index}>
                    <div className="flex items-center justify-between mb-3">
                        <div>
                            <p
                                className={cn({
                                    "text-sm": true,
                                    "text-gray-400": !item.validated_at
                                })}
                            >
                                {item.phone_with_indicative}
                            </p>

                            {!item.validated_at &&
                                (!showOtpForm ||
                                    !item.phone_with_indicative.includes(
                                        phone
                                    )) && (
                                    <button
                                        type="button"
                                        className="focus:outline-none text-xs underline text-primary"
                                        onClick={() => {
                                            phone.length && setPhone("");
                                            showAddForm &&
                                                setShowAddForm(false);
                                            handleResendOtp(
                                                item.phone_with_indicative
                                            );
                                        }}
                                    >
                                        {t("Valider le numéro")}
                                    </button>
                                )}
                        </div>

                        <button
                            className="text-red-600 underline text-sm"
                            onClick={() => {
                                phone.length && setPhone("");
                                showOtpForm && setShowOtpForm(false);
                                setSelectNumber(item);
                            }}
                        >
                            {t("Supprimer")}
                        </button>
                    </div>

                    {phone.length > 0 &&
                        item.phone_with_indicative.split(" ")[1] === phone &&
                        (showAddForm || showOtpForm) && (
                            <>
                                <Form
                                    handleSubmit={handleSubmit}
                                    showAddForm={showAddForm}
                                    indicative={indicative}
                                    setIndicative={setIndicative}
                                    phone={phone}
                                    setPhone={setPhone}
                                    showOtpForm={showOtpForm}
                                    errors={errors}
                                    setOtp={setOtp}
                                    resendOtp={resendOtp}
                                    loading={loading}
                                    setShowOtpForm={setShowOtpForm}
                                    setShowAddForm={setShowAddForm}
                                />
                            </>
                        )}
                </Fragment>
            ))}

            {!user?.account?.transfer_phone_numbers?.length &&
                (showAddForm || showOtpForm) && (
                    <>
                        <Form
                            handleSubmit={handleSubmit}
                            showAddForm={showAddForm}
                            indicative={indicative}
                            setIndicative={setIndicative}
                            phone={phone}
                            setPhone={setPhone}
                            showOtpForm={showOtpForm}
                            errors={errors}
                            setOtp={setOtp}
                            resendOtp={resendOtp}
                            loading={loading}
                            setShowOtpForm={setShowOtpForm}
                            setShowAddForm={setShowAddForm}
                        />
                    </>
                )}

            {!!user?.account?.transfer_phone_numbers?.length &&
                (showAddForm || showOtpForm) &&
                !checkPhoneMatchOneSecondaryPhone && (
                    <>
                        <Form
                            handleSubmit={handleSubmit}
                            showAddForm={showAddForm}
                            indicative={indicative}
                            setIndicative={setIndicative}
                            phone={phone}
                            setPhone={setPhone}
                            showOtpForm={showOtpForm}
                            errors={errors}
                            setOtp={setOtp}
                            resendOtp={resendOtp}
                            loading={loading}
                            setShowOtpForm={setShowOtpForm}
                            setShowAddForm={setShowAddForm}
                        />
                    </>
                )}

            {!(showAddForm || showOtpForm) &&
                canAdd &&
                user?.account?.transfer_kyc_status === "validated" && (
                    <Button
                        withAuto
                        size="xs"
                        className="py-2 px-8 mt-8"
                        onClick={() => {
                            showOtpForm && setShowOtpForm(false);
                            phone.length && setPhone("");
                            setShowAddForm(true);
                        }}
                    >
                        {t("Ajouter")}
                    </Button>
                )}

            <ConfirmRemove
                loading={removeLoading}
                isOpen={!!selectNumber}
                closeModal={() => !removeLoading && setSelectNumber(null)}
                number={selectNumber?.phone_with_indicative || ""}
                onConfirm={handleClickConfirm}
            />
        </>
    );
};

export default ManageNumber;
