import { ReactNode } from "react";

import { cn } from "../../helpers";

interface Props {
    children: ReactNode;
    color?: "yellow" | "orange" | "red" | "green" | "blue";
}

const Badge = (props: Props) => {
    const { color = "blue", children } = props;

    return (
        <span
            className={cn({
                "px-3 py-0.5 text-xs rounded-full": true,
                "bg-ember-100 text-ember-600": color === "orange",
                "bg-blue-100 text-blue-600": color === "blue",
                "bg-green-100 text-green-600": color === "green",
                "bg-red-100 text-red-600": color === "red",
                "bg-yellow-100 text-yellow-600": color === "yellow"
            })}
        >
            {children}
        </span>
    );
};

export default Badge;
