import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ENDPOINTS from "../constants/endpoint.ts";
import { loadTransferServiceHost } from "../helpers";
import { RootState } from "../store";
import { storeNotifications } from "../store/notificationSlice.ts";

const useFetchNotifications = () => {
    const { isLogin } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();

    const fetchNotifications = useCallback(() => {
        loadTransferServiceHost();
        window.axios.get(ENDPOINTS.NOTIFICATIONS).then(response => {
            dispatch(storeNotifications(response.data.data || []));
        });
    }, [dispatch]);

    useEffect(() => {
        if (isLogin) {
            fetchNotifications();
        }
    }, [dispatch, fetchNotifications, isLogin]);

    return {
        fetchNotifications
    };
};

export default useFetchNotifications;
