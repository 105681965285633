import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import CGUButton from "../components/CGUButton.tsx";
import InviteFriendButton from "../components/InviteFriendButton.tsx";
import Main from "../components/layouts/Main.tsx";
import SettingAccount from "../components/SettingAccount.tsx";
import SettingServiceClient from "../components/SettingServiceClient.tsx";
import {
    SettingWizard,
    SettingWizardButton,
    SettingWizardItem
} from "../components/SettingWizard.tsx";
import { cn } from "../helpers";
import useAuth from "../hooks/useAuth.ts";
import useLogout from "../hooks/useLogout.ts";
import usePageTitle from "../hooks/usePageTitle.ts";
import { RootState } from "../store";

const Setting = () => {
    const { t } = useTranslation();
    usePageTitle(t("Paramètres"));
    const logout = useLogout();
    const { logoutLoading } = useSelector((state: RootState) => state.ui);
    const { isLogin, kycActionData } = useAuth();

    return (
        <Main info={t("headerTitle1")}>
            <div
                className={cn(
                    "bg-white min-h-[calc(100vh-105px)] lg:min-h-[calc(100vh-130px)]",
                    "lg:flex divide-y-2 lg:divide-y-0 lg:divide-x-2 divide-dashed"
                )}
            >
                <SettingWizard
                    defaultIndex={
                        isLogin && !kycActionData.transferRejected ? 1 : 2
                    }
                >
                    <div className="py-12 px-6 lg:w-1/2 flex flex-col items-center justify-between">
                        <div className="w-full">
                            {isLogin && !kycActionData.transferRejected && (
                                <>
                                    <SettingWizardButton
                                        title={t("Compte")}
                                        index={1}
                                    >
                                        {t(
                                            "Changer le code secret ou supprimer le compte"
                                        )}
                                    </SettingWizardButton>
                                    <hr className="my-2" />
                                </>
                            )}

                            <SettingWizardButton
                                title={t("Contacter le service client")}
                                index={2}
                            >
                                {t("Contacter le support via whatsapp")}
                            </SettingWizardButton>
                            <hr className="my-2" />

                            <InviteFriendButton />
                            <hr className="my-2" />

                            <CGUButton />
                            <hr className="my-2" />

                            {isLogin && (
                                <>
                                    <SettingWizardButton
                                        title={t("Déconnexion")}
                                        index={5}
                                        onClick={() =>
                                            logout(
                                                t("Succès de la déconnexion")
                                            )
                                        }
                                        loading={logoutLoading}
                                    >
                                        {t("Quitter l’application")}
                                    </SettingWizardButton>
                                    <hr className="my-2" />
                                </>
                            )}
                        </div>
                    </div>

                    <div className="py-12 px-6 lg:w-1/2 flex justify-center">
                        {isLogin && !kycActionData.transferRejected && (
                            <SettingWizardItem index={1}>
                                <SettingAccount />
                            </SettingWizardItem>
                        )}

                        <SettingWizardItem index={2}>
                            <SettingServiceClient />
                        </SettingWizardItem>

                        <SettingWizardItem index={3}>
                            <div className="lg:hidden p-10 bg-white" />
                        </SettingWizardItem>

                        <SettingWizardItem index={4}>
                            <div className="lg:hidden p-10 bg-white" />
                        </SettingWizardItem>

                        {isLogin && (
                            <SettingWizardItem index={5}>
                                <div className="lg:hidden p-10 bg-white" />
                            </SettingWizardItem>
                        )}
                    </div>
                </SettingWizard>
            </div>
        </Main>
    );
};

export default Setting;
