import { useCallback, useState } from "react";

type CopiedValue = string | null;

const useCopyToClipboard = () => {
    const [copiedText, setCopiedText] = useState<CopiedValue>(null);

    const copy = useCallback(async (text: string) => {
        if (!navigator?.clipboard) {
            return false;
        }

        // Try to save to clipboard then save it in the state if worked
        try {
            await navigator.clipboard.writeText(text);
            setCopiedText(text);
        } catch (error) {
            setCopiedText(null);
            return false;
        }
    }, []);

    return { copiedText, copy };
};

export default useCopyToClipboard;
