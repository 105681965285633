import Button from "@ui/Button.tsx";
import { Modal } from "@ui/Modal.tsx";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import PAGES from "../../constants/pages.ts";
import { ModalPropsType } from "../../types";
import { BfIcon, BjIcon, CiIcon, GnIcon, SnIcon, TgIcon } from "../flags";
import MyDunyaLogo from "../MyDunyaLogo.tsx";

const InfoDescriptionModal = (props: ModalPropsType) => {
    const { isOpen, closeModal } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClickButton = useCallback(() => {
        closeModal();
        navigate(PAGES.TRANSFER);
    }, [closeModal, navigate]);

    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            hiddeCloseIcon
            className="min-w-full lg:min-w-[650px]"
        >
            <div className="min-h-[264px] rounded-lg bg-white flex flex-col items-center md:items-start justify-end md:justify-start w-full p-3 bg-cover bg-center md:bg-left-bottom bg-[url('/images/info-mobile.jpg')] md:bg-[url('/images/info.jpg')]">
                <MyDunyaLogo
                    src="/images/logo-without-bg.png"
                    className="shadow-none bg-none w-24 mb-6 bg-opacity-0 hidden md:block"
                />

                <h3 className="text-base md:text-xl font-medium mb-4 mt-48 md:mt-0 text-secondary">
                    {t("Envoyer de l'argent facilement")}
                    <br />
                    {t("à partir de 1,5% depuis et vers")}
                </h3>

                <div className="flex items-center space-x-4 mb-4">
                    <SnIcon className="h-8 w-8 border rounded-full shadow-sm bg-white" />
                    <CiIcon className="h-8 w-8 border rounded-full shadow-sm bg-white" />
                    <BjIcon className="h-8 w-8 border rounded-full shadow-sm bg-white" />
                    <BfIcon className="h-8 w-8 border rounded-full shadow-sm bg-white" />
                    <TgIcon className="h-8 w-8 border rounded-full shadow-sm bg-white" />
                    <GnIcon className="h-8 w-8 border rounded-full shadow-sm bg-white" />
                </div>
            </div>

            <p className="text-center my-10 text-gray-700 text-xs md:text-sm">
                {t(
                    "Découvrez MyDunya, votre nouvelle solution simple, rapide et sécurisée pour envoyer de l'argent à vos proches depuis votre Mobile Money vers n’importe quel autre Mobile Money en Côte d’Ivoire, Burkina, Mali, Sénégal, Bénin et Togo."
                )}
            </p>

            <Button
                className="mb-6 px-20 mx-auto text-sm md:text-base"
                withAuto
                onClick={handleClickButton}
            >
                {t("Je fais mon 1er transfert maintenant")}
            </Button>

            <div className="text-center">
                <button
                    onClick={closeModal}
                    className="text-gray-500 mx-auto underline text-sm font-medium"
                >
                    {t("Fermer")}
                </button>
            </div>
        </Modal>
    );
};

export default InfoDescriptionModal;
