import { ENV_PREFIX } from "../constants";

export default function env(
    key: string,
    defaultValue: string | number | null = null
) {
    return key
        ? import.meta.env[!key.startsWith(ENV_PREFIX) ? ENV_PREFIX + key : key]
        : defaultValue;
}
