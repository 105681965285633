import { cn } from "../helpers";

interface Props {
    src: string;
}

const WalletPicture = (props: Props) => {
    return (
        <div
            className={cn(
                "w-[30px] h-[30px] lg:w-[45px] lg:h-[45px] p-0.5 border border-opacity-20 rounded-full bg-white shadow-sm",
                "flex items-center justify-center"
            )}
        >
            <img
                className="object-cover object-center rounded-full"
                width="35"
                src={props.src}
                alt=""
            />
        </div>
    );
};

export default WalletPicture;
