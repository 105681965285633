import { Modal } from "@ui/Modal.tsx";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RESPONSE_STATUS } from "../../../constants";
import ENDPOINTS from "../../../constants/endpoint.ts";
import PAGES from "../../../constants/pages.ts";
import { loadAuthServiceHost } from "../../../helpers";
import useToast from "../../../hooks/useToast.ts";
import { logout } from "../../../store/authSlice.ts";
import { ModalPropsType } from "../../../types";
import TrashIcon from "../../icons/TrashIcon.tsx";

import LevelOne from "./LevelOne.tsx";
import LevelThree from "./LevelThree.tsx";
import LevelTwo from "./LevelTwo.tsx";

const RemoveAccountModal = (props: ModalPropsType) => {
    const { t } = useTranslation();
    const [error, setError] = useState<string[]>([]);
    const [pin, setPin] = useState("");
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(true);
    const [showConfirmCode, setShowConfirmCode] = useState(false);
    const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);

    const navigate = useNavigate();
    const { successToast, errorToast } = useToast();
    const dispatch = useDispatch();

    const handleCloseModal = useCallback(() => {
        if (!loading) {
            props.closeModal();
            if (showConfirmCode || showRemoveConfirm) {
                setTimeout(() => {
                    showConfirmCode && setShowConfirmCode(false);
                    showRemoveConfirm && setShowRemoveConfirm(false);
                    !showAlert && setShowAlert(true);
                }, 300);
            }
        }
    }, [loading, props, showAlert, showConfirmCode, showRemoveConfirm]);

    const submitConfirmCode = useCallback(async () => {
        setLoading(true);
        await loadAuthServiceHost();
        window.axios
            .post(ENDPOINTS.VERIFY_PIN, { pin })
            .then(() => {
                successToast(t("Code secret confirmé avec succès"));
                setShowConfirmCode(false);
                setShowRemoveConfirm(true);
            })
            .catch(error => {
                errorToast(t("Code secret incorrect"));
                if (
                    error.response?.status ===
                    RESPONSE_STATUS.UNPROCESSABLE_ENTITY
                ) {
                    setError(error.response.data?.pin || []);
                }
            })
            .finally(() => setLoading(false));
    }, [errorToast, pin, successToast, t]);

    const submitConfirmRemove = useCallback(() => {
        setLoading(true);
        loadAuthServiceHost();
        window.axios
            .delete(ENDPOINTS.DELETE_USER)
            .then(() => {
                dispatch(logout());
                handleCloseModal();
                navigate(PAGES.REGISTER);
                successToast(
                    t("Succès de l'opération"),
                    t("Votre compte à été supprimer avec succès")
                );
            })
            .catch(() => {
                errorToast(t("Échec de la suppression du compte"));
            })
            .finally(() => setLoading(false));
    }, [dispatch, errorToast, handleCloseModal, navigate, successToast, t]);

    return (
        <Modal isOpen={props.isOpen} closeModal={handleCloseModal}>
            <TrashIcon className="text-red-500 mx-auto mt-5" size="xl" />

            <LevelOne
                show={showAlert}
                onClickCancel={handleCloseModal}
                onClickContinue={() => {
                    setShowAlert(false);
                    setShowConfirmCode(true);
                }}
            />

            <LevelTwo
                show={showConfirmCode}
                handleSubmitForm={submitConfirmCode}
                setPin={setPin}
                error={error}
                loading={loading}
                onClickCancel={handleCloseModal}
            />

            <LevelThree
                show={showRemoveConfirm}
                onClickContinue={submitConfirmRemove}
                loading={loading}
                onClickCancel={handleCloseModal}
            />
        </Modal>
    );
};

export default RemoveAccountModal;
