import React, { InputHTMLAttributes } from "react";

import { cn } from "../../helpers";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    children?: React.ReactNode;
    checkboxClassName?: string;
    textClassName?: string;
}

const Checkbox = (props: Props) => {
    const {
        children,
        className,
        checkboxClassName,
        id,
        textClassName,
        ...rest
    } = props;

    return (
        <label
            className={cn({
                "cursor-pointer flex items-center space-x-4": true,
                "cursor-not-allowed": rest.disabled,
                [className || ""]: true
            })}
            htmlFor={id}
            role="label"
        >
            <input
                id={id}
                className={cn({
                    "rounded-full cursor-pointer border-gray-300": true,
                    "text-blue-600 shadow-sm": true,
                    "focus:border-blue-600 focus:ring": true,
                    "focus:ring-offset-0": true,
                    "focus:ring-blue-200 focus:ring-opacity-50": true,
                    "cursor-not-allowed": rest.disabled,
                    [checkboxClassName || ""]: true
                })}
                type="radio"
                {...rest}
            />
            {!!children && (
                <p
                    className={cn(
                        "text-xs text-gray-700 select-none",
                        textClassName
                    )}
                >
                    {children}
                </p>
            )}
        </label>
    );
};

export default Checkbox;
