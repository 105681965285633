import React, { useCallback, useState } from "react";

import { STORAGE } from "../constants";
import { cn, storage } from "../helpers";

import LoCloseIcon from "./icons/LoCloseIcon.tsx";

// import AppleIcon from "./icons/AppleIcon.tsx";
// import GooglePlayStoreIcon from "./icons/GooglePlayStoreIcon.tsx";

interface Props {
    children: React.ReactNode;
    showCloseIcon?: boolean;
    persist?: boolean;
    className?: string;
}

const InfoMessage = (props: Props) => {
    const { showCloseIcon = false, persist = false, className } = props;

    const [isOpen, setIsOpen] = useState(true);

    const handleClose = useCallback(() => {
        setIsOpen(!isOpen);
        if (persist) {
            storage().setItem(STORAGE.ALERT_COMPLETE_PROFILE, "true");
        }
    }, [isOpen, persist]);

    if (persist && storage().getItem(STORAGE.ALERT_COMPLETE_PROFILE)) {
        return null;
    }

    if (!isOpen) return null;

    return (
        <div
            className={cn(
                "relative p-3 bg-[#009FBC5D] text-sm text-[#42389F] flex items-center justify-between",
                className
            )}
        >
            <p className="w-full text-center lg:text-left">{props.children}</p>

            {showCloseIcon && (
                <LoCloseIcon
                    className="cursor-pointer absolute right-4"
                    onClick={handleClose}
                />
            )}

            {/*<div className="items-center space-x-10 text-gray-700 hidden lg:flex">
                <a
                    href="#app-store"
                    // target="_blank"
                    className="flex items-center py-1 px-4 bg-white rounded-xl space-x-3"
                    rel="noreferrer"
                >
                    <AppleIcon className="text-gray-800" size="lg" />

                    <div>
                        <p className="text-xs">Download on the</p>
                        <p className="text-lg leading-6">App Store</p>
                    </div>
                </a>

                <a
                    href="#play-store"
                    // target="_blank"
                    className="flex items-center py-1 px-4 bg-white rounded-xl space-x-3"
                    rel="noreferrer"
                >
                    <GooglePlayStoreIcon className="text-gray-800" size="lg" />

                    <div>
                        <p className="text-xs">Download on</p>
                        <p className="text-lg leading-6">Google play</p>
                    </div>
                </a>
            </div>*/}
        </div>
    );
};

export default InfoMessage;
