import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../store";
import { closeSuspendModal, showSuspendModal } from "../store/uiSlice.ts";

const useSuspendModal = () => {
    const { suspendModal } = useSelector((state: RootState) => state.ui);
    const dispatch = useDispatch();

    const showModal = useCallback(
        (reason: string) => {
            dispatch(showSuspendModal(reason));
        },
        [dispatch]
    );

    const closeModal = useCallback(() => {
        dispatch(closeSuspendModal());
    }, [dispatch]);

    return {
        isOpen: suspendModal.show,
        reason: suspendModal.reason,
        showModal,
        closeModal
    };
};

export default useSuspendModal;
