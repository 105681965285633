import React from "react";
import { useTranslation } from "react-i18next";

import useThemeColor from "../../hooks/useThemeColor.ts";
import { COLOR } from "../../types";
import GrRefreshIcon from "../icons/GrRefreshIcon.tsx";
import LangSwitcher from "../LangSwitcher.tsx";
import MyDunyaLogo from "../MyDunyaLogo.tsx";

interface Props {
    children?: React.ReactNode;
    title?: string;
}

interface PictureProps {
    src?: string;
    alt?: string;
}

const WalletPicture = (props: PictureProps) => {
    return (
        <img
            className="w-10 h-10 bg-white border-2 border-white rounded-full transition-all duration-300"
            src={props.src}
            alt={props.alt}
        />
    );
};

const WALLETS_PICTURE = [
    "/images/wallet/orange-money.png",
    "/images/wallet/wave.png",
    "/images/wallet/mtn.png",
    "/images/wallet/moov.png",
    "/images/wallet/t-money.jpeg",
    "/images/wallet/free-money-two.png",
    "/images/wallet/wizall.png",
    "/images/wallet/e-money.png"
];

const AuthLayout = (props: Props) => {
    const { t } = useTranslation();
    useThemeColor(COLOR.WHITE);

    return (
        <div className="relative min-h-screen flex text-gray-500">
            <div className="min-h-screen w-full lg:w-1/2 p-5">
                <div className="flex flex-col items-center justify-center h-full bg-white rounded-xl">
                    <div className="w-full lg:w-11/12 xl:w-3/4 px-6">
                        <MyDunyaLogo
                            src="/images/logo-without-bg.png"
                            className="shadow-none bg-none w-20 -mb-12 mx-auto md:hidden"
                        />
                        {props.children}
                    </div>
                </div>
            </div>

            <div className="lg:flex -space-x-28 items-center justify-center min-h-screen hidden w-1/2 p-5">
                <div className="relative bg-[#103357] rounded-xl h-full w-full overflow-hidden">
                    <div className="relative pt-12 px-16 z-10">
                        <MyDunyaLogo />

                        <h3 className="text-3xl font-semibold text-white mb-4">
                            {t("slogan.part1")} <br />
                            {t("slogan.part2")} <br />
                            {t("slogan.part3")}
                        </h3>

                        <div className="inline-flex items-center -space-x-4 cursor-pointer hover:space-x-2 group">
                            {WALLETS_PICTURE.map((item, index) => (
                                <WalletPicture key={index} src={item} />
                            ))}
                        </div>
                    </div>

                    <img
                        src="/images/login-end.png"
                        className="object-cover object-center absolute z-0 bottom-0 opacity-50"
                        alt=""
                    />
                </div>
            </div>

            <div className="absolute top-10 left-12 flex items-center">
                <LangSwitcher className="" itemsContainerClassName="-left-1" />

                <button
                    type="button"
                    className="py-5 pl-3 pr-6 mx-3 cursor-pointer"
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        window.location.reload();
                    }}
                >
                    <GrRefreshIcon />
                </button>
            </div>
        </div>
    );
};

export default AuthLayout;
