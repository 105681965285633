import { DropdownItem } from "@ui/Dropdown.tsx";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import PAGES from "../../constants/pages.ts";
import { SimpleLink } from "../links";

const NotificationFooter = () => {
    const { t } = useTranslation();
    return (
        <div className="py-3 border-t text-center">
            <DropdownItem as={Fragment}>
                <SimpleLink to={PAGES.NOTIFICATION}>
                    {t("Voir plus")}
                </SimpleLink>
            </DropdownItem>
        </div>
    );
};

export default NotificationFooter;
