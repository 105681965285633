import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";

import { DEFAULT_COUNTRY_INDICATIVE } from "../constants";
import { getIndicativeByCountryCode } from "../helpers";
import { RootState } from "../store";

const useInitPhoneIndicative = (setIndicative: (value: string) => void) => {
    const { position } = useSelector((state: RootState) => state.position);

    useLayoutEffect(() => {
        if (position) {
            const positionIndicative = getIndicativeByCountryCode(
                position.countryCode
            );

            setIndicative(positionIndicative || DEFAULT_COUNTRY_INDICATIVE);
        }
    }, [position, setIndicative]);
};

export default useInitPhoneIndicative;
