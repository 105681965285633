import Input from "@ui/Input.tsx";
import { useTranslation } from "react-i18next";

import { cn } from "../helpers";
import { InputProps } from "../types";

import SearchIcon from "./icons/SearchIcon.tsx";
import Loading from "./Loading.tsx";

interface Props extends InputProps {
    divClassName?: string;
    loading?: boolean;
}

const SearchInput = (props: Props) => {
    const { t } = useTranslation();
    const { loading, divClassName, ...rest } = props;
    return (
        <div className={cn("relative", divClassName)}>
            <Input
                type="text"
                className="px-10 rounded-full"
                placeholder={t("Rechercher montant, nom ou numéro")}
                {...rest}
            />

            <SearchIcon className="text-gray-400 absolute top-[1.1rem] left-3" />

            {loading && (
                <Loading className="text-primary absolute top-[1.1rem] right-4" />
            )}
        </div>
    );
};

export default SearchInput;
