import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { cn } from "../helpers";
import useAuth from "../hooks/useAuth.ts";
import useCopyToClipboard from "../hooks/useCopyToClipboard.tsx";
import useManageWalletChannels from "../hooks/useManageWalletChannels.ts";
import { formatDate } from "../libs/date.ts";
import { StatusType, TransferType } from "../types";

import CopyClipboardButton from "./CopyClipboardButton.tsx";
import MatchStatusIcon from "./MatchStatusIcon.tsx";

interface Props {
    transfer: TransferType;
    service: string;
}

const TransferDetail = (props: Props) => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const { transfer } = props;

    const { copy } = useCopyToClipboard();
    const { getCountryById } = useManageWalletChannels();

    const getReceiveAmount = useCallback(() => {
        if (transfer.support_fees)
            return parseFloat(transfer.amount.toString());

        return (
            parseFloat(transfer.amount.toString()) - parseFloat(transfer.fees)
        );
    }, [transfer.amount, transfer.fees, transfer.support_fees]);

    const getSendAmount = useCallback(() => {
        if (!transfer.support_fees)
            return parseFloat(transfer.amount.toString());

        return (
            parseFloat(transfer.amount.toString()) +
            parseFloat(transfer.fees.toString())
        );
    }, [transfer.amount, transfer.fees, transfer.support_fees]);

    const country = useMemo(() => {
        const payInCountry = transfer?.pay_in_channel?.country_id
            ? getCountryById(true, transfer?.pay_in_channel?.country_id)
            : null;

        const payOutCountry = transfer?.pay_out_channel?.country_id
            ? getCountryById(false, transfer?.pay_out_channel?.country_id)
            : null;

        return {
            payIn: payInCountry || null,
            payOut: payOutCountry || null
        };
    }, [
        getCountryById,
        transfer?.pay_in_channel?.country_id,
        transfer?.pay_out_channel?.country_id
    ]);

    return (
        <>
            <hr className="mt-5 mb-3" />

            {transfer?.status === StatusType.pending && (
                <p className="mb-5 text-center font-semibold text-gray-700">
                    {t(
                        "Votre transaction pourrait prendre 24h ouvré, veuillez patienter le temps du traitement ..."
                    )}
                </p>
            )}

            <div className="text-gray-400 mb-2">
                <p>{formatDate(transfer.created_at, "lll")}</p>
            </div>
            <div className="flex items-end justify-between">
                <div>
                    <p>{t("Montant envoyé")}</p>
                    <p>{t("Montant reçu")}</p>
                    <p>{t("Frais")}</p>
                    <p>ID</p>
                    <p>{t("Service")}</p>
                    <p>{t("Statut")}</p>
                </div>

                <div className="text-end">
                    <p>{getSendAmount()} Fcfa</p>
                    <p>{getReceiveAmount()} Fcfa</p>
                    <p>{parseFloat(transfer.fees)} Fcfa</p>
                    <div className="inline-flex items-center justify-end space-x-1">
                        <p className="truncate max-w-[120px] md:max-w-full text-xs">
                            {transfer.identifier || "---"}
                        </p>

                        {transfer?.identifier && (
                            <CopyClipboardButton
                                onClick={() => copy(transfer?.identifier || "")}
                            />
                        )}
                    </div>
                    <p>{props.service}</p>
                    <p
                        className={cn({
                            "pl-2": true,
                            "inline-flex items-center justify-between": true,
                            "text-green-500":
                                transfer.status === StatusType.success,
                            "text-orange-500":
                                transfer.status === StatusType.pending,
                            "text-red-500":
                                transfer.status === StatusType.failed
                        })}
                    >
                        {transfer.status === StatusType.success && t("Success")}
                        {transfer.status === StatusType.pending &&
                            t("En attente")}
                        {transfer.status === StatusType.failed && t("Echec")}
                        <MatchStatusIcon
                            status={transfer.status}
                            size="sm"
                            className="ml-2"
                        />
                    </p>
                </div>
            </div>

            <hr className="my-3" />

            <div className="flex items-start justify-between">
                <div>
                    <p>{t("Envoyer de")}</p>
                    <p>
                        {transfer.pay_in_channel?.country_id
                            ? t("N° de téléphone")
                            : t("N° de carte")}
                    </p>
                    <p>{t("Envoyer par")}</p>
                    <p>
                        {transfer.pay_in_channel?.country_id
                            ? t("Référence du wallet")
                            : t("Référence")}
                    </p>
                </div>

                <div className="text-end">
                    <p
                        className={cn({
                            "opacity-0": !user
                        })}
                    >
                        {user?.full_name || "---"}
                    </p>

                    <p>
                        {transfer?.pay_in_channel?.country_id
                            ? transfer.sender_phone
                            : transfer?.sender_transfer_card?.card_number ||
                              "---"}
                    </p>

                    <p>
                        {transfer.pay_in_channel?.country_id ? (
                            <>
                                {transfer?.pay_in_channel?.name || "---"} (
                                {country.payIn?.label || "---"})
                            </>
                        ) : (
                            t("Carte bancaire")
                        )}
                    </p>

                    <div className="inline-flex items-center justify-end space-x-1">
                        <p className="md:truncate break-words max-w-[120px] md:max-w-full">
                            {transfer?.ref_pay_in_partner || "---"}
                        </p>
                        {transfer?.ref_pay_in_partner && (
                            <CopyClipboardButton
                                onClick={() =>
                                    copy(transfer?.ref_pay_in_partner || "")
                                }
                            />
                        )}
                    </div>
                </div>
            </div>

            <hr className="my-3" />

            <div className="flex items-start justify-between">
                <div>
                    <p>{t("Envoyer à")}</p>
                    <p>{t("N° de téléphone")}</p>
                    <p>{t("Envoyer sur")}</p>
                    <p>{t("Référence du wallet")}</p>
                </div>

                <div className="text-end">
                    <p
                        className={cn({
                            "opacity-0": !transfer?.receiver_name
                        })}
                    >
                        {transfer?.receiver_name || "---"}
                    </p>
                    <p>{transfer.receiver_phone}</p>
                    <p>
                        {transfer?.pay_out_channel?.name || "---"} (
                        {country.payOut?.label || "---"})
                    </p>
                    <div className="inline-flex items-center justify-end space-x-1">
                        <p className="md:truncate break-words max-w-[120px] md:max-w-full">
                            {transfer?.ref_pay_out_partner || "---"}
                        </p>
                        {transfer?.ref_pay_out_partner && (
                            <CopyClipboardButton
                                onClick={() =>
                                    copy(transfer?.ref_pay_out_partner || "")
                                }
                            />
                        )}
                    </div>
                </div>
            </div>

            <hr className="mt-3 mb-5" />
        </>
    );
};

export default TransferDetail;
