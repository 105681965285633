import Button from "@ui/Button.tsx";
import CheckboxInput from "@ui/CheckboxInput.tsx";
import TakePicture from "@ui/TakePicture.tsx";
import { FormEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import * as yup from "yup";

import ChevronIcon from "../components/icons/ChevronIcon.tsx";
import InputField from "../components/InputField.tsx";
import { AuthLayout } from "../components/layouts";
import PhoneInput from "../components/PhoneInput.tsx";
import { DEFAULT_COUNTRY_INDICATIVE, RESPONSE_STATUS } from "../constants";
import ENDPOINTS from "../constants/endpoint.ts";
import PAGES from "../constants/pages.ts";
import { loadAuthServiceHost, phoneIsValid } from "../helpers";
import useInitPhoneIndicative from "../hooks/useInitPhoneIndicative.ts";
import useManageSchemaValidationError from "../hooks/useManageSchemaValidationError.ts";
import usePageTitle from "../hooks/usePageTitle.ts";
import useRedirectConfirmAccount from "../hooks/useRedirectConfirmAccount.ts";
import useToast from "../hooks/useToast.ts";
import rules from "../schemas";

const RegisterSchema = yup.object().shape({
    // photo: rules.identityCard,
    pin: rules.pin,
    confirm_pin: rules.confirmPin("pin"),
    phone_with_indicative: rules.phone,
    full_name: rules.fullName
});

const PHONE_NUMBER_USE_LIKE_SECONDARY_NUMBER =
    "phone_number_with_indicative_has_already_registered_like_secondary_phone";

const LONG_DURATION = 10 * 1000;

const Register = () => {
    const { t } = useTranslation();
    usePageTitle(t("Inscription"));

    const [loading, setLoading] = useState(false);
    const [indicative, setIndicative] = useState(DEFAULT_COUNTRY_INDICATIVE);
    const [photo, setPhoto] = useState<File | null>(null);
    const [selfie, setSelfie] = useState<File | null>(null);
    const [pin, setPin] = useState("");
    const [confirmPin, setConfirmPin] = useState("");
    const [fullName, setFullName] = useState("");
    const [phoneWithIndicatif, setPhoneWithIndicatif] = useState("");
    const [condition, setCondition] = useState(false);

    useInitPhoneIndicative(setIndicative);
    const { successToast, errorToast } = useToast();
    const { showErrors, resetErrors, errors, setErrors } =
        useManageSchemaValidationError();
    const { redirect } = useRedirectConfirmAccount();

    const onSubmit = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            const sendData = {
                photo: photo,
                pin: pin,
                confirm_pin: confirmPin,
                phone_with_indicative: phoneWithIndicatif,
                full_name: fullName,
                selfie_photo_device: "web",
                selfie_photo: selfie
            };

            RegisterSchema.validate(sendData, { abortEarly: false })
                .then(() => {
                    if (condition) {
                        let phoneNumber = `${indicative}${phoneWithIndicatif}`;

                        if (!phoneIsValid(phoneNumber)) {
                            setErrors({
                                ...errors,
                                phone_with_indicative: t("Invalid phone number")
                            });
                            return;
                        }

                        const formData = new FormData();
                        phoneNumber = `${indicative} ${phoneWithIndicatif}`;
                        formData.append("phone_with_indicative", phoneNumber);
                        formData.append("full_name", fullName);
                        formData.append("selfie_photo_device", "web");
                        formData.append("pin", pin);
                        if (photo) {
                            formData.append("photo", photo);
                        }

                        if (selfie) {
                            formData.append("selfie_photo", selfie);
                        }
                        setLoading(true);
                        Object.keys(errors).length && setErrors({});
                        loadAuthServiceHost();
                        window.axios
                            .post(ENDPOINTS.REGISTER, formData, {
                                headers: {
                                    "content-type": "multipart/form-data"
                                }
                            })
                            .then(() => {
                                resetErrors();
                                setPin("");
                                setConfirmPin("");
                                setFullName("");
                                successToast(
                                    t("Succès de l'inscription"),
                                    t(
                                        "Connectez-vous pour profiter de toutes les fonctionnalités."
                                    )
                                );
                                redirect(phoneNumber);
                            })
                            .catch(error => {
                                resetErrors();
                                const status = error.response?.status;
                                const key = error.response?.data.data.key;
                                const errorsResponse =
                                    error.response?.data.errors;
                                if (
                                    status ===
                                    RESPONSE_STATUS.UNPROCESSABLE_ENTITY
                                ) {
                                    setErrors(errorsResponse);
                                }

                                if (status === RESPONSE_STATUS.CONFLICT) {
                                    switch (key) {
                                        case PHONE_NUMBER_USE_LIKE_SECONDARY_NUMBER:
                                            errorToast(
                                                t("Echec de l'inscription"),
                                                t(
                                                    "Ce numéro est déjà utilisé comme numéro secondaire sur un autre compte"
                                                ),
                                                LONG_DURATION
                                            );
                                            setErrors(error => ({
                                                ...error,
                                                phone_with_indicative: t(
                                                    "Ce numéro est déjà utilisé comme numéro secondaire sur un autre compte"
                                                )
                                            }));
                                            break;
                                        default:
                                            errorToast(
                                                t(
                                                    "Cet utilisateur a déjà un compte"
                                                )
                                            );
                                            break;
                                    }
                                } else {
                                    errorToast(t("Echec de l'inscription"));
                                }
                            })
                            .finally(() => setLoading(false));
                    } else {
                        errorToast(
                            t("Echec de soumission du formulaire"),
                            t("Veuillez accepter les condition d'utilisation")
                        );
                    }
                })
                .catch(showErrors());
        },
        [
            condition,
            confirmPin,
            errorToast,
            errors,
            fullName,
            indicative,
            phoneWithIndicatif,
            photo,
            pin,
            redirect,
            resetErrors,
            selfie,
            setErrors,
            showErrors,
            successToast,
            t
        ]
    );

    return (
        <AuthLayout>
            <form className="space-y-5" onSubmit={onSubmit}>
                <h4 className="text-lg text-gray-700 font-medium mt-12 text-center md:text-left">
                    {t("Créer un compte")}
                </h4>

                <InputField
                    id="full-name"
                    type="text"
                    placeholder={t("Nom et Prénom")}
                    value={fullName}
                    onChange={e => setFullName(e.target.value)}
                    error={errors.full_name}
                />

                <PhoneInput
                    id="phone-number"
                    placeholder={t("Numéro")}
                    indicative={indicative}
                    onIndicativeChange={value => setIndicative(value)}
                    value={phoneWithIndicatif}
                    onChange={e => setPhoneWithIndicatif(e.target.value)}
                    error={errors.phone_with_indicative}
                />

                <TakePicture
                    required={true}
                    label={t("Photo carte d'identité/passeport")}
                    value={photo}
                    onPictureTake={file => setPhoto(file)}
                    errorMessage={errors.photo}
                />

                <TakePicture
                    // required={true}
                    label={t("Selfie avec carte d'identité/passeport")}
                    value={selfie}
                    cameraType="user"
                    onPictureTake={file => setSelfie(file)}
                    errorMessage={errors.selfie_photo}
                />

                <InputField
                    id="secret-code"
                    isPin
                    type="password"
                    inputMode="numeric"
                    placeholder={t("Code secret")}
                    value={pin}
                    onChange={e => setPin(e.target.value)}
                    error={errors.pin}
                />

                <InputField
                    id="confirm-secret-code"
                    isPin
                    type="password"
                    inputMode="numeric"
                    placeholder={t("Confirmer le code secret")}
                    value={confirmPin}
                    onChange={e => setConfirmPin(e.target.value)}
                    error={errors.confirm_pin}
                />

                <CheckboxInput
                    className="justify-center mt-4"
                    checkboxClassName="cursor-pointer"
                    id="policy-condition"
                    name="check-condition"
                    checked={condition}
                    onChange={e => setCondition(e.target.checked)}
                >
                    {t("J'accepte les")}{" "}
                    <NavLink
                        to={PAGES.PRIVACY_POLICY}
                        className="text-primary hover:underline"
                    >
                        {t("conditions générales d’utilisation")}
                    </NavLink>
                    .
                </CheckboxInput>

                <Button
                    loading={loading}
                    disabled={!condition}
                    type="submit"
                    data-cy="submit"
                    className="justify-start px-7"
                    textContainerClassName="flex items-center justify-between w-full"
                >
                    <span>{t("Créer")}</span>
                    <ChevronIcon className="w-3 h-3 -rotate-90" />
                </Button>

                <p className="text-sm text-center lg:hidden">
                    {t("Vous avez un compte ?")}{" "}
                    <NavLink
                        to={PAGES.LOGIN}
                        className="text-primary hover:underline"
                    >
                        {t("Se connecter")}
                    </NavLink>{" "}
                    <br /> <br />
                    <NavLink
                        to={PAGES.HOME}
                        className="text-primary hover:underline"
                    >
                        {t("1er transfert sans connexion")}
                    </NavLink>
                </p>

                <div className="hidden lg:flex items-center justify-between text-sm font-medium pb-5">
                    <div>
                        <p>{t("Vous avez un compte ?")}</p>

                        <NavLink
                            to={PAGES.LOGIN}
                            className="text-primary hover:underline"
                        >
                            {t("Se connecter")}
                        </NavLink>
                    </div>

                    <div className="text-right">
                        <p>{t("Voulez vous essayer")}</p>

                        <NavLink
                            to={PAGES.HOME}
                            className="text-primary hover:underline"
                        >
                            {t("1er transfert sans connexion")}
                        </NavLink>
                    </div>
                </div>
            </form>
        </AuthLayout>
    );
};

export default Register;
