import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import PAGES from "../../constants/pages.ts";
import { cn } from "../../helpers";
import SettingIcon from "../icons/SettingIcon.tsx";

const SettingLink = () => {
    const { t } = useTranslation();
    return (
        <NavLink
            className={({ isActive }) => {
                return cn({
                    "py-4 px-4 inline": true,
                    "lg:px-12 lg:py-3 lg:flex lg:flex-col items-center justify-center":
                        true,
                    "lg:bg-white lg:text-gray-600 lg:rounded-r-lg": isActive
                });
            }}
            to={PAGES.SETTING}
        >
            <SettingIcon size="lg" />
            <span className="hidden lg:inline text-sm">{t("Paramètres")}</span>
        </NavLink>
    );
};

export default SettingLink;
