import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import PAGES from "../../constants/pages.ts";
import env from "../../helpers/env.ts";
import { RootState } from "../../store";
import FaCircleUser from "../icons/FaCircleUser.tsx";
import FiLoginIcon from "../icons/FiLoginIcon.tsx";
import GrRefreshIcon from "../icons/GrRefreshIcon.tsx";
import SendIcon from "../icons/SendIcon.tsx";
// import StayLinkedIcon from "../icons/StayLinkedIcon.tsx";
import LangSwitcher from "../LangSwitcher.tsx";
import NavbarLink from "../links/NavbarLink.tsx";
import SettingLink from "../links/SettingLink.tsx";
import NotificationDropdown from "../notifications/NotificationDropdown.tsx";

const Nav = () => {
    const { t } = useTranslation();
    const { isLogin } = useSelector((state: RootState) => state.auth);
    const { pathname } = useLocation();

    return (
        <nav className="relative text-white flex items-center justify-between bg-primary lg:shadow-sm z-[999px]">
            <div className="flex">
                <SettingLink />

                {isLogin && <NotificationDropdown />}

                {(pathname === PAGES.TRANSFER || pathname === PAGES.HOME) &&
                    !isLogin && (
                        <>
                            <NavbarLink className="px-4" to={PAGES.REGISTER}>
                                <FaCircleUser />
                                <span className="relative top-1">
                                    {t("Inscription")}
                                </span>
                            </NavbarLink>

                            <NavbarLink className="px-4" to={PAGES.LOGIN}>
                                <FiLoginIcon />
                                <span className="relative top-1">
                                    {t("Connexion")}
                                </span>
                            </NavbarLink>
                        </>
                    )}
            </div>

            <div className="flex items-center absolute left-1/2 -translate-x-1/2">
                <span className="font-semibold lg:hidden">
                    {env("APP_NAME")}
                </span>

                {pathname !== PAGES.REGISTER && (
                    <>
                        <NavbarLink
                            to={PAGES.TRANSFER}
                            active={window.location.pathname === PAGES.HOME}
                        >
                            <SendIcon />
                            <span>{t("Transfert")}</span>
                        </NavbarLink>

                        {/*{isLogin && (
                            <NavbarLink to={PAGES.COLLECT}>
                                <StayLinkedIcon />
                                <span>{t("Collect")}</span>
                            </NavbarLink>
                        )}*/}
                    </>
                )}
            </div>

            <div className="flex items-center lg:space-x-3">
                <LangSwitcher />

                <button
                    type="button"
                    className="py-5 pl-3 pr-6 mx-3 inline cursor-pointer lg:hidden z-5 relative"
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        window.location.reload();
                    }}
                >
                    <GrRefreshIcon />
                </button>

                <NavbarLink
                    className="px-4"
                    to={PAGES.REFRESH}
                    onClick={() => window.location.reload()}
                >
                    <GrRefreshIcon />
                </NavbarLink>
                {/*<a className="py-5 px-2 lg:px-4 bg-pink-200 inline relative z-0 opacity-0 lg:hidden">
                    <BackIcon />
                </a>*/}
            </div>
        </nav>
    );
};

export default Nav;
