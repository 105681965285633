import React, { useEffect, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import config from "../config";
import { STORAGE } from "../constants";
import PAGES from "../constants/pages.ts";
import { auth, storage } from "../helpers";
import useAuth from "../hooks/useAuth.ts";
import useToast from "../hooks/useToast.ts";
import { RootState } from "../store";
import { setStoreRedirectReviewKyc } from "../store/uiSlice.ts";

const navigateState = { previousUrl: window.location.pathname };

interface Props {
    children: React.ReactElement;
}

export const Auth = ({ children }: Props) => {
    return (
        <>
            {auth()?.isLogin ? (
                children
            ) : (
                <Navigate
                    to={config.auth.redirectLogoutUrl}
                    state={navigateState}
                />
            )}
        </>
    );
};

export const Guest = ({ children }: Props) => {
    return (
        <>
            {!auth()?.isLogin ? (
                children
            ) : (
                <Navigate
                    to={config.auth.redirectLoginUrl}
                    state={navigateState}
                />
            )}
        </>
    );
};

export const ConfirmAccountM = ({ children }: Props) => {
    const condition =
        !auth()?.isLogin && storage().getItem(STORAGE.CONFIRM_ACCOUNT_TEL);
    const { t } = useTranslation();

    const { errorToast } = useToast();

    useLayoutEffect(() => {
        if (!condition) {
            errorToast(
                t("Vous ne pouvez pas accéder à cette page"),
                t("Veuillez vous inscrire avant de confirmer votre compte")
            );
        }
    }, [condition, errorToast, t]);

    return (
        <>
            {condition ? (
                children
            ) : (
                <Navigate to={PAGES.REGISTER} state={navigateState} />
            )}
        </>
    );
};

export const ToReviewKyc = ({ children }: Props) => {
    const { kycActionData, isLogin } = useAuth();
    const navigate = useNavigate();
    const { redirectReviewKyc } = useSelector((state: RootState) => state.ui);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!redirectReviewKyc && isLogin && kycActionData.toReview) {
            dispatch(setStoreRedirectReviewKyc(true));
            navigate(PAGES.CORRECT_KYC);
        }
    }, [
        dispatch,
        isLogin,
        kycActionData.toReview,
        navigate,
        redirectReviewKyc
    ]);

    return children;
};
