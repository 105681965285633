import { Transition } from "@headlessui/react";
import Tab, { TabItem } from "@ui/tab";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import PAGES from "../constants/pages.ts";
import { cn } from "../helpers";
import useAuth from "../hooks/useAuth.ts";
import useToggle from "../hooks/useToggle.ts";

import UpdatePinForm from "./forms/UpdatePinForm.tsx";
import ChevronIcon from "./icons/ChevronIcon.tsx";
import ManageCard from "./ManageCard.tsx";
import ManageNumber from "./ManageNumber.tsx";
import RemoveAccountButton from "./RemoveAccountButton.tsx";
import SelectCardOrNumber from "./SecondaryCardOrNumber/SelectCardOrNumber.tsx";
import SettingButton from "./SettingButton.tsx";

const ToggleTransition = ({
    show,
    children
}: {
    show: boolean;
    children: ReactNode;
}) => {
    return (
        <Transition
            show={show}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            className="p-6 relative z-[5]"
        >
            {children}
        </Transition>
    );
};

const SettingAccount = () => {
    const { t } = useTranslation();
    const [showForm, toggle] = useToggle(false);
    const [showManageNumber, toggleManageNumber] = useToggle(false);
    const ref = useRef<HTMLButtonElement | null>(null);
    const [radio, setRadio] = useState("1");

    const location = useLocation();
    const { kycActionData } = useAuth();

    useEffect(() => {
        const path = `${location.pathname}${location.search}`;
        if (path === PAGES.SETTING_LOCATION) {
            if (ref.current) {
                ref.current.click();
                ref.current = null;
            }
        }
    }, [location]);

    useEffect(() => {
        if (kycActionData.transferValidate) {
            setRadio("1");
        }

        if (kycActionData.cardValidate) {
            setRadio("2");
        }
    }, [kycActionData.cardValidate, kycActionData.transferValidate]);

    return (
        <div className="w-full lg:w-2/3">
            <div>
                <SettingButton
                    onClick={() => toggle()}
                    title={t("Changer le code secret")}
                    className="relative"
                >
                    {t("Modifier le code secret du compte")}
                    <ChevronIcon
                        size="sm"
                        className={cn({
                            "absolute top-6 right-6": true,
                            "transition duration-300": true,
                            "-rotate-90": !showForm
                        })}
                    />
                </SettingButton>

                <hr />

                <ToggleTransition show={showForm}>
                    <UpdatePinForm />
                </ToggleTransition>

                {(kycActionData.transferValidate ||
                    kycActionData.cardValidate) && (
                    <>
                        <SettingButton
                            ref={ref}
                            onClick={() => toggleManageNumber()}
                            title={t("Gérer mes cartes bancaires et numéros")}
                            className="relative"
                        >
                            {t(
                                "Ajouter et gérer les cartes et numéros d'envoi. 3 cartes et 3 numéros maximum autorisés."
                            )}
                            <ChevronIcon
                                size="sm"
                                className={cn({
                                    "absolute top-6 right-6": true,
                                    "transition duration-300": true,
                                    "-rotate-90": !showManageNumber
                                })}
                            />
                        </SettingButton>

                        <hr />

                        <ToggleTransition show={showManageNumber}>
                            <Tab activeIndex={radio}>
                                <SelectCardOrNumber
                                    choice={radio}
                                    setChoice={setRadio}
                                />

                                <TabItem index={"1"}>
                                    {kycActionData.transferRejected ? (
                                        <p className="text-sm text-gray-600">
                                            {t(
                                                "Votre kyc transfert a été rejeté."
                                            )}
                                        </p>
                                    ) : (
                                        <>
                                            {kycActionData.transferValidate ? (
                                                <ManageNumber />
                                            ) : (
                                                <p className="text-sm text-gray-600">
                                                    {t(
                                                        "Votre profil est en cours de vérification."
                                                    )}
                                                </p>
                                            )}
                                        </>
                                    )}
                                </TabItem>

                                <TabItem index={"2"}>
                                    {kycActionData.cardRejected ? (
                                        <p className="text-sm text-gray-600">
                                            {t("Votre kyc carte a été rejeté.")}
                                        </p>
                                    ) : (
                                        <>
                                            {kycActionData.cardValidate ? (
                                                <ManageCard />
                                            ) : (
                                                <p className="text-sm text-gray-600">
                                                    {t(
                                                        "Votre profil est en cours de vérification."
                                                    )}
                                                </p>
                                            )}
                                        </>
                                    )}
                                </TabItem>
                            </Tab>
                        </ToggleTransition>
                    </>
                )}

                <RemoveAccountButton />
                <hr />
            </div>
        </div>
    );
};

export default SettingAccount;
