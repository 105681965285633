import React, { MouseEventHandler } from "react";

import { cn } from "../../helpers";
import Loading from "../Loading.tsx";

interface Props {
    type?: "button" | "submit";
    children?: React.ReactNode;
    color?: "primary" | "secondary";
    variant?: "outline" | "default" | "none";
    loading?: boolean;
    disabled?: boolean;
    className?: string;
    textContainerClassName?: string;
    withAuto?: boolean;
    size?: "xs" | "md";
    onClick?: (
        e?: React.MouseEvent<HTMLButtonElement>
    ) => MouseEventHandler<HTMLButtonElement> | void;
    id?: string;
}

const Button = (props: Props) => {
    const {
        type = "button",
        children,
        color = "primary",
        variant = "default",
        disabled = false,
        loading = false,
        className = "",
        withAuto = false,
        size = "md",
        textContainerClassName,
        ...rest
    } = props;

    return (
        <button
            type={type}
            className={cn({
                "flex items-center justify-center rounded-md text-white": true,
                "w-full space-x-0.5 disabled:cursor-not-allowed": true,
                "focus:outline-none focus:ring": true,
                "bg-primary focus:border-primary focus:ring-primary/20":
                    color === "primary",
                "disabled:bg-[#97A5AA]": color === "primary",
                "py-3 text-sm font-medium": size === "md",
                "py-1 text-xs": size === "xs",
                "px-6 w-auto": withAuto,
                "bg-transparent": variant === "outline",
                "text-primary border border-primary":
                    variant === "outline" && color === "primary",
                "text-secondary border border-secondary":
                    variant === "outline" && color === "secondary",
                "cursor-not-allowed bg-secondary": disabled || loading,
                [className]: true
            })}
            disabled={loading ? loading : disabled}
            {...rest}
        >
            {loading && <Loading />}
            <span
                className={cn(
                    "flex items-center space-x-3",
                    textContainerClassName
                )}
            >
                {children}
            </span>
        </button>
    );
};

export default Button;
