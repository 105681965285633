import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import PAGES from "../../constants/pages.ts";
import { cn } from "../../helpers";
import { RootState } from "../../store";
import FaCircleUser from "../icons/FaCircleUser.tsx";
import FiLoginIcon from "../icons/FiLoginIcon.tsx";
import SendIcon from "../icons/SendIcon.tsx";
import MobileLink from "../links/MobileLink.tsx";

const Header = () => {
    const { t } = useTranslation();
    const { isLogin } = useSelector((state: RootState) => state.auth);
    const { pathname } = useLocation();

    return (
        <header
            role="header"
            className={cn({
                "bg-white px-2 md:px-6 flex items-center lg:hidden": true,
                "justify-between": pathname !== PAGES.REGISTER,
                "justify-center": pathname === PAGES.REGISTER
            })}
        >
            {pathname === PAGES.REGISTER && !isLogin ? (
                <MobileLink to={PAGES.REGISTER}>
                    <FaCircleUser />
                    <span className="ml-2">{t("Inscription")}</span>
                </MobileLink>
            ) : (
                <>
                    <MobileLink to={PAGES.TRANSFER}>
                        <SendIcon />
                        <span className="ml-2">{t("Transfert")}</span>
                    </MobileLink>

                    {(pathname === PAGES.TRANSFER || pathname === PAGES.HOME) &&
                        !isLogin && (
                            <>
                                <MobileLink to={PAGES.REGISTER}>
                                    <FaCircleUser />
                                    <span className="ml-2 truncate">
                                        {t("Inscription")}
                                    </span>
                                </MobileLink>

                                <MobileLink to={PAGES.LOGIN}>
                                    <FiLoginIcon />
                                    <span className="ml-2 truncate">
                                        {t("Connexion")}
                                    </span>
                                </MobileLink>
                            </>
                        )}
                </>
            )}
        </header>
    );
};

export default Header;
