import Button from "@ui/Button.tsx";
import PinInput from "@ui/PinInput";
import { useTranslation } from "react-i18next";

interface Props {
    show?: boolean;
    handleSubmitForm: () => void;
    setPin: (value: string) => void;
    error: string[];
    loading?: boolean;
    onClickCancel?: () => void;
}

const LevelTwo = (props: Props) => {
    const { t } = useTranslation();
    if (!props.show) return null;

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                props.handleSubmitForm();
            }}
        >
            <h5 className="text-center text-sm font-medium mt-12 mb-5">
                {t("Confirmation de code secret")}
            </h5>

            <p className="text-sm text-gray-600 mb-2">{t("Code secret")}</p>

            <PinInput
                type="number"
                length={4}
                onChange={value => props.setPin(value)}
                className="mb-3"
                pinItemClassName="w-1/4"
                errorMessage={props.error}
            />

            <div className="flex items-center gap-6 mt-8">
                <Button loading={props.loading} type="submit" className="w-1/2">
                    {t("Confirmer")}
                </Button>

                <Button
                    className="w-1/2"
                    onClick={props.onClickCancel}
                    variant="outline"
                >
                    {t("Annuler")}
                </Button>
            </div>
        </form>
    );
};

export default LevelTwo;
