import React from "react";

import { cn } from "../../helpers";

interface Props {
    children: React.ReactNode;
    className?: string;
}

const AppLayoutSection = (props: Props) => {
    return (
        <section
            className={cn(
                "col-span-3 lg:col-span-2 bg-white pt-6 pb-4",
                props.className
            )}
        >
            {props.children}
        </section>
    );
};

export default AppLayoutSection;
