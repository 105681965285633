import Button from "@ui/Button.tsx";
import PinInput from "@ui/PinInput";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import PAGES from "../../constants/pages.ts";
import useManageAddNumber from "../../hooks/useManageAddNumber.tsx";
import PhoneInput from "../PhoneInput.tsx";

interface Props {
    closeModal: () => void;
}

const AddNumber = (props: Props) => {
    const { closeModal } = props;

    const { t } = useTranslation();
    const {
        handleClickSubmitButton,
        showAddForm,
        indicative,
        phone,
        errors,
        showOtpForm,
        resendOtp,
        setShowAddForm,
        setShowOtpForm,
        setOtp,
        loading,
        setIndicative,
        setPhone
    } = useManageAddNumber(true);
    const navigate = useNavigate();

    return (
        <>
            {showAddForm && (
                <PhoneInput
                    placeholder={t("Numéro")}
                    indicative={indicative}
                    onIndicativeChange={value => setIndicative(value)}
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    error={errors?.phone_with_indicative}
                />
            )}

            {showOtpForm && (
                <>
                    <p className="mb-2 text-sm">{t("Code OTP reçu par sms")}</p>

                    <PinInput
                        pinItemClassName="w-1/4"
                        length={4}
                        onChange={value => setOtp(value)}
                        errorMessage={errors?.otp}
                    />

                    <p className="text-xs mt-1 text-end">
                        Code non reçu?{" "}
                        <button
                            type="button"
                            className="text-primary underline"
                            onClick={resendOtp}
                        >
                            Renvoyer
                        </button>
                    </p>
                </>
            )}

            <div className="flex items-center justify-center space-x-5 mt-5">
                <Button
                    type="button"
                    withAuto
                    size="xs"
                    className="py-2 px-8"
                    loading={loading}
                    onClick={() => {
                        if (!showAddForm && !showOtpForm) {
                            setShowAddForm(true);
                            closeModal();
                        } else {
                            handleClickSubmitButton();
                        }
                    }}
                >
                    {showAddForm && !showOtpForm && t("Enregistrer")}

                    {!showAddForm && showOtpForm && t("Confirmer")}

                    {!showAddForm && !showOtpForm && t("Continuer")}
                </Button>

                <Button
                    type="button"
                    withAuto
                    size="xs"
                    className="py-2 px-8"
                    variant="outline"
                    onClick={() => {
                        if (!showAddForm && !showOtpForm) {
                            navigate(PAGES.SETTING_LOCATION);
                        } else {
                            setShowAddForm(true);
                            setShowOtpForm(false);
                            setPhone("");
                        }
                        !loading && closeModal();
                    }}
                >
                    {!showOtpForm && !showAddForm
                        ? t("Gérer mes numéros")
                        : t("Annuler")}
                </Button>
            </div>
        </>
    );
};

export default AddNumber;
