import { cn } from "../../helpers";
import { IconProps } from "../../types";

const XMarkIcon = ({ size = "md", color, className = "" }: IconProps) => {
    return (
        <svg
            className={cn({
                "text-primary": color === "primary",
                "text-secondary": color === "secondary",
                "h-3 w-3": size === "xs",
                "h-4 w-4": size === "sm",
                "h-5 w-5": size === "md",
                "h-7 w-7": size === "lg",
                "h-8 w-8": size === "xl",
                [className]: true
            })}
            role="img"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
            />
        </svg>
    );
};

export default XMarkIcon;
