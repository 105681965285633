import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { cn } from "../helpers";

interface Props {
    paragraphClassName?: string;
}

interface DocumentProps {
    children?: ReactNode;
    className?: string;
    list?: string[] | ReactNode[];
}

const List = ({ className, list }: DocumentProps) => {
    return (
        <>
            <ul className={cn("list-disc list-inside space-y-2", className)}>
                {!!list && (
                    <>
                        {list.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </>
                )}
            </ul>
            <br />
        </>
    );
};

const Paragraph = ({ children, className }: DocumentProps) => {
    return (
        <>
            <p className={className}>{children}</p>
            <br />
        </>
    );
};

const Title = ({ children, className }: DocumentProps) => {
    return (
        <>
            <h5 className={cn("font-bold", className)}>{children}</h5>
            <br />
        </>
    );
};

const CGUDescription = (props: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <h5 className="text-center text-gray-700 text-lg font-medium mb-3">
                {t("Conditions générales d’utilisation")}
            </h5>

            <div
                className={cn(
                    "text-sm h-[500px] overflow-y-auto",
                    props.paragraphClassName
                )}
            >
                <Paragraph>{t("CGU.paragraph1")}</Paragraph>
                <Paragraph>{t("CGU.paragraph2")}</Paragraph>
                <Paragraph>{t("CGU.paragraph3")}</Paragraph>
                <Paragraph>{t("CGU.paragraph4")}</Paragraph>
                <Paragraph>{t("CGU.paragraph5")}</Paragraph>

                <Title>1- {t("Définition de quelques termes clés")}</Title>
                <Paragraph>
                    <span className="font-bold">CDP:</span> {t("CGU.1.1")}
                </Paragraph>
                <Paragraph>
                    <span className="font-bold">KYC:</span> {t("CGU.1.2")}
                </Paragraph>
                <Paragraph>
                    <span className="font-bold">
                        {t("Vous ou utilisateur")}:
                    </span>{" "}
                    {t("CGU.1.3")}
                </Paragraph>
                <Paragraph>
                    <span className="font-bold">{t("Transaction")}:</span>{" "}
                    {t("CGU.1.3")}
                </Paragraph>
                <Paragraph>
                    <span className="font-bold">{t("Transfert")}:</span>{" "}
                    {t("CGU.1.4")}
                </Paragraph>

                <Title>2- {t("Présentation")}</Title>
                <Paragraph className="font-bold">PAYDUNYA</Paragraph>
                <Paragraph>
                    <span className="font-bold">PAYDUNYA SN,</span>{" "}
                    {t("CGU.2.1")}
                </Paragraph>
                <Paragraph>{t("CGU.2.2")}</Paragraph>
                <Paragraph className="font-bold">MYDUNYA</Paragraph>

                <Paragraph>{t("CGU.2.submenu1.1")}</Paragraph>

                <Title>3- {t("Caractéristiques")}</Title>
                <Paragraph>{t("CGU.2.submenu2.1")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu2.2")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu2.3")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu2.4")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu2.5")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu2.6")}</Paragraph>

                <Title>4- {t("Respect de la vie privée")}</Title>
                <Paragraph>{t("CGU.2.submenu3.1")}</Paragraph>

                <Title>5- {t("Cession de compte MyDunya")}</Title>
                <Paragraph>{t("CGU.2.submenu4.1")}</Paragraph>

                <Title>6- {t("Notifications qui vous sont destinées")}</Title>
                <Paragraph>{t("CGU.2.submenu5.1")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu5.2")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu5.3")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu5.4")}</Paragraph>

                <Title>7- {t("Notifications destinées à PayDunya")}</Title>
                <Paragraph>{t("CGU.2.submenu6.1")}</Paragraph>

                <Title>8- {t("Historiques des transactions")}</Title>
                <Paragraph>{t("CGU.2.submenu7.1")}</Paragraph>

                <Title>9- {t("Modifications du présent Contrat")}</Title>
                <Paragraph>{t("CGU.2.submenu8.1")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu8.2")}</Paragraph>

                <Title>10- {t("Activités interdites")}</Title>
                <Paragraph>{t("CGU.2.submenu9.1")}</Paragraph>

                <div className="pl-8">
                    <List
                        list={[
                            t("CGU.2.submenu9.list.1"),
                            t("CGU.2.submenu9.list.2"),
                            t("CGU.2.submenu9.list.3"),
                            t("CGU.2.submenu9.list.4"),
                            t("CGU.2.submenu9.list.5")
                        ]}
                    />
                </div>

                <Title>
                    11- {t("Limitation de la responsabilité de PayDunya")}
                </Title>
                <Paragraph>{t("CGU.2.submenu10.1")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu10.2")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu10.3")}</Paragraph>

                <Title>
                    12-{" "}
                    {t(
                        "Lutte contre le blanchiment d’argent et financement du terrorisme"
                    )}
                </Title>
                <Paragraph>{t("CGU.2.submenu11.1")}</Paragraph>

                <Title>13- {t("Eligibilité")}</Title>
                <Paragraph>{t("CGU.2.submenu12.1")}</Paragraph>
                <div className="pl-8">
                    <List
                        list={[
                            t("CGU.2.submenu12.list.1"),
                            t("CGU.2.submenu12.list.2")
                        ]}
                    />
                </div>
                <Paragraph>{t("CGU.2.submenu12.2")}</Paragraph>

                <Title>14- {t("Type de compte")}</Title>
                <Paragraph>{t("CGU.2.submenu13.1")}</Paragraph>

                <Title>15- {t("Transferts")}</Title>
                <Title className="pl-4">
                    15.1- {t("Statut des transfert sur MyDunya")}:
                </Title>
                <div className="pl-8">
                    <List
                        list={[
                            <>
                                <span className="font-bold">
                                    {t("Transfert réussi")}:
                                </span>{" "}
                                {t("CGU.2.submenu14.list.1")}
                            </>,
                            <>
                                <span className="font-bold">
                                    {t("Transfert échoué")}:
                                </span>{" "}
                                {t("CGU.2.submenu14.list.2")}
                            </>,
                            <>
                                <span className="font-bold">
                                    {t("Transfert en attente")}:
                                </span>{" "}
                                {t("CGU.2.submenu14.list.3")}
                            </>,
                            <>
                                <span className="font-bold">
                                    {t("Transfert refusé")}:
                                </span>{" "}
                            </>
                        ]}
                    />
                </div>
                <Paragraph>{t("CGU.2.submenu14.1")}</Paragraph>
                <Title className="pl-4">
                    15.2- {t("Transfert en différentes devises ou monnaies")}
                </Title>
                <Paragraph>{t("CGU.2.submenu14.2")}</Paragraph>

                <Title>16- {t("Chargeback")}</Title>
                <Title className="pl-4">16.1- {t("Définition")}</Title>
                <Paragraph>{t("CGU.2.submenu14-1.1")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu14-1.2")}</Paragraph>
                <div className="pl-8">
                    <List
                        list={[
                            t("CGU.2.submenu14-1.list.1"),
                            t("CGU.2.submenu14-1.list.2"),
                            t("CGU.2.submenu14-1.list.3"),
                            t("CGU.2.submenu14-1.list.4")
                        ]}
                    />
                </div>
                <Paragraph>{t("CGU.2.submenu14-1.3")}</Paragraph>
                <Title className="pl-4">
                    16.2- {t("Responsabilité du client mydunya")}
                </Title>
                <Paragraph>{t("CGU.2.submenu14-1.4")}</Paragraph>
                <div className="pl-8">
                    <List
                        list={[
                            t("CGU.2.submenu14-1.list-2.1"),
                            t("CGU.2.submenu14-1.list-2.2")
                        ]}
                    />
                </div>
                <Paragraph>{t("CGU.2.submenu14-1.5")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu14-1.6")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu14-1.7")}</Paragraph>
                <div className="pl-8">
                    <List
                        list={[
                            t("CGU.2.submenu14-1.list-3.1"),
                            t("CGU.2.submenu14-1.list-3.2"),
                            t("CGU.2.submenu14-1.list-3.3"),
                            t("CGU.2.submenu14-1.list-3.4"),
                            t("CGU.2.submenu14-1.list-3.5"),
                            t("CGU.2.submenu14-1.list-3.6")
                        ]}
                    />
                </div>
                <Title className="pl-4">
                    16.2- {t("Gestion et remboursement du chargeback")}
                </Title>
                <Paragraph>{t("CGU.2.submenu14-1.8")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu14-1.9")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu14-1.10")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu14-1.11")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu14-1.12")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu14-1.13")}</Paragraph>
                <Paragraph className="font-semibold">
                    {t("CGU.2.submenu14-1.14")}
                </Paragraph>
                <Paragraph className="font-semibold">
                    {t("CGU.2.submenu14-1.15")}
                </Paragraph>
                <Paragraph>{t("CGU.2.submenu14-1.16")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu14-1.17")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu14-1.18")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu14-1.19")}</Paragraph>

                <Title>
                    17-{" "}
                    {t(
                        "Durée du Contrat et procédure de fermeture, suspension et restriction du Compte"
                    )}
                </Title>
                <Paragraph>{t("CGU.2.submenu15.1")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu15.2")}</Paragraph>

                <Title>18- {t("Commissions")}</Title>
                <Paragraph>{t("CGU.2.submenu16.1")}</Paragraph>
                <Paragraph>{t("CGU.2.submenu16.2")}</Paragraph>
                <table className="w-full border-collapse border border-slate-400 mb-5">
                    <thead>
                        <tr>
                            <th
                                rowSpan={2}
                                scope="col"
                                className="border border-[#4C73BE] px-3 lg:px-6 py-4 text-center bg-[#4C73BE] text-white uppercase"
                            >
                                {t("DE")}
                            </th>
                            <th
                                colSpan={6}
                                scope="col"
                                className="border border-[#4C73BE] px-3 lg:px-6 py-4 text-center bg-[#4C73BE] text-white uppercase"
                            >
                                {t("VERS")}
                            </th>
                        </tr>

                        <tr>
                            <td
                                scope="col"
                                className="border border-slate-300 px-3 lg:px-6 py-4"
                            >
                                <strong>Côte d’Ivoire</strong>: mtn momo <br />
                                <strong>Bénin</strong>: moov money, mtn momo{" "}
                                <br />
                                <strong>Sénégal</strong>: free money, e-money{" "}
                                <br />
                                <strong>Togo</strong>: t money, moov money{" "}
                                <br />
                            </td>
                            <td
                                scope="col"
                                className="border border-slate-300 px-3 lg:px-6 py-4"
                            >
                                <strong>Côte d’Ivoire</strong>: moov money
                            </td>

                            <td
                                scope="col"
                                className="border border-slate-300 px-3 lg:px-6 py-4"
                            >
                                <strong>Sénégal</strong>: orange money
                            </td>

                            <td
                                scope="col"
                                className="border border-slate-300 px-3 lg:px-6 py-4"
                            >
                                <strong>Côte d’Ivoire</strong>: orange money{" "}
                                <br />
                                <strong>Burkina Faso</strong>: orange money,
                                moov money <br />
                                <strong>Mali</strong>: orange money, moov money{" "}
                                <br />
                            </td>

                            <td
                                scope="col"
                                className="border border-slate-300 px-3 lg:px-6 py-4"
                            >
                                <strong>Côte d’Ivoire</strong>: wave <br />
                                <strong>Sénégal</strong>: wave
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>Burkina Faso</strong>: moov money <br />
                                <strong>Côte d’Ivoire</strong>: mtn momo <br />
                                <strong>Mali</strong>: moov money <br />
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>2,5%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>3,0%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>3,5%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>3,5%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>4,0%</strong>
                            </td>
                        </tr>

                        <tr>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>Burkina Faso</strong>: orange money
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>3,0%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>3,5%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>3,5%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>4,0%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>4,0%</strong>
                            </td>
                        </tr>

                        <tr>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>Bénin</strong>: moov money, mtn momo
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>2,0%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>2,5%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>2,5%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>3,0%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>3,5%</strong>
                            </td>
                        </tr>

                        <tr>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>Côte d’Ivoire</strong>: orange money,
                                wave <br />
                                <strong>Sénégal</strong>: wave <br />
                                <strong>Togo</strong>: t money, moov money{" "}
                                <br />
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>2,0%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>2,5%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>3,0%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>3,0%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>3,5%</strong>
                            </td>
                        </tr>

                        <tr>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>Sénégal</strong>: orange money, free
                                money, wizall money e-money <br />
                                <strong>Mali</strong>: orange money <br />
                                <strong>Côte d’Ivoire</strong>: moov money{" "}
                                <br />
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>1,5%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>2,0%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>2,5%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>2,5%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>3,0%</strong>
                            </td>
                        </tr>

                        <tr>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>Carte Bancaire</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>3,0%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>3,5%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>3,5%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>4,0%</strong>
                            </td>
                            <td className="border border-slate-300 whitespace-nowrap px-3 lg:px-6 py-4">
                                <strong>4,5%</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Paragraph>{t("CGU.2.submenu16.3")}</Paragraph>
                <div className="pl-8">
                    <List
                        list={[
                            t("CGU.2.submenu16.list.1"),
                            t("CGU.2.submenu16.list.2"),
                            t("CGU.2.submenu16.list.3")
                        ]}
                    />
                </div>

                <Title>19- {t("Erreurs et gestion et Annulation")}</Title>
                <Title className="pl-4">19-1- {t("Erreurs et gestion")}</Title>
                <Paragraph>{t("CGU.2.submenu17.1")}</Paragraph>
                <div className="pl-8">
                    <List
                        list={[
                            t("CGU.2.submenu17.list.1"),
                            t("CGU.2.submenu17.list.2"),
                            t("CGU.2.submenu17.list.3")
                        ]}
                    />
                </div>
                <Paragraph>{t("CGU.2.submenu17.2")}</Paragraph>
                <Title className="pl-4">
                    19-2- {t("Conditions d’annulation de transfert")}
                </Title>
                <Paragraph>{t("CGU.2.submenu17.3")}</Paragraph>

                <Title>20- {t("Accord des termes du contrat")}</Title>
                <Paragraph>{t("CGU.2.submenu18.1")}</Paragraph>
            </div>
        </>
    );
};

export default CGUDescription;
