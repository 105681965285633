import InfiniteScroll from "react-infinite-scroll-component";

import { TransferType } from "../types";

import TransactionsEmpty from "./TransactionsEmpty.tsx";
import TransferISkeletonItem from "./TransferISkeletonItem.tsx";
import TransferItem from "./TransferItem.tsx";

interface PaginateType {
    page: number;
    limit: number;
}

interface Props {
    transactions: TransferType[];
    loading: boolean;
    paginate: PaginateType;
    setPaginate: (value: PaginateType) => void;
    hasMore: boolean;
}

const TransferHistory = (props: Props) => {
    const {
        transactions = [],
        loading,
        setPaginate,
        paginate,
        hasMore
    } = props;

    return (
        <>
            {transactions.length === 0 && !loading ? (
                <TransactionsEmpty />
            ) : (
                <div className="h-96 w-[381.24]">
                    <InfiniteScroll
                        dataLength={transactions.length}
                        next={() =>
                            setPaginate({
                                page: paginate.page + 1,
                                limit: paginate.limit
                            })
                        }
                        hasMore={hasMore}
                        loader={
                            <>
                                {[1, 2, 3, 4, 5].map(item => (
                                    <TransferISkeletonItem key={item} />
                                ))}
                            </>
                        }
                        height={384}
                    >
                        {transactions.map((item, index) => (
                            <TransferItem key={index} item={item} />
                        ))}
                    </InfiniteScroll>
                </div>
            )}
        </>
    );
};

export default TransferHistory;
