import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import ENDPOINTS from "../../constants/endpoint.ts";
import { loadTransferServiceHost } from "../../helpers";
import useTransferModal from "../../hooks/useTransferModal.ts";
import { readNotification } from "../../store/notificationSlice.ts";

import { NotificationType } from "./types.ts";

const TRANSFER_NOTIFICATION = "Transfer";
const RISK_NOTIFICATION = "RiskNotification";
const DETAIL_ACTION = "details";
const SEPARATOR = "\\";

const useNotificationAction = (notification: NotificationType) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { openTransferModal } = useTransferModal();

    const clientNotificationRead = useCallback(() => {
        if (notification.read_at === null)
            dispatch(readNotification(notification.id));
    }, [dispatch, notification.id, notification.read_at]);

    const getTransfer = useCallback(() => {
        setLoading(true);
        loadTransferServiceHost();
        window.axios
            .get(
                `${ENDPOINTS.TRANSFER_DETAIL.replace(
                    ":id",
                    notification.data.id.toString()
                )}`
            )
            .then(response => {
                openTransferModal(response.data.data);
                clientNotificationRead();
            })
            .finally(() => setLoading(false));
    }, [clientNotificationRead, notification.data.id, openTransferModal]);

    const markNotificationRead = useCallback(
        (successAction?: () => void, clientReadAfterSuccess = false) => {
            if (notification.read_at === null) {
                setLoading(true);
                loadTransferServiceHost();
                window.axios
                    .put(
                        `${ENDPOINTS.READ_NOTIFICATION.replace(
                            ":id",
                            notification.id
                        )}`
                    )
                    .then(() => {
                        successAction && successAction();
                        clientReadAfterSuccess && clientNotificationRead();
                    })
                    .finally(() => setLoading(false));
            } else {
                successAction && successAction();
            }
        },
        [clientNotificationRead, notification.id, notification.read_at]
    );

    const readTransferNotification = useCallback(() => {
        markNotificationRead(() => {
            getTransfer();
        });
    }, [getTransfer, markNotificationRead]);

    return {
        loading,
        action: useCallback(() => {
            switch (notification.type.split(SEPARATOR).pop()) {
                case TRANSFER_NOTIFICATION:
                    if (notification.data.action === DETAIL_ACTION) {
                        readTransferNotification();
                    }
                    break;
                case RISK_NOTIFICATION:
                    markNotificationRead(undefined, true);
                    break;
                default:
                    break;
            }
        }, [notification, markNotificationRead, readTransferNotification])
    };
};

export default useNotificationAction;
