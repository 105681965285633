import React, { forwardRef } from "react";

import { cn } from "../helpers";

import Loading from "./Loading.tsx";

interface Props {
    children: React.ReactNode;
    disabled?: boolean;
    loading?: boolean;
    title: string;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const SettingButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const { loading = false } = props;

    return (
        <button
            ref={ref}
            onClick={props.onClick}
            className={cn({
                "relative z-0 p-2 w-full text-left rounded-md": true,
                "border border-transparent": true,
                [props.className || ""]: true,
                "bg-black/20": loading
            })}
            disabled={props.disabled || loading}
        >
            {loading && (
                <div className="absolute h-full w-full flex items-center justify-center">
                    <Loading />
                </div>
            )}
            <h6 className="text-gray-700 mb-2">{props.title}</h6>
            <p className="text-sm text-gray-500">{props.children}</p>
        </button>
    );
});

SettingButton.displayName = "SettingButton";

export default SettingButton;
