import { cn } from "../../helpers";
import { IconProps } from "../../types";

const SendIcon = ({ size = "md", color, className = "" }: IconProps) => {
    return (
        <svg
            stroke="currentColor"
            fill="none"
            strokeWidth="2"
            viewBox="0 0 24 24"
            strokeLinecap="round"
            strokeLinejoin="round"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
            className={cn({
                "text-primary": color === "primary",
                "text-secondary": color === "secondary",
                "h-3 w-3": size === "xs",
                "h-4 w-4": size === "sm",
                "h-5 w-5": size === "md",
                "h-7 w-7": size === "lg",
                "h-8 w-8": size === "xl",
                [className]: true
            })}
            role="img"
        >
            <line x1="22" y1="2" x2="11" y2="13"></line>
            <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
        </svg>
    );
};

export default SendIcon;
