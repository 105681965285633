import React from "react";
import { NavLink } from "react-router-dom";

import { cn } from "../../helpers";

interface Props {
    children?: React.ReactNode;
    to: string;
    className?: string;
    active?: boolean;
    onClick?: () => void;
}

const NavbarLink = (props: Props) => {
    return (
        <NavLink
            to={props.to}
            onClick={e => {
                if (props.onClick) {
                    e.preventDefault();
                    props.onClick && props.onClick();
                }
            }}
            className={({ isActive }) => {
                return cn({
                    "px-8 text-sm py-4 hidden": true,
                    "lg:flex flex-col justify-center items-center": true,
                    "bg-white text-gray-600 rounded-b-lg":
                        isActive || props.active,
                    [props.className || ""]: true
                });
            }}
        >
            {props.children}
        </NavLink>
    );
};

export default NavbarLink;
