import { useTranslation } from "react-i18next";

import Main from "../components/layouts/Main.tsx";
import { SimpleLink } from "../components/links";
import NotificationItem from "../components/notifications/NotificationItem.tsx";
import PAGES from "../constants/pages.ts";
import useNotification from "../hooks/useNotification.ts";

const Notification = () => {
    const { t } = useTranslation();
    const { notifications } = useNotification();

    return (
        <Main info={t("Consulter mes notifications")}>
            <div className="bg-white space-y-6 p-4 lg:p-12">
                <SimpleLink to={PAGES.TRANSFER}>{t("Retour")}</SimpleLink>

                <div className="overflow-y-auto divide-y">
                    {notifications.map((item, index) => (
                        <NotificationItem key={index} value={item} />
                    ))}
                </div>
            </div>
        </Main>
    );
};

export default Notification;
