import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { STORAGE } from "../constants";
import { storage } from "../helpers";
import {
    setStoreShowTransferKycValidateMessage,
    setStoreTransferCardKycValidateMessage
} from "../store/uiSlice.ts";

import useAuth from "./useAuth.ts";

const useInitValidatedBannerMessage = () => {
    const { kycActionData, isLogin } = useAuth();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isLogin) {
            const hideTransferKycValidatedMessage =
                storage().getItem(
                    STORAGE.HIDE_TRANSFER_KYC_VALIDATED_MESSAGE
                ) === "true";

            if (hideTransferKycValidatedMessage) {
                if (kycActionData.transferValidate) {
                    dispatch(setStoreShowTransferKycValidateMessage(false));
                } else {
                    dispatch(setStoreShowTransferKycValidateMessage(true));
                }
            }

            const hideTransferCardKycValidatedMessage =
                storage().getItem(
                    STORAGE.HIDE_TRANSFER_CARD_KYC_VALIDATED_MESSAGE
                ) === "true";

            if (hideTransferCardKycValidatedMessage) {
                if (kycActionData.validate) {
                    dispatch(setStoreTransferCardKycValidateMessage(false));
                } else {
                    dispatch(setStoreTransferCardKycValidateMessage(true));
                }
            }
        }
    }, [
        dispatch,
        isLogin,
        kycActionData.transferValidate,
        kycActionData.validate
    ]);
};

export default useInitValidatedBannerMessage;
