import { useTranslation } from "react-i18next";

import InputField from "../InputField.tsx";

interface Props {
    kycNotValidated?: boolean;
    limitCards?: boolean;
    cardNumber: string;
    setCardNumber: (value: string) => void;
    cardName: string;
    setCardName: (value: string) => void;
    errors: { [key: string]: string };
    cardAlreadyRegisteredMyAccount: boolean;
    cardAlreadyRegisteredOtherAccount: boolean;
    cardAlreadyBlocked: boolean;
    limitCardsPerMonth: boolean;
}

const AddCardForm = (props: Props) => {
    const {
        kycNotValidated,
        limitCards,
        cardNumber,
        setCardNumber,
        cardName,
        setCardName,
        errors,
        cardAlreadyRegisteredMyAccount,
        cardAlreadyRegisteredOtherAccount,
        cardAlreadyBlocked,
        limitCardsPerMonth
    } = props;

    const { t } = useTranslation();

    return (
        <div className="space-y-4 mt-4">
            {kycNotValidated && (
                <p className="text-red-500 mb-3 text-sm text-center">
                    {t("Le kyc card de votre compte n'est pas valider")}
                </p>
            )}

            {limitCards && (
                <p className="text-red-500 mb-3 text-sm text-center">
                    {t("Vous avez atteint la limite de carte à ajouter")}
                </p>
            )}

            <InputField
                isCardNumber
                inputMode="numeric"
                placeholder={t("Numéro de la carte")}
                value={cardNumber}
                onChange={e => setCardNumber(e.target.value)}
                error={errors?.number || ""}
            />

            <InputField
                placeholder={t("Nom sur la carte")}
                value={cardName}
                onChange={e => setCardName(e.target.value)}
                error={errors?.name || ""}
            />

            {cardAlreadyRegisteredMyAccount && (
                <p className="text-red-500 text-center text-xs my-3">
                    {t(
                        "La carte que vous souhaitez ajouter a déjà été ajoutée à votre compte."
                    )}
                </p>
            )}

            {cardAlreadyRegisteredOtherAccount && (
                <p className="text-red-500 text-center text-xs my-3">
                    {t(
                        "La carte que vous souhaitez ajouter a déjà été ajoutée à un autre compte."
                    )}
                </p>
            )}

            {cardAlreadyBlocked && (
                <p className="text-red-500 text-center text-xs my-3">
                    {t(
                        "La carte que vous souhaitez ajouter a été bloquée pour des raisons de sécurité."
                    )}
                </p>
            )}

            {limitCardsPerMonth && (
                <p className="text-red-500 text-center text-xs my-3">
                    {t(
                        "Échec d'ajout de la carte. Vous avez atteint la limite mensuelle de cartes autorisées."
                    )}
                </p>
            )}
        </div>
    );
};

export default AddCardForm;
