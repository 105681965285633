import { ReactNode, useContext, useMemo } from "react";

import TabContext, { TabStoreType } from "../../../context/TabContext.ts";

interface Props {
    as?: string;
    children: ReactNode;
    className?: ((isActive: boolean) => string) | string;
    index: number | string;
    disable?: boolean;
}

const TabButton = (props: Props) => {
    const { as = "button", children, className, index, disable } = props;

    const value = useContext(TabContext) as TabStoreType;

    const Tag = useMemo(() => as as keyof JSX.IntrinsicElements, [as]);

    const finalClass = useMemo(() => {
        if (!className) return "";
        if (typeof className === "string") return className;
        return className(index === value.index);
    }, [className, index, value.index]);

    return (
        <Tag
            className={finalClass}
            onClick={() => {
                if (value?.index !== index && !disable) {
                    value.changeIndex(index);
                }
            }}
        >
            {children}
        </Tag>
    );
};

export default TabButton;
