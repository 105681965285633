import Button from "@ui/Button.tsx";
import { Modal } from "@ui/Modal.tsx";
import { ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Webcam from "react-webcam";

import {
    cn,
    formatFileSize,
    generateFileName,
    getFileExtension,
    getFileSrc,
    srcToObjectFile,
    userPlatformIs
} from "../../helpers";
import useModal from "../../hooks/useModal.ts";
import { FileExtensionEnum, ModalPropsType, TypeMimEnum } from "../../types";
import IoCameraIcon from "../icons/IoCameraIcon.tsx";
import IoCameraReverseOutlineIcon from "../icons/IoCameraReverseOutlineIcon.tsx";
import MdInfoIcon from "../icons/MdInfoIcon.tsx";
import PhotoIcon from "../icons/PhotoIcon.tsx";
import XMarkIcon from "../icons/XMarkIcon.tsx";

interface Props {
    label?: string;
    required?: boolean;
    inputContainerClassName?: string;
    errorMessage?: string;
    disabled?: boolean;
    onPictureTake: (file: null | File) => void;
    cameraType?: "user" | "environment";
    value?: null | File;
    inputClassName?: string;
}

interface CameraProps extends ModalPropsType {
    onPictureTake: (src: string) => void;
    cameraType?: "user" | "environment";
}

interface FileType {
    size: string;
    name: string;
    extension: string;
    src: string;
    data: File;
}

interface PreviewPicturePropsType extends ModalPropsType {
    src: string;
}

const PicturePreview = (props: PreviewPicturePropsType) => {
    const { isOpen, closeModal, src } = props;
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            className="px-0 pt-0 pb-5 max-w-full md:max-w-[75%]"
            containerClassName="p-0"
            hiddeCloseIcon
        >
            <img
                src={src}
                alt=""
                className="mx-auto object-cover object-center mb-5"
            />

            <div>
                <Button withAuto className="px-8 mx-auto" onClick={closeModal}>
                    {t("Fermer")}
                </Button>
            </div>
        </Modal>
    );
};

interface InfoModalProps extends ModalPropsType {
    children: ReactNode;
    onClickOK: () => void;
}

const InfoModal = (props: InfoModalProps) => {
    const { isOpen, closeModal, onClickOK, children } = props;

    return (
        <Modal isOpen={isOpen} closeModal={closeModal}>
            <MdInfoIcon size="xl" className="mx-auto text-red-700" />

            <p className="my-3 text-center">{children}</p>

            <Button onClick={onClickOK}>OK</Button>
        </Modal>
    );
};

const Camera = (props: CameraProps) => {
    const { isOpen, closeModal, onPictureTake, cameraType } = props;
    const { t } = useTranslation();
    const [pictureSrc, setPictureSrc] = useState<string | null>(null);

    const webcamref = useRef(null);

    const capturePicture = useCallback(() => {
        if (webcamref.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const imageSrc = webcamref.current.getScreenshot({
                quality: 1.0
                // width,
                // height
            });
            setPictureSrc(imageSrc);
        }
    }, [setPictureSrc]);

    const handleClickContinue = useCallback(() => {
        pictureSrc && onPictureTake(pictureSrc);
        setPictureSrc(null);
        closeModal();
    }, [closeModal, onPictureTake, pictureSrc]);

    const facingMode = useMemo(() => {
        if (userPlatformIs("ios") || userPlatformIs("android")) {
            if (cameraType) {
                return cameraType === "user"
                    ? "user"
                    : { exact: "environment" };
            } else {
                return { exact: "environment" };
            }
        } else {
            return "user";
        }
    }, [cameraType]);

    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            className="max-w-full h-screen rounded-none bg-black pt-0 px-0 pb-8"
            containerClassName="p-0"
            topDivClassName="h-screen"
            // hiddeCloseIcon
        >
            <div className="relative space-y-8 w-screen h-screen">
                {pictureSrc ? (
                    <div className="h-full flex items-center justify-center">
                        <img
                            src={pictureSrc}
                            className="object-cover object-center mx-auto w-full h-full"
                            alt=""
                        />
                    </div>
                ) : (
                    <Webcam
                        className="object-cover object-center mx-auto w-full h-full"
                        audio={false}
                        ref={webcamref}
                        screenshotFormat="image/png"
                        screenshotQuality={1}
                        disablePictureInPicture={true}
                        forceScreenshotSourceSize={true}
                        playsInline={true}
                        mirrored={facingMode === "user"}
                        videoConstraints={{
                            width: 1600,
                            height: 900,
                            // width: facingMode === "user" ? 720 : 1920,
                            // height: facingMode === "user" ? 720 : 1080,
                            facingMode
                        }}
                    />
                )}

                {pictureSrc ? (
                    <div className="absolute bottom-32 md:bottom-5 left-0 right-0 z-30 flex items-center justify-center space-x-10">
                        <Button
                            className="md:text-xs md:px-3 w-auto focus:outline-none"
                            withAuto
                            onClick={handleClickContinue}
                        >
                            {t("Continuer")}
                        </Button>

                        <Button
                            className="md:text-xs md:px-3 w-auto bg-gray-300 text-gray-700"
                            color="secondary"
                            onClick={() => setPictureSrc(null)}
                            withAuto
                        >
                            <IoCameraReverseOutlineIcon />
                            <span>{t("Reprendre la photo")}</span>
                        </Button>
                    </div>
                ) : (
                    <div className="absolute bottom-32 md:bottom-5 left-0 right-0 h-14 w-14 flex items-center justify-center border opacity-80 border-white rounded-full mx-auto mt-3">
                        <button
                            onClick={capturePicture}
                            className="h-12 w-12 rounded-full bg-red-600"
                        />
                    </div>
                )}
            </div>
        </Modal>
    );
};

const TakePicture = (props: Props) => {
    const {
        label,
        required = false,
        inputContainerClassName = "",
        errorMessage = "",
        disabled = false,
        value = null,
        cameraType,
        onPictureTake,
        inputClassName
    } = props;

    const [showPreview, setShowPreview] = useState(false);
    const [file, setFile] = useState<FileType | null>(null);

    const { t } = useTranslation();
    const {
        isOpen: isCameraOpen,
        openModal: openCamera,
        closeModal: closeCamera
    } = useModal();

    const {
        isOpen: isInfoOpen,
        openModal: openInfo,
        closeModal: closeInfo
    } = useModal();

    const handleClickClear = useCallback(() => {
        setFile(null);
        onPictureTake && onPictureTake(null);
    }, [onPictureTake]);

    const handlePictureChange = useCallback(
        async (src: string) => {
            srcToObjectFile(
                src,
                generateFileName(FileExtensionEnum.jpeg),
                TypeMimEnum.jpeg
            ).then(async objectFile => {
                const fileData = objectFile;

                if (fileData) {
                    const fileSrc = (await getFileSrc(fileData)) as string;
                    onPictureTake && onPictureTake(fileData);

                    try {
                        setFile({
                            size: formatFileSize(fileData.size),
                            name: fileData.name,
                            extension: getFileExtension(fileData.name),
                            src: fileSrc,
                            data: fileData
                        });
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error(error);
                    }
                }
            });
        },
        [onPictureTake]
    );

    const facingMode = useMemo(() => {
        if (userPlatformIs("ios") || userPlatformIs("android")) {
            if (cameraType) {
                return cameraType === "user"
                    ? "user"
                    : { exact: "environment" };
            } else {
                return { exact: "environment" };
            }
        } else {
            return "user";
        }
    }, [cameraType]);

    return (
        <>
            <div className={cn("w-full", inputContainerClassName)}>
                {label && (
                    <p className="text-sm text-gray-500">
                        {label}{" "}
                        {required && <span className="text-red-500">*</span>}
                    </p>
                )}

                <div
                    className={cn({
                        "flex border border-dashed border-gray-300": true,
                        "relative p-2 rounded-md h-[58px]": true,
                        "bg-gray-50 cursor-not-allowed": disabled,
                        "mt-2": !!label,
                        [inputClassName || ""]: true
                    })}
                >
                    <div className="flex items-center gap-3 flex-1 flex-shrink">
                        <div
                            className={cn({
                                "h-8 w-8 rounded-md": true,
                                "overflow-hidden cursor-pointer": true,
                                "flex items-center justify-center": true,
                                "bg-gray-100": true
                            })}
                            onClick={() => {
                                if (file) {
                                    setShowPreview(true);
                                }
                            }}
                        >
                            {file ? (
                                <img
                                    src={file.src}
                                    alt=""
                                    className="w-8 h-8 object-cover object-center"
                                />
                            ) : (
                                <PhotoIcon
                                    size="sm"
                                    className="text-gray-500"
                                />
                            )}
                        </div>

                        <div className="text-[10px] md:text-xs space-y-1 md:space-y-2 text-gray-500">
                            <p className="truncate max-w-[100px] lg:max-w-full">
                                {file
                                    ? file.name
                                    : t("Veuillez prendre une photo.")}
                            </p>

                            {file && (
                                <p className="truncate">
                                    <span>
                                        {file.extension.toUpperCase()} •{" "}
                                        {file.size}
                                    </span>
                                </p>
                            )}
                        </div>
                    </div>

                    <div className="flex items-center md:space-x-2">
                        {!!value && !disabled && (
                            <button
                                onClick={handleClickClear}
                                type="button"
                                className="py-2 px-3 rounded-md"
                            >
                                <XMarkIcon />
                            </button>
                        )}

                        <button
                            type="button"
                            className={cn({
                                "h-7 rounded-full py-2 px-3 cursor-pointer":
                                    true,
                                "md:font-medium text-xs bg-primary text-white":
                                    true,
                                "flex items-center justify-center space-x-2":
                                    true,
                                "bg-[#97A5AA] cursor-not-allowed": disabled
                            })}
                            disabled={disabled}
                            onClick={() => openInfo()}
                        >
                            <IoCameraIcon />
                            <span className="md:hidden">{t("photo")}</span>
                            <span className="hidden md:block">
                                {t("Prendre photo")}
                            </span>
                        </button>
                    </div>
                </div>

                {errorMessage && (
                    <p className="text-xs text-red-500 mt-0.5">
                        {errorMessage}
                    </p>
                )}
            </div>

            {file && (
                <PicturePreview
                    isOpen={showPreview}
                    closeModal={() => setShowPreview(false)}
                    src={file?.src}
                />
            )}

            <Camera
                isOpen={isCameraOpen}
                closeModal={closeCamera}
                onPictureTake={handlePictureChange}
                cameraType={cameraType}
            />

            <InfoModal
                isOpen={isInfoOpen}
                closeModal={() => closeInfo()}
                onClickOK={() => {
                    closeInfo();
                    openCamera();
                }}
            >
                {facingMode === "user" ? (
                    <>
                        {userPlatformIs("ios") || userPlatformIs("android") ? (
                            <>
                                {/* Selfie + ID card */}
                                {t(
                                    "Assurez vous d'être dans un espace éclairé et positionnez la pièce d'identité à côté de votre visage. Vérifiez l'absence de reflets ou d'ombres. Avant d'envoyer la photo, vérifiez que toutes les informations sont lisibles."
                                )}
                            </>
                        ) : (
                            <>
                                {!cameraType ? (
                                    <>
                                        {/* ID card */}
                                        {t(
                                            "Assurez vous d'être dans un espace éclairé, évitez les ombres et les reflets. Avant d'envoyer la photo, vérifiez que toutes les informations sont lisibles."
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {/* Selfie + ID card */}
                                        {t(
                                            "Assurez vous d'être dans un espace éclairé et positionnez la pièce d'identité à côté de votre visage. Vérifiez l'absence de reflets ou d'ombres. Avant d'envoyer la photo, vérifiez que toutes les informations sont lisibles."
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        {/* ID card */}
                        {t(
                            "Assurez vous d'être dans un espace éclairé, évitez les ombres et les reflets. Avant d'envoyer la photo, vérifiez que toutes les informations sont lisibles."
                        )}
                    </>
                )}
            </InfoModal>
        </>
    );
};

export default TakePicture;
