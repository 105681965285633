import { useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DEFAULT_COUNTRY_INDICATIVE, STORAGE } from "../constants";
import { getIndicativeByCountryCode, storage } from "../helpers";
import { RootState } from "../store";
import { updateTransferFormData } from "../store/transferSlice.ts";

const useInitTransferFormData = () => {
    const { position } = useSelector((state: RootState) => state.position);
    const { formData } = useSelector((state: RootState) => state.transfer);
    const dispatch = useDispatch();
    const ref = useRef(false);

    useLayoutEffect(() => {
        const transferFormDataString = storage().getItem(
            STORAGE.TRANSFER_FORM_DATA
        );

        if (position && !ref.current && transferFormDataString === null) {
            ref.current = true;

            dispatch(
                updateTransferFormData({
                    ...formData,
                    senderPhone: {
                        ...formData.senderPhone,
                        indicative:
                            getIndicativeByCountryCode(position.countryCode) ||
                            DEFAULT_COUNTRY_INDICATIVE
                    },
                    recipientPhone: {
                        ...formData.recipientPhone,
                        indicative:
                            getIndicativeByCountryCode(position.countryCode) ||
                            DEFAULT_COUNTRY_INDICATIVE
                    }
                })
            );
        }
    }, [dispatch, formData, position]);
};

export default useInitTransferFormData;
