import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { STORAGE } from "../constants";
import { auth, storage } from "../helpers";
import { AccountType, UserType } from "../types";

export interface TokenDataType {
    id: string;
    accessToken: string;
    user_id: number;
    client_id: number;
    name: string;
    scopes: [];
    revoked: boolean;
    created_at: string;
    updated_at: string;
    expires_at: string;
}

export interface AuthState {
    isLogin: boolean;
    tokenData: TokenDataType | null;
    user: UserType | null;
}

const authData = auth();

const initialState: AuthState = authData
    ? authData
    : {
          isLogin: false,
          user: null,
          tokenData: null
      };

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        login: (
            state,
            action: PayloadAction<{ user: UserType; tokenData: TokenDataType }>
        ) => {
            state.isLogin = true;
            state.user = action.payload.user;
            state.tokenData = action.payload.tokenData;
            state.isLogin = true;
            storage().setItem(STORAGE.AUTH, JSON.stringify(state));
        },
        logout: state => {
            storage().removeItem(STORAGE.AUTH);
            state.isLogin = false;
            state.user = null;
            state.tokenData = null;
        },
        updateAuthUser: (
            state,
            action: PayloadAction<{ user?: UserType; account?: AccountType }>
        ) => {
            state.user = action.payload.user || state.user;
            if (state.user && action.payload.account) {
                state.user.account = action.payload.account;
            }
            storage().setItem(STORAGE.AUTH, JSON.stringify(state));
        }
    }
});

export const { login, logout, updateAuthUser } = authSlice.actions;

const authReducer = authSlice.reducer;

export default authReducer;
