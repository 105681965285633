import { useCallback, useState } from "react";

const useModal = (defaultOpen?: boolean) => {
    const [isOpen, setIsOpen] = useState(defaultOpen || false);

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    const openModal = useCallback(() => {
        setIsOpen(true);
    }, []);

    return {
        isOpen,
        setIsOpen,
        closeModal,
        openModal
    };
};

export default useModal;
