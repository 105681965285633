import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import useCopyToClipboard from "../hooks/useCopyToClipboard.tsx";

import CopyClipboardButton from "./CopyClipboardButton.tsx";

interface Props {
    slug: string;
    code: string;
    fullCode: string;
}

const OtpMessage = (props: Props) => {
    const { code, fullCode } = props;
    const { t } = useTranslation();
    const { copy } = useCopyToClipboard();

    const handleCallCode = useCallback(() => {
        const link = document.createElement("a");
        link.href = `tel:${code}`;
        link.click();
    }, [code]);

    return (
        <div className="flex items-center space-x-2">
            <div className="text-xs text-gray-500">
                {t("Obtenir le code otp avec")}{" "}
                <span
                    className="text-primary cursor-pointer underline"
                    onClick={handleCallCode}
                >
                    {fullCode}
                </span>
            </div>

            <CopyClipboardButton onClick={() => copy(code)} />
        </div>
    );
};

export default OtpMessage;
