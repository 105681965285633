import { Modal } from "@ui/Modal.tsx";

import { ModalPropsType } from "../../types";
import CGUDescription from "../CGUDescription.tsx";

const CGUModal = (props: ModalPropsType) => {
    const { isOpen, closeModal } = props;

    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            className="rounded-md lg:max-w-[66%]"
        >
            <CGUDescription />
        </Modal>
    );
};

export default CGUModal;
