import React from "react";

import { cn } from "../../helpers";

interface Props {
    children?: React.ReactNode;
    className?: string;
}

const AsideTitle = (props: Props) => {
    return (
        <h6 className={cn("text-lg text-gray-700 mb-6", props.className)}>
            {props.children}
        </h6>
    );
};

export default AsideTitle;
