import BfIcon from "./flags/BfIcon.tsx";
import BjIcon from "./flags/BjIcon.tsx";
import CiIcon from "./flags/CiIcon.tsx";
import CmIcon from "./flags/CmIcon.tsx";
import GnIcon from "./flags/GnIcon.tsx";
import MlIcon from "./flags/MlIcon.tsx";
import NgIcon from "./flags/NgIcon.tsx";
import SnIcon from "./flags/SnIcon.tsx";
import TgIcon from "./flags/TgIcon.tsx";

interface Props {
    indicative: string;
    className?: string;
}

const MatchCountryIcon = (props: Props) => {
    switch (props.indicative) {
        case "+229":
            return <BjIcon className={props.className} />;
        case "+225":
            return <CiIcon className={props.className} />;
        case "+237":
            return <CmIcon className={props.className} />;
        case "+221":
            return <SnIcon className={props.className} />;
        case "+223":
            return <MlIcon className={props.className} />;
        case "+228":
            return <TgIcon className={props.className} />;
        case "+226":
            return <BfIcon className={props.className} />;
        case "+224":
            return <GnIcon className={props.className} />;
        case "+234":
            return <NgIcon className={props.className} />;
        default:
            return null;
    }
};

export default MatchCountryIcon;
