import Button from "@ui/Button.tsx";
import PinInput from "@ui/PinInput";
import { Dispatch, FormEvent, SetStateAction } from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import PAGES from "../../constants/pages.ts";
import ChevronIcon from "../icons/ChevronIcon.tsx";
import InputField from "../InputField.tsx";
import PhoneInput from "../PhoneInput.tsx";

interface StringStateType {
    value: string;
    setValue: (value: string) => void;
}

interface Props {
    showForgotPinForm?: boolean;
    showResetPinForm?: boolean;
    indicative: StringStateType;
    code: StringStateType;
    handleSubmit: (e: FormEvent) => void;
    resendCode: () => void;
    register: UseFormRegister<FieldValues>;
    errors: { [key: string]: string | string[] };
    loading?: boolean;
    pin: string;
    setPin: Dispatch<SetStateAction<string>>;
    confirmPin: string;
    setConfirmPin: Dispatch<SetStateAction<string>>;
}

const ForgotFlowForm = (props: Props) => {
    const { t } = useTranslation();
    const { pin, setPin, confirmPin, setConfirmPin, resendCode } = props;

    return (
        <form className="w-full space-y-5" onSubmit={props.handleSubmit}>
            {props.showForgotPinForm && (
                <h4 className="text-lg text-gray-700 font-medium mt-12 text-center md:text-left">
                    {t("Code secret oublié")}
                </h4>
            )}

            {props.showResetPinForm && (
                <h4 className="text-lg text-gray-700 font-medium mt-12 text-center md:text-left">
                    {t("Réinitialisation du code secret")}
                </h4>
            )}

            {/*// step 1*/}
            {props.showForgotPinForm && (
                <PhoneInput
                    name="phone_with_indicative"
                    placeholder={t("N° de téléphone")}
                    register={props.register("phone_with_indicative")}
                    indicative={props.indicative.value}
                    onIndicativeChange={value =>
                        props.indicative.setValue(value)
                    }
                    error={props.errors?.phone_with_indicative}
                />
            )}

            {/*// step 3*/}
            {props.showResetPinForm && (
                <>
                    <div>
                        <p className="mb-2 text-sm">
                            {t("Veuillez renseigner le code reçu par sms")}
                        </p>

                        <PinInput
                            type="number"
                            length={4}
                            size="xl"
                            onChange={value => props.code.setValue(value)}
                            pinItemClassName="w-1/4 mb-2"
                            errorMessage={props.errors?.code}
                        />

                        <div className="text-xs text-right">
                            <p>
                                {t("Code non reçu?")}{" "}
                                <button
                                    type="button"
                                    onClick={resendCode}
                                    className="focus:outline-none text-blue-600 hover:underline"
                                >
                                    {t("Renvoyer")}
                                </button>
                            </p>
                        </div>
                    </div>

                    <InputField
                        isPin
                        id="pin"
                        name="pin"
                        type="password"
                        inputMode="numeric"
                        placeholder={t("Nouveau code pin")}
                        value={pin}
                        onChange={e => setPin(e.target.value)}
                        error={props.errors?.pin}
                    />

                    <InputField
                        isPin
                        id="confirm_pin"
                        name="confirm_pin"
                        type="password"
                        inputMode="numeric"
                        placeholder={t("Confirmer code pin")}
                        value={confirmPin}
                        onChange={e => setConfirmPin(e.target.value)}
                        error={props.errors?.confirm_pin}
                    />
                </>
            )}

            <Button
                type="submit"
                loading={props.loading}
                className="justify-start px-7"
                textContainerClassName="flex items-center justify-between w-full"
            >
                {t("Valider")}
                <ChevronIcon className="w-3 h-3 -rotate-90" />
            </Button>

            <p className="text-sm text-center lg:hidden">
                {t("Vous souvenez-vous ?")}{" "}
                <NavLink
                    to={PAGES.LOGIN}
                    className="text-primary hover:underline"
                >
                    {t("Se connecter")}
                </NavLink>
            </p>

            <div className="text-sm font-medium pt-60 hidden lg:block pb-5">
                <p>{t("Vous souvenez-vous ?")}</p>

                <NavLink
                    to={PAGES.LOGIN}
                    className="text-primary hover:underline"
                >
                    {t("Se connecter")}
                </NavLink>
            </div>
        </form>
    );
};

export default ForgotFlowForm;
