import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

import config from "../config";
import { ENV } from "../constants";
import { scrollToTop } from "../helpers";

import useAuth from "./useAuth.ts";

const useScrollTop = () => {
    const { pathname } = useLocation();
    const { reFetchAuthUser } = useAuth();

    useLayoutEffect(() => {
        // eslint-disable-next-line no-console
        if (config.env === ENV.PROD) console.clear();
        scrollToTop();
    }, [pathname, reFetchAuthUser]);
};

export default useScrollTop;
