import { useEffect } from "react";

const useRequestNotificationPermission = () => {
    useEffect(() => {
        if ("Notification" in window) {
            if (Notification.permission !== "granted") {
                Notification.requestPermission().then(permissions => {
                    if (permissions === "granted") {
                        /* Do something */
                    }
                });
            }
        }
    }, []);
};

export default useRequestNotificationPermission;
