import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { STORAGE } from "../constants";
import { storage } from "../helpers";
import { PositionType } from "../types/location.ts";

interface PositionStateType {
    position: PositionType | null;
}

const position = storage().getItem(STORAGE.POSITION);

const initialState: PositionStateType = {
    position: position ? JSON.parse(position) : null
};

const positionSlice = createSlice({
    name: "position",
    initialState,
    reducers: {
        addPosition: (state, action: PayloadAction<PositionType>) => {
            state.position = action.payload;
            storage().setItem(STORAGE.POSITION, JSON.stringify(state.position));
        }
    }
});

export const { addPosition } = positionSlice.actions;

const positionReducer = positionSlice.reducer;

export default positionReducer;
