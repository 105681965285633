import Button from "@ui/Button.tsx";
import { Modal } from "@ui/Modal.tsx";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { RESPONSE_STATUS } from "../../constants";
import ENDPOINTS from "../../constants/endpoint.ts";
import { loadTransferServiceHost } from "../../helpers";
import useManageSchemaValidationError from "../../hooks/useManageSchemaValidationError.ts";
import useToast from "../../hooks/useToast.ts";
import { ModalPropsType } from "../../types";
import InputField from "../InputField.tsx";

interface Props extends ModalPropsType {
    token: string;
}

const OtpSchema = yup.object().shape({
    otp: yup.string().required()
});

const TRANSFER_NOT_CONFIRM_KEY = "transfer_not_confirm";

const OtpValidTransferModal = (props: Props) => {
    const { t } = useTranslation();
    const [otp, setOtp] = useState("");
    const [loading, setLoading] = useState(false);
    const { setErrors, errors, showErrors, resetErrors } =
        useManageSchemaValidationError();
    const { successToast, errorToast } = useToast();

    const handleSubmit = useCallback(() => {
        OtpSchema.validate({ otp }, { abortEarly: false })
            .then(() => {
                setLoading(true);
                loadTransferServiceHost();
                window.axios
                    .post(ENDPOINTS.CONFIRM_TRANSFER, {
                        token: props.token,
                        otp: otp
                    })
                    .then(() => {
                        resetErrors();
                        successToast(
                            t("Le code OTP à été envoyer avec success"),
                            t(
                                "Veuillez consulter votre messagerie pour obtenir le code OTP"
                            )
                        );
                        props.closeModal();
                    })
                    .catch(error => {
                        resetErrors();
                        const errorsResponse = error.response.data.errors;
                        const status = error.response.status;
                        if (status === RESPONSE_STATUS.UNPROCESSABLE_ENTITY) {
                            typeof errorsResponse === "object" &&
                                setErrors(errorsResponse);
                        }

                        if (
                            status === RESPONSE_STATUS.CONFLICT &&
                            error.response.data.error ===
                                TRANSFER_NOT_CONFIRM_KEY
                        ) {
                            errorToast(
                                t("Le transfert n'a pas pu être confirmé"),
                                t(
                                    "Vueillez vérifier si le code OTP correspond vraiment à celui envoyer par sms"
                                )
                            );
                        }
                    })
                    .finally(() => setLoading(false));
            })
            .catch(showErrors());
    }, [
        errorToast,
        otp,
        props,
        resetErrors,
        setErrors,
        showErrors,
        successToast,
        t
    ]);

    return (
        <Modal
            isOpen={props.isOpen}
            closeModal={() => {
                !loading && props.closeModal();
            }}
        >
            <div className="my-6 mt-12">
                <InputField
                    type="text"
                    placeholder={t("Vueillez saisir l'OTP")}
                    value={otp}
                    onChange={e => setOtp(e.target.value)}
                    error={errors.otp}
                />
            </div>

            <Button onClick={handleSubmit} type="button" loading={loading}>
                {t("Envoyer")}
            </Button>
        </Modal>
    );
};

export default OtpValidTransferModal;
