import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectItemType } from "@ui/Select.tsx";

import { DEFAULT_COUNTRY_INDICATIVE, LIMIT_DATA, STORAGE } from "../constants";
import { storage } from "../helpers";
import { PhoneType, TransferType } from "../types";

export interface FormDataType {
    senderAmount: string;
    senderWallet: SelectItemType | null;
    senderPhone: PhoneType;
    recipientAmount: string;
    recipientWallet: SelectItemType | null;
    recipientPhone: PhoneType;
    recipientName?: string;
    supportFees: boolean;
    otp?: string;
    card: SelectItemType | null;
    cvv: string;
    expirationDate: string;
}

interface FeesType {
    loading: boolean;
    value: string | null;
}

interface TransferListFilterType {
    pageIndex: number;
    pageSize: number;
    search: string | null;
}

export interface TransferStateType {
    retryTransfer: TransferType | null;
    formData: FormDataType;
    fees: FeesType;
    currentTransfer: TransferType | null;
    list: {
        loading: boolean;
        value: TransferType[];
        filter: TransferListFilterType;
    };
}

const defaultPhone: PhoneType = {
    phoneNumber: "",
    indicative: DEFAULT_COUNTRY_INDICATIVE
};

const defaultFormValue: FormDataType = {
    card: null,
    cvv: "",
    expirationDate: "",
    senderAmount: "",
    senderWallet: null,
    senderPhone: defaultPhone,
    recipientAmount: "",
    recipientWallet: null,
    recipientPhone: defaultPhone,
    recipientName: "",
    supportFees: false,
    otp: ""
};

const initialState: TransferStateType = {
    retryTransfer: null,
    formData: defaultFormValue,
    fees: {
        loading: false,
        value: null
    },
    currentTransfer: null,
    list: {
        loading: false,
        value: [],
        filter: {
            pageIndex: 1,
            pageSize: LIMIT_DATA,
            search: null
        }
    }
};

const transferSlice = createSlice({
    name: "transfer",
    initialState,
    reducers: {
        updateTransferFormData: (
            state,
            action: PayloadAction<FormDataType>
        ) => {
            state.formData = action.payload;
            storage().setItem(
                STORAGE.TRANSFER_FORM_DATA,
                JSON.stringify(action.payload)
            );
        },
        resetTransferFormData: state => {
            state.formData.senderAmount = "";
            state.formData.recipientAmount = "";
            /*state.formData.recipientPhone = {
                phoneNumber: "",
                indicative:
                    state.formData.recipientPhone.indicative ||
                    defaultPhone.indicative
            };*/
            state.formData.recipientName = "";
            state.formData.supportFees = false;
            state.formData.card = null;
            state.formData.cvv = "";
            state.formData.expirationDate = "";
            state.formData.otp = "";
            state.fees.value = null;
            storage().setItem(
                STORAGE.TRANSFER_FORM_DATA,
                JSON.stringify(state.formData)
            );
        },
        submitTransferForm: () => {
            const button = document.getElementById(
                "transfer-button"
            ) as HTMLButtonElement;

            if (button) {
                button.click();
            }
        },
        setTransferFees: (state, action: PayloadAction<string>) => {
            state.fees.value = action.payload;
        },
        setTransferFeesLoading: (state, action: PayloadAction<boolean>) => {
            state.fees.loading = action.payload;
        },
        addCurrentTransfer: (state, action: PayloadAction<TransferType>) => {
            if (!state.currentTransfer) {
                state.currentTransfer = action.payload;
            } else {
                if (state.currentTransfer.id === action.payload.id) {
                    state.currentTransfer = action.payload;
                }
            }
        },
        clearCurrentTransfer: state => {
            state.currentTransfer = null;
        },
        setRetryTransfer: (
            state,
            action: PayloadAction<TransferType | null>
        ) => {
            state.retryTransfer = action.payload;
        },
        updateListFilter: (
            state,
            action: PayloadAction<TransferListFilterType>
        ) => {
            state.list.filter = action.payload;
        },
        setListLoading: (state, action: PayloadAction<boolean>) => {
            state.list.loading = action.payload;
        },
        addTransferList: (state, action: PayloadAction<TransferType[]>) => {
            state.list.value = action.payload;
        },
        resetTransferStore: state => {
            state.retryTransfer = initialState.retryTransfer;
            // state.formData = initialState.formData;
            state.fees = initialState.fees;
            state.currentTransfer = initialState.currentTransfer;
            state.list = initialState.list;
        },
        resetSenderData: state => {
            state.formData.senderPhone.phoneNumber = "";
            state.formData.senderPhone.indicative = DEFAULT_COUNTRY_INDICATIVE;
            state.formData.card = null;
            state.formData.cvv = "";
            state.formData.expirationDate = "";
        },
        updateSendDataInTransferForm: (
            state,
            action: PayloadAction<{ indicative: string; phone: string }>
        ) => {
            state.formData.senderPhone.phoneNumber = action.payload.phone;
            state.formData.senderPhone.indicative = action.payload.indicative;
        }
    }
});

export const {
    updateTransferFormData,
    resetTransferFormData,
    submitTransferForm,
    setTransferFees,
    setTransferFeesLoading,
    addCurrentTransfer,
    clearCurrentTransfer,
    setRetryTransfer,
    updateListFilter,
    setListLoading,
    addTransferList,
    resetTransferStore,
    resetSenderData,
    updateSendDataInTransferForm
} = transferSlice.actions;

const transferReducer = transferSlice.reducer;

export default transferReducer;
