import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import ENDPOINTS from "../constants/endpoint.ts";
import { loadAuthServiceHost } from "../helpers";
import { RootState } from "../store";
import { updateAuthUser } from "../store/authSlice.ts";
import { KycStatusType } from "../types";

const useAuth = () => {
    const { user, tokenData } = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();

    const isLogin = useMemo(() => {
        if (!tokenData) return false;
        const expiresAt = tokenData?.expires_at;

        return !(
            window.dayjs(expiresAt).isValid() &&
            window.dayjs().isAfter(expiresAt)
        );
    }, [tokenData]);

    const kycActionData = useMemo(() => {
        if (!user || !isLogin) {
            return {
                /* Validated */
                validate: false,
                transferValidate: false,
                cardValidate: false,
                /* End validated */

                /* Review */
                toReview: false,
                transferReview: false,
                cardReview: false,
                transferSubmitReview: false,
                cardSubmitReview: false,
                /* End Review */

                /* Rejected */
                rejected: false,
                transferRejected: false,
                cardRejected: false,
                /* End rejected */

                userHaveSelfie: false,
                oneActionIsVerify: false
            };
        }

        const transferValidate =
            user.account?.transfer_kyc_status === KycStatusType.validated;
        const cardValidate =
            user.account?.card_kyc_status === KycStatusType.validated;

        const transferReview =
            user.account?.transfer_kyc_status === KycStatusType.to_review;
        const transferSubmitReview =
            user.account?.transfer_kyc_status === KycStatusType.submit_review;

        const cardReview =
            user.account?.card_kyc_status === KycStatusType.to_review;
        const cardSubmitReview =
            user.account?.card_kyc_status === KycStatusType.submit_review;

        const transferRejected = !!user.account?.transfer_kyc_rejected_at;
        const cardRejected = !!user.account?.card_kyc_rejected_at;

        const validate = transferValidate && cardValidate;

        const toReview = transferReview || cardReview;

        const rejected = transferRejected || cardRejected;

        return {
            /* Validated */
            validate,
            transferValidate,
            cardValidate,
            /* End validated */

            /* Review */
            toReview,
            transferReview,
            transferSubmitReview,
            cardReview,
            cardSubmitReview,
            /* End review */

            /* Rejected */
            rejected,
            transferRejected,
            cardRejected,
            /* End rejected */

            userHaveSelfie: !!user?.selfie_photo,
            oneActionIsVerify: validate || toReview || rejected
        };
    }, [isLogin, user]);

    const formatUser = useMemo(() => {
        const phone_numbers = user?.account?.transfer_phone_numbers || [];
        const cards = user?.account?.transfer_cards || [];
        return {
            ...(user ? user : {}),
            account: {
                ...(user?.account ? user?.account : {}),
                transfer_phone_numbers: phone_numbers.filter(
                    item => !item.deleted_at
                ),
                transfer_cards: cards.filter(item => !item.deleted_at)
            }
        };
    }, [user]);

    const reFetchAuthUser = useCallback(() => {
        loadAuthServiceHost();
        window.axios.get(ENDPOINTS.ME).then(response => {
            dispatch(updateAuthUser({ user: response.data.data }));
        });
    }, [dispatch]);

    return {
        isLogin,
        user: formatUser,
        tokenData: tokenData,
        kycActionData,
        reFetchAuthUser
    };
};

export default useAuth;
