import { useLayoutEffect } from "react";

export interface BlurOrFocusAction {
    focus: (e: FocusEvent) => void;
    blur: (e: FocusEvent) => void;
}

const useBlurOrFocusInput = (inputId: string, action: BlurOrFocusAction) => {
    useLayoutEffect(() => {
        const input = document.getElementById(inputId) as HTMLInputElement;

        if (input) {
            input.addEventListener("focus", action.focus);
            input.addEventListener("blur", action.blur);

            return () => {
                input.addEventListener("focus", action.focus);
                input.addEventListener("blur", action.blur);
            };
        }
    }, [action.blur, action.focus, inputId]);
};

export default useBlurOrFocusInput;
