import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownItems
} from "@ui/Dropdown.tsx";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { cn } from "../helpers";
import { RootState } from "../store";
import { changeLang } from "../store/configSlice.ts";

import FrIcon from "./flags/FrIcon.tsx";
import UsIcon from "./flags/UsIcon.tsx";

interface Props {
    className?: string;
    itemsContainerClassName?: string;
}

const LangSwitcher = (props: Props) => {
    const { t, i18n } = useTranslation();
    const { client } = useSelector((state: RootState) => state.config);
    const dispatch = useDispatch();

    const changeLocal = useCallback(
        (lang: "fr" | "en") => {
            document.documentElement.lang = lang;
            i18n.changeLanguage(lang);
            dispatch(changeLang(lang));
        },
        [dispatch, i18n]
    );

    return (
        <Dropdown as="div" className={cn("relative", props.className)}>
            <DropdownButton
                as="button"
                className="flex items-center space-x-2 text-sm"
                data-testid="lang-button"
            >
                {client.locale === "fr" && <FrIcon className="h-6 w-6" />}

                {client.locale === "en" && <UsIcon className="h-6 w-6" />}
            </DropdownButton>

            <DropdownItems
                className={cn({
                    "text-sm right-0 z-10 mt-2 w-32 origin-top-right": true,
                    "text-gray-600 absolute top-10 shadow-sm": true,
                    "ring-1 ring-black ring-opacity-5 focus:outline-none": true,
                    "border border-gray-100 overflow-hidden": true,
                    "divide-y divide-gray-100 rounded-md bg-white": true,
                    [props.itemsContainerClassName || ""]: true
                })}
            >
                <DropdownItem
                    className={cn({
                        "cursor-pointer py-3 px-3 list-none": true,
                        "flex items-center space-x-4": true,
                        "hover:bg-gray-100": true
                    })}
                    as="li"
                    onClick={() => changeLocal("fr")}
                    data-testid="lang-fr"
                >
                    <FrIcon className="h-5 w-5" />
                    <span>{t("Français")}</span>
                </DropdownItem>

                <DropdownItem
                    className={cn({
                        "cursor-pointer py-3 px-3 list-none": true,
                        "flex items-center space-x-4": true,
                        "hover:bg-gray-100": true
                    })}
                    as="li"
                    onClick={() => changeLocal("en")}
                    data-testid="lang-en"
                >
                    <UsIcon className="h-5 w-5" />
                    <span>{t("Anglais")}</span>
                </DropdownItem>
            </DropdownItems>
        </Dropdown>
    );
};

export default LangSwitcher;
