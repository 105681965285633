import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getConfig } from "../helpers";
import { RootState } from "../store";
import { addPosition } from "../store/positionSlice.ts";

const useFetchLocalisation = () => {
    const dispatch = useDispatch();
    const { position } = useSelector((state: RootState) => state.position);

    useEffect(() => {
        const config = getConfig();
        if ("geolocation" in navigator && !position) {
            if (config) {
                navigator.geolocation.getCurrentPosition(position => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;

                    window.axios
                        .get(
                            `${config.localisationApi}?latitude=${latitude}&longitude=${longitude}&localityLanguage=${config.locale}`
                        )
                        .then(response => {
                            dispatch(addPosition(response.data));
                        });
                });
            }
        }
    }, [dispatch, position]);
};

export default useFetchLocalisation;
