import { ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";

import { cn } from "../helpers";

import SearchIcon from "./icons/SearchIcon.tsx";

interface Props {
    value?: string | number | readonly string[];
    onChange?: ChangeEventHandler<HTMLInputElement>;
}

const SecondSearchInput = (props: Props) => {
    const { t } = useTranslation();
    return (
        <div className="relative mb-3">
            <input
                data-testid="search-input"
                type="text"
                className={cn(
                    "w-full py-2.5 pl-12 text-sm border-none rounded",
                    "text-gray-500 placeholder-gray-400 bg-[#f2f5f9]",
                    "focus:border-none focus:ring-0 focus:outline-none"
                )}
                placeholder={`${t("Rechercher")}...`}
                value={props.value}
                onKeyDown={e => e.stopPropagation()}
                onChange={props.onChange}
            />
            <SearchIcon className="absolute w-5 h-5 top-[0.65rem] left-3 text-gray-500" />
        </div>
    );
};

export default SecondSearchInput;
