import Button from "@ui/Button.tsx";
import CheckboxInput from "@ui/CheckboxInput.tsx";
import Select, { SelectItemType } from "@ui/Select.tsx";
import {
    FormEvent,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import * as yup from "yup";

import { LIMIT_AMOUNT, RESPONSE_STATUS, STORAGE } from "../../constants";
import ENDPOINTS from "../../constants/endpoint.ts";
import PAGES from "../../constants/pages.ts";
import {
    auth,
    getCountryByIndicative,
    getOtpMessageDataBySlug,
    getPhoneWithIndicative,
    loadTransferServiceHost,
    phoneIsValid,
    phoneObjectToString,
    removeCharacter,
    storage
} from "../../helpers";
import useAuth from "../../hooks/useAuth.ts";
import useFetchTransferFees from "../../hooks/useFetchTransferFees.ts";
import useManageWalletChannels from "../../hooks/useManageWalletChannels.ts";
import useModal from "../../hooks/useModal.ts";
import useToast from "../../hooks/useToast.ts";
import useTransferModal from "../../hooks/useTransferModal.ts";
import rules from "../../schemas";
import { RootState } from "../../store";
import {
    FormDataType,
    resetTransferFormData,
    setRetryTransfer,
    updateTransferFormData
} from "../../store/transferSlice.ts";
import {
    setStoreShowTransferKycValidateMessage,
    setStoreTransferCardKycValidateMessage
} from "../../store/uiSlice.ts";
import { StatusType, TransferFlowEnum, TransferType } from "../../types";
import AmountInput from "../AmountInput.tsx";
import Container from "../Container.tsx";
import DirectionCircle from "../DirectionCircle.tsx";
import FeesPreview from "../FeesPreview.tsx";
import InputField from "../InputField.tsx";
import ConfirmTransfertModal from "../modals/ConfirmTransfertModal.tsx";
import DetailTransferModal from "../modals/DetailTransferModal.tsx";
import LimitTransferModal from "../modals/LimitTransferModal.tsx";
import LoginFormModal from "../modals/LoginFormModal.tsx";
import OtpValidTransferModal from "../modals/OtpValidTransferModal.tsx";
import RegisterAlertModal from "../modals/RegisterAlertModal.tsx";
import TransferInLive from "../modals/TransferInLive.tsx";
import OtpMessage from "../OtpMessage.tsx";
import PhoneInput from "../PhoneInput.tsx";
import AddCardOrNumberButton from "../SecondaryCardOrNumber/AddCardOrNumberButton.tsx";
import WalletSelect from "../WalletSelect.tsx";

interface Props {
    onSuccess: () => void;
}

const TOAST_DURATION = 10000;

const RETRY_TRANSFER_MORE_THAN_100000 =
    "transfer_amount_more_than_100000_FCFA_can_t_retry";

const USER_WITHOUT_ACCOUNT_RECEIVE_AMOUNT_MORE_LIMIT =
    "user_without_account_receive_amount_more_limite";

const KYC_UNVALIDATED = "kyc_unvalidated";

const RETRY_TRANSFER_LIMIT_PER_WEEK = "retry_transfer_limitation_per_week";

const CARD_LIMIT_NUMBER_PER_DAY_PER_ACCOUNT =
    "transfer_card_limitation_number_per_day_per_account";
const CARD_LIMIT_AMOUNT_PER_DAY_PER_ACCOUNT =
    "transfer_card_limitation_amount_per_day_per_account";
const CARD_LIMIT_AMOUNT_PER_WEEK_PER_ACCOUNT =
    "transfer_card_limitation_amount_per_week_per_account";
const CARD_LIMIT_AMOUNT_PER_MONTH_PER_ACCOUNT =
    "transfer_card_limitation_amount_per_month_per_account";

const CARD_LIMIT_NUMBER_PER_DAY_PER_CARD =
    "transfer_card_limitation_number_per_day_per_card";
const CARD_LIMIT_AMOUNT_PER_DAY_PER_CARD =
    "transfer_card_limitation_amount_per_day_per_card";
const CARD_LIMIT_AMOUNT_PER_WEEK_PER_CARD =
    "transfer_card_limitation_amount_per_week_per_card";
const CARD_LIMIT_AMOUNT_PER_MONTH_PER_CARD =
    "transfer_card_limitation_amount_per_month_per_card";
const RETRY_TRANSFER_LIMIT_PER_TRANSFER =
    "retry_transfer_limitation_per_transfer";
const MIN_PAYOUT_OM_MALI = "om_mali_pay_out_channel_min_amount";

const TRANSFER_WALLET_LIMITATION_AMOUNT_PER_DAY_PER_ACCOUNT =
    "transfer_wallet_limitation_amount_per_day_per_account";
const TRANSFER_WALLET_LIMITATION_AMOUNT_PER_WEEK_PER_ACCOUNT =
    "transfer_wallet_limitation_amount_per_week_per_account";
const TRANSFER_WALLET_LIMITATION_AMOUNT_PER_MONTH_PER_ACCOUNT =
    "transfer_wallet_limitation_amount_per_month_per_account";
const RECEIVER_NAME_REQUIRED = "receiver_name_required";

const TRANSFER_WALLET_LIMITATION_AMOUNT_PER_ACCOUNT = [
    TRANSFER_WALLET_LIMITATION_AMOUNT_PER_DAY_PER_ACCOUNT,
    TRANSFER_WALLET_LIMITATION_AMOUNT_PER_WEEK_PER_ACCOUNT,
    TRANSFER_WALLET_LIMITATION_AMOUNT_PER_MONTH_PER_ACCOUNT
];

const CARD_LIMIT_CASE_AND_OTHER = [
    CARD_LIMIT_NUMBER_PER_DAY_PER_ACCOUNT,
    CARD_LIMIT_AMOUNT_PER_DAY_PER_ACCOUNT,
    CARD_LIMIT_AMOUNT_PER_WEEK_PER_ACCOUNT,
    CARD_LIMIT_AMOUNT_PER_MONTH_PER_ACCOUNT,
    CARD_LIMIT_NUMBER_PER_DAY_PER_CARD,
    CARD_LIMIT_AMOUNT_PER_DAY_PER_CARD,
    CARD_LIMIT_AMOUNT_PER_WEEK_PER_CARD,
    CARD_LIMIT_AMOUNT_PER_MONTH_PER_CARD
];

const RETRY_TRANSFER_CASE = [
    RETRY_TRANSFER_MORE_THAN_100000,
    RETRY_TRANSFER_LIMIT_PER_WEEK,
    RETRY_TRANSFER_LIMIT_PER_TRANSFER,
    MIN_PAYOUT_OM_MALI
];

const CASE_409 = [
    ...CARD_LIMIT_CASE_AND_OTHER,
    ...RETRY_TRANSFER_CASE,
    ...TRANSFER_WALLET_LIMITATION_AMOUNT_PER_ACCOUNT,
    RECEIVER_NAME_REQUIRED
];

const TransferSchema = yup.object().shape({
    transferType: rules.transferType,
    pay_in_channel_id: rules.idNumber,
    pay_out_channel_id: rules.idNumber,
    receiver_phone: rules.phone,
    amount: rules.amount,
    sender_transfer_card_id: yup
        .string()
        .when("transferType", ([transferType], schema) => {
            return transferType === "card" ? schema.required() : schema;
        }),
    sender_phone: yup
        .string()
        .when("transferType", ([transferType], schema) => {
            return transferType === "transfer" ? rules.phone : schema;
        }),
    receiver_name: yup.string().when("amount", ([amount], schema) => {
        return amount > LIMIT_AMOUNT ? schema.required() : schema;
    })
});

const LIMIT_ENTER_RECIPIENT = 100000;

const TransferForm = (props: Props) => {
    const { t } = useTranslation();
    const { onSuccess } = props;
    const [errors, setErrors] = useState<{ [key: string]: string | string[] }>(
        {}
    );
    const [loading, setLoading] = useState(false);
    const [showRegisterAlertModal, setShowRegisterAlertModal] = useState(false);
    const [condition, setCondition] = useState(!!auth()?.isLogin);
    const [transfer, setTransfer] = useState<TransferType | null>(null);
    const [quotaExceeded, setQuotaExceeded] = useState("");
    const [transferInProd, setTransferInProd] = useState(false);

    const [firstTransfer, setFirstTransfer] = useState(false);
    const [secondTransfer, setSecondTransfer] = useState(false);
    const [haveAccount, setHaveAccount] = useState(false);
    const [validationOtpToken, setValidationOtpToken] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [payInChannelNotAvailable, setPayInChannelNotAvailable] =
        useState(false);
    const [payOutChannelNotAvailable, setPayOutChannelNotAvailable] =
        useState(false);

    const ref = useRef(false);

    const { isOpen, closeModal, openModal } = useModal();
    const {
        isOpen: isConfirmModalIsOpen,
        closeModal: closeConfirmModal,
        openModal: openConfirmModal
    } = useModal();
    const {
        isOpen: isLimitTransferModalOpen,
        closeModal: closeLimitTransferModal,
        openModal: openLimitTransferModal
    } = useModal();
    const { kycActionData, reFetchAuthUser, isLogin, user } = useAuth();

    const { formData, fees, retryTransfer } = useSelector(
        (state: RootState) => state.transfer
    );
    const {
        showTransferCardKycValidateMessage,
        showTransferKycValidateMessage
    } = useSelector((state: RootState) => state.ui);

    const dispatch = useDispatch();
    const fetchFees = useFetchTransferFees();
    const { getDefaultWallet, getDefaultWalletByCountryCode, getCountryById } =
        useManageWalletChannels();

    const { successToast, errorToast } = useToast();
    const { openTransferModal } = useTransferModal();
    const navigate = useNavigate();

    const handleCloseModal = () => {
        firstTransfer && setFirstTransfer(false);
        transfer && setTransfer(null);
        secondTransfer && setSecondTransfer(false);
        showRegisterAlertModal && setShowRegisterAlertModal(false);
        haveAccount && setHaveAccount(false);
        closeModal();
    };

    const authFormData = useMemo(() => {
        const phone = getPhoneWithIndicative(user?.phone_with_indicative);
        return {
            phone: phone,
            phoneCountry: getCountryByIndicative(phone?.indicative || ""),
            wallet: ""
        };
    }, [user?.phone_with_indicative]);

    const secondaryNumbersData = useMemo(() => {
        const value = formData.senderPhone.phoneNumber
            ? {
                  value: `${formData.senderPhone.indicative} ${formData.senderPhone.phoneNumber}`,
                  label: `${formData.senderPhone.indicative} ${formData.senderPhone.phoneNumber}`
              }
            : null;

        const indicativePart = value?.value.split(" ")[0];
        const secondaryPhoneCountry = getCountryByIndicative(
            indicativePart || ""
        );

        if (!user?.account?.transfer_phone_numbers)
            return { options: [], value, secondaryPhoneCountry };

        let options = user?.account?.transfer_phone_numbers?.map(item => ({
            value: item.phone_with_indicative,
            label: item.phone_with_indicative,
            disable: !item.validated_at
        }));

        const phoneNumber = `${authFormData.phone?.indicative} ${authFormData.phone?.value}`;

        options = [
            {
                value: phoneNumber,
                label: phoneNumber,
                disable: false
            },
            ...options.filter(item => !item.disable)
        ];

        return { options, value, secondaryPhoneCountry };
    }, [
        authFormData.phone?.indicative,
        authFormData.phone?.value,
        formData.senderPhone.indicative,
        formData.senderPhone.phoneNumber,
        user?.account?.transfer_phone_numbers
    ]);

    const cardData = useMemo(() => {
        return {
            value: formData.card,
            option: (user?.account?.transfer_cards || [])
                .map(item => ({
                    ...item,
                    label: item.card_number,
                    value: item.id
                }))
                .filter(item => item.activated_at)
        };
    }, [formData.card, user?.account?.transfer_cards]);

    const handleConfirmTransfer = useCallback(() => {
        const phone =
            isLogin && user
                ? secondaryNumbersData?.options.length > 1
                    ? phoneObjectToString(formData.senderPhone)
                    : user.phone_with_indicative
                : phoneObjectToString(formData.senderPhone);

        const month = removeCharacter(formData?.expirationDate || "").substring(
            0,
            2
        );
        const year = removeCharacter(formData?.expirationDate || "").substring(
            2
        );

        const sendData = {
            transferType:
                formData.senderWallet && formData.senderWallet?.country_id
                    ? "transfer"
                    : "card",
            support_fees: formData.supportFees,
            sender_phone: (phone || "").split(" ")[1].toString() || undefined,
            pay_in_channel_id: formData.senderWallet?.id,
            pay_out_channel_id: formData.recipientWallet?.id,
            user_id: user?.id,
            receiver_phone: `${formData.recipientPhone.phoneNumber}`,
            receiver_name:
                parseInt(formData.senderAmount) > LIMIT_AMOUNT ||
                !formData.senderWallet?.country_id
                    ? formData.recipientName
                    : "",
            amount: formData.senderAmount,
            otp: formData.otp,
            sender_transfer_card_id: formData.card?.value,
            card_cvv: formData?.cvv || undefined,
            card_expired_date_month: month || undefined,
            card_expired_date_year: year || undefined,
            cgu: formData.senderWallet?.country_id ? undefined : condition
        };

        if (!formData.senderWallet?.has_otp) delete sendData?.otp;
        if (!sendData.receiver_name) delete sendData?.receiver_name;
        if (!sendData.user_id) delete sendData?.user_id;
        if (!sendData.sender_transfer_card_id)
            delete sendData?.sender_transfer_card_id;
        if (!sendData.card_cvv) delete sendData?.card_cvv;

        if (!sendData.card_expired_date_month)
            delete sendData.card_expired_date_month;
        if (!sendData.card_expired_date_year)
            delete sendData?.card_expired_date_year;
        if (!sendData.cgu) delete sendData.cgu;

        TransferSchema.validate(sendData, { abortEarly: false })
            .then(() => {
                const userPhone =
                    user?.phone_with_indicative?.split(" ")[1] || "";
                const senderPhone = `${formData.senderPhone.indicative}${
                    secondaryNumbersData?.options.length > 1
                        ? formData.senderPhone.phoneNumber
                        : userPhone || formData.senderPhone.phoneNumber
                }`;
                const receiverPhone = `${formData.recipientPhone.indicative}${formData.recipientPhone.phoneNumber}`;
                const senderPhoneIsNotValide = !phoneIsValid(senderPhone);
                const receiverPhoneIsNotValide = !phoneIsValid(receiverPhone);

                if (formData.senderWallet && formData.senderWallet.country_id) {
                    if (senderPhoneIsNotValide || receiverPhoneIsNotValide) {
                        setErrors({
                            ...errors,
                            receiver_phone: receiverPhoneIsNotValide
                                ? t("Invalid phone number")
                                : "",
                            sender_phone: senderPhoneIsNotValide
                                ? t("Invalid phone number")
                                : ""
                        });
                        return;
                    }
                } else {
                    if (receiverPhoneIsNotValide) {
                        setErrors({
                            ...errors,
                            receiver_phone: receiverPhoneIsNotValide
                                ? t("Invalid phone number")
                                : ""
                        });
                    }
                }

                if (condition) {
                    setLoading(true);

                    const endpoint = retryTransfer
                        ? ENDPOINTS.RETRY_TRANSFER.replace(
                              ":id",
                              retryTransfer.id.toString()
                          )
                        : ENDPOINTS.CREATE_TRANSFER;
                    loadTransferServiceHost();

                    if (sendData.transferType === "transfer") {
                        sendData.sender_phone = phone;
                        delete sendData.card_cvv;
                        delete sendData.card_expired_date_month;
                        delete sendData.card_expired_date_year;
                        delete sendData.sender_transfer_card_id;
                    } else {
                        delete sendData.sender_phone;
                    }
                    sendData.receiver_phone = `${formData.recipientPhone.indicative} ${formData.recipientPhone.phoneNumber}`;
                    quotaExceeded.length > 0 && setQuotaExceeded("");
                    window.axios[retryTransfer ? "put" : "post"](
                        endpoint,
                        sendData
                    )
                        .then(response => {
                            const data = response.data.data;
                            const token = data.token;
                            errorMessage && setErrorMessage("");
                            payInChannelNotAvailable &&
                                setPayInChannelNotAvailable(false);
                            payOutChannelNotAvailable &&
                                setPayOutChannelNotAvailable(false);
                            if (isLogin) {
                                reFetchAuthUser();
                                openTransferModal(data);

                                if (
                                    showTransferKycValidateMessage &&
                                    kycActionData.transferValidate
                                ) {
                                    dispatch(
                                        setStoreShowTransferKycValidateMessage(
                                            false
                                        )
                                    );
                                }

                                if (
                                    showTransferCardKycValidateMessage &&
                                    kycActionData.validate
                                ) {
                                    dispatch(
                                        setStoreTransferCardKycValidateMessage(
                                            false
                                        )
                                    );
                                }
                            } else {
                                setTransfer(data || null);
                            }

                            if (token) {
                                setValidationOtpToken(token);
                                successToast(
                                    t("Le code OTP à été envoyer avec success"),
                                    t(
                                        "Veuillez consulter votre messagerie pour obtenir le code OTP"
                                    )
                                );
                            } else {
                                Object.keys(errors).length && setErrors({});
                                setFirstTransfer(true);

                                if (retryTransfer)
                                    dispatch(setRetryTransfer(null));

                                if (data.url) {
                                    window.location.href = data.url;
                                } else {
                                    !isLogin && openModal();
                                    successToast(
                                        t(
                                            "L'initiation à été effectuer avec succès"
                                        ),
                                        t(
                                            "Vous pouvez maintenant consulter les détails de votre transfert."
                                        )
                                    );
                                    onSuccess();
                                }
                            }

                            setTimeout(() => {
                                dispatch(resetTransferFormData());
                            }, 200);
                        })
                        .catch(error => {
                            Object.keys(errors).length && setErrors({});
                            const status = error.response.status;
                            const message = error.response.data.message;
                            errorMessage.length && setErrorMessage("");
                            payInChannelNotAvailable &&
                                setPayInChannelNotAvailable(false);
                            payOutChannelNotAvailable &&
                                setPayOutChannelNotAvailable(false);
                            const responseErrors = error.response.data.errors;
                            if (
                                status === RESPONSE_STATUS.UNPROCESSABLE_ENTITY
                            ) {
                                const errorList: {
                                    [key: string]: string | string[];
                                } = {};
                                Object.keys(responseErrors)?.forEach(key => {
                                    errorList[key] = responseErrors[key][0];
                                });
                                setErrors(errorList);
                            }

                            const key = error.response.data?.output?.key || "";
                            if (status === RESPONSE_STATUS.CONFLICT) {
                                if (CASE_409.includes(key)) {
                                    reFetchAuthUser();
                                    setErrorMessage(message);
                                } else {
                                    switch (key) {
                                        /*case TransferFlowEnum.secondTransfer:
                                            openModal();
                                            setSecondTransfer(true);
                                            break;*/
                                        case TransferFlowEnum.secondTransfer:
                                            openLimitTransferModal();
                                            errorToast(
                                                t(
                                                    "Échec d'initiation du transfert"
                                                )
                                            );
                                            break;
                                        case TransferFlowEnum.userHaveAccount:
                                            errorToast(
                                                t(
                                                    "Transfert non pris en charge"
                                                ),
                                                t(
                                                    "Veuillez-vous connecter pour effectuer votre transfert"
                                                )
                                            );
                                            openModal();
                                            setHaveAccount(true);
                                            break;
                                        case TransferFlowEnum.incorrectReceiveAmount:
                                            setErrors({
                                                receiveAmount: t(
                                                    "Le montant de reception doit être supérieur ou égal à 200"
                                                )
                                            });
                                            errorToast(
                                                t(
                                                    "Échec d'initiation du transfert"
                                                ),
                                                t(
                                                    "Veuillez modifier le montant d'émission pour refaire le transfert"
                                                )
                                            );
                                            break;
                                        case USER_WITHOUT_ACCOUNT_RECEIVE_AMOUNT_MORE_LIMIT:
                                            setErrors({
                                                receiveAmount: message
                                            });
                                            openLimitTransferModal();
                                            if (!isLogin) {
                                                errorToast(
                                                    t(
                                                        "Échec d'initiation du transfert"
                                                    )
                                                );
                                            } else {
                                                errorToast(
                                                    t(
                                                        "Échec d'initiation du transfert"
                                                    ),
                                                    t(
                                                        "Veuillez modifier le montant d'émission pour refaire le transfert"
                                                    )
                                                );
                                            }
                                            break;
                                        case TransferFlowEnum.transferNotInit:
                                            errorToast(
                                                t(
                                                    "Échec d'initiation du transfert"
                                                ),
                                                t(
                                                    "Veuillez bien renseigner les informations"
                                                )
                                            );
                                            break;
                                        case KYC_UNVALIDATED:
                                            setQuotaExceeded(message);

                                            errorToast(
                                                t(
                                                    "Échec d'initiation du transfert"
                                                ),
                                                t(message),
                                                TOAST_DURATION
                                            );
                                            break;
                                        case TransferFlowEnum.userConnectedNotOk:
                                            errorToast(
                                                t(
                                                    "Échec d'initiation du transfert"
                                                ),
                                                t(
                                                    "Le numéro de téléphone de l'utilisateur connecté est différent du numéro de téléphone émettrice de la transaction. Veuillez réessayer."
                                                ),
                                                TOAST_DURATION
                                            );
                                            break;
                                        case TransferFlowEnum.payInChanelNotAvailable:
                                            errorToast(
                                                t(
                                                    "Échec d'initiation du transfert"
                                                ),
                                                t(""),
                                                TOAST_DURATION
                                            );
                                            setPayInChannelNotAvailable(true);
                                            break;
                                        case TransferFlowEnum.payOutChanelNotAvailable:
                                            errorToast(
                                                t(
                                                    "Échec d'initiation du transfert"
                                                ),
                                                t(""),
                                                TOAST_DURATION
                                            );
                                            setPayOutChannelNotAvailable(true);
                                            break;
                                        case TransferFlowEnum.phoneNumberUnauthorized:
                                            errorToast(
                                                t(
                                                    "Échec d'initiation du transfert"
                                                )
                                            );
                                            setTransferInProd(true);
                                            break;
                                        default:
                                            break;
                                    }
                                }
                            }
                        })
                        .finally(() => setLoading(false));
                } else {
                    errorToast(
                        t("Echec de soumission du formulaire"),
                        t("Veuillez accepter les condition d'utilisation")
                    );
                }
            })
            .catch((validationErrors: yup.ValidationError) => {
                const validationErrorsMap: { [key: string]: string } = {};
                validationErrors.inner.forEach(error => {
                    if (error.path) {
                        validationErrorsMap[error.path] = error.message;
                    }
                });
                setErrors({ ...errors, ...validationErrorsMap });
            })
            .finally(() => closeConfirmModal());
    }, [
        isLogin,
        user,
        secondaryNumbersData?.options.length,
        formData.senderPhone,
        formData?.expirationDate,
        formData.senderWallet,
        formData.supportFees,
        formData.recipientWallet?.id,
        formData.recipientPhone.phoneNumber,
        formData.recipientPhone.indicative,
        formData.senderAmount,
        formData.recipientName,
        formData.otp,
        formData.card?.value,
        formData?.cvv,
        condition,
        errors,
        t,
        retryTransfer,
        quotaExceeded.length,
        errorMessage,
        payInChannelNotAvailable,
        payOutChannelNotAvailable,
        reFetchAuthUser,
        openTransferModal,
        showTransferKycValidateMessage,
        kycActionData.transferValidate,
        kycActionData.validate,
        showTransferCardKycValidateMessage,
        successToast,
        dispatch,
        openModal,
        onSuccess,
        openLimitTransferModal,
        errorToast,
        closeConfirmModal
    ]);

    const handleSubmit = useCallback(
        (e: FormEvent) => {
            e.preventDefault();
            openConfirmModal();
        },
        [openConfirmModal]
    );

    const handleSelectPayInWallet = useCallback(
        (value: SelectItemType | null) => {
            let newFormData = {
                ...formData,
                senderWallet: value,
                senderPhone: {
                    phoneNumber: formData.senderPhone.phoneNumber,
                    indicative: value?.countryIndicative as string
                }
            };
            if (value?.country_id) {
                setCondition(true);
                newFormData = {
                    ...newFormData,
                    senderWallet: value,
                    senderPhone: {
                        phoneNumber: formData.senderPhone.phoneNumber,
                        indicative: value?.countryIndicative as string
                    }
                };
            } else {
                setCondition(false);
                const card = cardData.option.length ? cardData.option[0] : null;

                newFormData = {
                    ...newFormData,
                    senderWallet: value,
                    card
                };
            }
            dispatch(updateTransferFormData(newFormData));
            value?.id &&
                fetchFees(
                    {
                        payInChannel: value?.id
                    },
                    newFormData
                );
        },
        [cardData.option, dispatch, fetchFees, formData]
    );

    const handleSelectPayoutWallet = useCallback(
        (value: SelectItemType | null) => {
            const newFormData = {
                ...formData,
                recipientWallet: value,
                recipientPhone: {
                    phoneNumber: formData.recipientPhone.phoneNumber,
                    indicative: value?.countryIndicative as string
                }
            };
            dispatch(updateTransferFormData(newFormData));
            value?.id &&
                fetchFees(
                    {
                        payOutChannel: value?.id
                    },
                    newFormData
                );
        },
        [dispatch, fetchFees, formData]
    );

    useEffect(() => {
        let defaultPayInWallet = getDefaultWallet(false);
        if (isLogin) {
            if (formData.senderWallet && !formData.senderWallet?.country_id) {
                defaultPayInWallet =
                    formData.senderWallet as typeof defaultPayInWallet;
            }
        }
        let defaultPayOutWallet = getDefaultWallet(true);

        let senderPhone = {
            phoneNumber: formData.senderPhone.phoneNumber,
            indicative: defaultPayInWallet?.countryIndicative as string
        };

        let recipientPhone = {
            phoneNumber: formData.recipientPhone.phoneNumber,
            indicative: defaultPayOutWallet?.countryIndicative as string
        };

        if (!ref.current && defaultPayInWallet && defaultPayOutWallet) {
            ref.current = true;
            const transferFormDataString = storage().getItem(
                STORAGE.TRANSFER_FORM_DATA
            );

            if (transferFormDataString !== null) {
                const formatTransferFormData: FormDataType | null =
                    transferFormDataString
                        ? JSON.parse(transferFormDataString)
                        : null;

                if (isLogin && formatTransferFormData) {
                    const checkPhoneNumber = (phoneWithIndicative: string) => {
                        const [indicative, phoneNumber] =
                            phoneWithIndicative.split(" ");

                        return (
                            indicative ===
                                formatTransferFormData?.senderPhone
                                    .indicative &&
                            phoneNumber ===
                                formatTransferFormData?.senderPhone.phoneNumber
                        );
                    };

                    if (checkPhoneNumber(user.phone_with_indicative || "")) {
                        if (formatTransferFormData.senderWallet) {
                            defaultPayInWallet =
                                formatTransferFormData.senderWallet;
                        }

                        senderPhone = formatTransferFormData.senderPhone;
                    }

                    if (formatTransferFormData.recipientWallet) {
                        defaultPayOutWallet =
                            formatTransferFormData.recipientWallet;
                    }
                    recipientPhone = formatTransferFormData.recipientPhone;
                }

                if (!isLogin && formatTransferFormData) {
                    if (formatTransferFormData.senderWallet) {
                        defaultPayInWallet =
                            formatTransferFormData.senderWallet;
                    }
                    if (formatTransferFormData.recipientWallet) {
                        defaultPayOutWallet =
                            formatTransferFormData.recipientWallet;
                    }

                    senderPhone = formatTransferFormData.senderPhone;
                    recipientPhone = formatTransferFormData.recipientPhone;
                }
            }

            if (defaultPayInWallet && defaultPayOutWallet) {
                const newFormData = {
                    ...formData,
                    senderWallet: defaultPayInWallet,
                    recipientWallet: defaultPayOutWallet,
                    senderPhone: senderPhone,
                    recipientPhone: recipientPhone
                };

                dispatch(updateTransferFormData(newFormData));
                fetchFees(
                    {
                        payInChannel: defaultPayInWallet?.id,
                        payOutChannel: defaultPayOutWallet?.id
                    },
                    newFormData
                );
            }
        }
    }, [
        dispatch,
        fetchFees,
        formData,
        getDefaultWallet,
        handleSelectPayInWallet,
        handleSelectPayoutWallet,
        isLogin,
        user.phone_with_indicative
    ]);

    const amountReceived = useMemo(() => {
        if (!fees?.value || fees?.value === "0") return formData.senderAmount;

        if (formData.supportFees) return formData.senderAmount;

        return parseFloat(formData.senderAmount) - parseFloat(fees.value);
    }, [fees.value, formData.senderAmount, formData.supportFees]);

    const otpMessageData = useMemo(() => {
        return getOtpMessageDataBySlug(formData.senderWallet?.slug);
    }, [formData.senderWallet?.slug]);

    const buttonSendDisable = useMemo(() => {
        if (kycActionData.transferRejected) return true;
        const senderPhone = isLogin ? true : !!formData.senderPhone.phoneNumber;
        const recipientName =
            parseInt(formData.senderAmount) > LIMIT_ENTER_RECIPIENT ||
            (formData.senderWallet && !formData.senderWallet?.country_id)
                ? !!formData.recipientName
                : true;

        const check = !(
            !!formData.senderAmount &&
            senderPhone &&
            !!formData.senderWallet &&
            !!formData.recipientPhone.phoneNumber &&
            !!formData.recipientWallet &&
            recipientName
        );

        return isLogin ? check : condition && check;
    }, [
        condition,
        formData.recipientName,
        formData.recipientPhone.phoneNumber,
        formData.recipientWallet,
        formData.senderAmount,
        formData.senderPhone.phoneNumber,
        formData.senderWallet,
        isLogin,
        kycActionData.transferRejected
    ]);

    const showCGU = useMemo(() => {
        return !isLogin || !formData.senderWallet?.country_id;
    }, [formData.senderWallet?.country_id, isLogin]);

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Container className={isLogin ? "" : "lg:w-3/6"}>
                    <div className="flex space-x-2 lg:space-x-4 items-center">
                        <DirectionCircle>{t("De")}</DirectionCircle>

                        <div className="flex-1">
                            <AmountInput
                                id="sender_amount"
                                name="sender_amount"
                                required={true}
                                className="mb-3"
                                placeholder={t("Montant envoyer")}
                                value={formData.senderAmount}
                                autoFocus={true}
                                onValueChange={value => {
                                    const newFormData = {
                                        ...formData,
                                        senderAmount: value
                                    };
                                    dispatch(
                                        updateTransferFormData(newFormData)
                                    );
                                    fetchFees({ amount: value }, newFormData);
                                }}
                                error={errors.amount}
                                disabled={
                                    kycActionData.transferRejected ||
                                    !!retryTransfer
                                }
                            />

                            <div className="flex items-start space-x-3">
                                <WalletSelect
                                    value={formData.senderWallet}
                                    onChange={handleSelectPayInWallet}
                                    data-testid="payin"
                                    country={
                                        secondaryNumbersData?.secondaryPhoneCountry ||
                                        authFormData.phoneCountry
                                    }
                                    disabled={
                                        kycActionData.transferRejected ||
                                        !!retryTransfer
                                    }
                                />

                                <div className="space-y-2 w-full">
                                    {isLogin &&
                                    formData.senderWallet &&
                                    (secondaryNumbersData.options.length > 1 ||
                                        !formData.senderWallet?.country_id) ? (
                                        <>
                                            {formData.senderWallet &&
                                            formData.senderWallet
                                                ?.country_id ? (
                                                <div>
                                                    <Select
                                                        value={
                                                            secondaryNumbersData.value
                                                        }
                                                        onChange={value => {
                                                            const indicativeValue =
                                                                value?.value.split(
                                                                    " "
                                                                )[0] ||
                                                                undefined;
                                                            const phoneValue =
                                                                value?.value.split(
                                                                    " "
                                                                )[1] ||
                                                                undefined;

                                                            if (
                                                                indicativeValue &&
                                                                phoneValue &&
                                                                `${indicativeValue}${phoneValue}` !==
                                                                    `${formData.senderPhone.indicative}${formData.senderPhone.phoneNumber}`
                                                            ) {
                                                                const countryCode =
                                                                    getCountryByIndicative(
                                                                        indicativeValue
                                                                    );
                                                                const defaultWalletByCountryCode =
                                                                    countryCode
                                                                        ? getDefaultWalletByCountryCode(
                                                                              countryCode,
                                                                              true
                                                                          )
                                                                        : null;

                                                                dispatch(
                                                                    updateTransferFormData(
                                                                        {
                                                                            ...formData,
                                                                            senderPhone:
                                                                                {
                                                                                    phoneNumber:
                                                                                        phoneValue,
                                                                                    indicative:
                                                                                        indicativeValue
                                                                                },
                                                                            senderWallet:
                                                                                defaultWalletByCountryCode?.available_at
                                                                                    ? defaultWalletByCountryCode
                                                                                    : null
                                                                        }
                                                                    )
                                                                );
                                                            }
                                                        }}
                                                        options={
                                                            secondaryNumbersData.options
                                                        }
                                                        disabled={
                                                            kycActionData.transferRejected ||
                                                            !kycActionData.transferValidate ||
                                                            !!retryTransfer
                                                        }
                                                        placeholder={t(
                                                            "Sélectionner un numéro"
                                                        )}
                                                    />

                                                    {!!errors?.sender_phone && (
                                                        <p className="text-xs text-red-500">
                                                            {
                                                                errors?.sender_phone
                                                            }
                                                        </p>
                                                    )}
                                                </div>
                                            ) : (
                                                <div>
                                                    <Select
                                                        value={cardData.value}
                                                        options={
                                                            cardData.option
                                                        }
                                                        onChange={value => {
                                                            dispatch(
                                                                updateTransferFormData(
                                                                    {
                                                                        ...formData,
                                                                        card: value
                                                                    }
                                                                )
                                                            );
                                                        }}
                                                        placeholder={t(
                                                            "Sélectionner une carte"
                                                        )}
                                                        disabled={
                                                            kycActionData.transferRejected ||
                                                            !kycActionData.cardValidate ||
                                                            !!retryTransfer
                                                        }
                                                    />

                                                    {!!errors?.sender_transfer_card_id && (
                                                        <p className="text-xs text-red-500">
                                                            {
                                                                errors?.sender_transfer_card_id
                                                            }
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <PhoneInput
                                            id="sender_phone"
                                            name="sender_phone"
                                            placeholder={t("Numéro")}
                                            disabledIndicative
                                            disabled={isLogin}
                                            indicative={
                                                authFormData.phone
                                                    ?.indicative ||
                                                formData.senderPhone.indicative
                                            }
                                            value={
                                                authFormData.phone?.value ||
                                                formData.senderPhone.phoneNumber
                                            }
                                            onChange={e =>
                                                dispatch(
                                                    updateTransferFormData({
                                                        ...formData,
                                                        senderPhone: {
                                                            phoneNumber:
                                                                e.target.value,
                                                            indicative:
                                                                formData
                                                                    .senderPhone
                                                                    .indicative
                                                        }
                                                    })
                                                )
                                            }
                                            error={errors.sender_phone}
                                        />
                                    )}

                                    {!!formData.senderWallet?.has_otp &&
                                        !retryTransfer && (
                                            <div className="mt-3">
                                                {otpMessageData && (
                                                    <OtpMessage
                                                        {...otpMessageData}
                                                    />
                                                )}

                                                <InputField
                                                    className="cursor-pointer"
                                                    placeholder={t("Code OTP")}
                                                    value={formData.otp}
                                                    onChange={e =>
                                                        dispatch(
                                                            updateTransferFormData(
                                                                {
                                                                    ...formData,
                                                                    otp: e
                                                                        .target
                                                                        .value
                                                                }
                                                            )
                                                        )
                                                    }
                                                    disabled={
                                                        kycActionData.transferRejected ||
                                                        !!retryTransfer
                                                    }
                                                    error={errors.otp}
                                                />
                                            </div>
                                        )}
                                </div>
                            </div>

                            {formData?.senderWallet &&
                                !formData?.senderWallet?.country_id && (
                                    <div className="mt-2 space-x-3 flex items-center">
                                        <InputField
                                            type="text"
                                            inputMode="numeric"
                                            isExpirationDate
                                            className="max-w-[84px] lg:max-w-[107px]"
                                            value={formData.expirationDate}
                                            onChange={e => {
                                                dispatch(
                                                    updateTransferFormData({
                                                        ...formData,
                                                        expirationDate:
                                                            e.target.value
                                                    })
                                                );
                                            }}
                                            placeholder="MM/YY"
                                        />

                                        <InputField
                                            isCvv
                                            type="text"
                                            placeholder="CVV"
                                            inputMode="numeric"
                                            className="w-full"
                                            value={formData.cvv}
                                            onChange={e => {
                                                dispatch(
                                                    updateTransferFormData({
                                                        ...formData,
                                                        cvv: e.target.value
                                                    })
                                                );
                                            }}
                                        />
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className="flex items-center justify-between mt-4">
                        <CheckboxInput
                            checked={formData.supportFees}
                            onChange={e =>
                                dispatch(
                                    updateTransferFormData({
                                        ...formData,
                                        supportFees: e.target.checked
                                    })
                                )
                            }
                            className="ml-20"
                            id="accpet-fees"
                            disabled={kycActionData.transferRejected}
                        >
                            {t("Je supporte les frais")}
                        </CheckboxInput>

                        {!kycActionData.transferRejected &&
                            (kycActionData.transferValidate ||
                                kycActionData.cardValidate) && (
                                <AddCardOrNumberButton />
                            )}
                    </div>
                </Container>

                <hr className="my-6 border-dashed border-[1.5px]" />

                <Container className={isLogin ? "" : "lg:w-3/6"}>
                    <div className="flex space-x-2 lg:space-x-4 items-center">
                        <DirectionCircle>{t("Vers")}</DirectionCircle>

                        <div className="flex-1">
                            <div className="flex items-start mb-3 space-x-3">
                                <WalletSelect
                                    value={formData.recipientWallet}
                                    isPayInChannel={false}
                                    data-testid="payout"
                                    onChange={handleSelectPayoutWallet}
                                    disabled={kycActionData.transferRejected}
                                />

                                <PhoneInput
                                    id="recipient-phone"
                                    name="recipient_phone"
                                    placeholder={t("Numéro")}
                                    disabledIndicative
                                    disabled={kycActionData.transferRejected}
                                    indicative={
                                        formData.recipientPhone.indicative
                                    }
                                    value={formData.recipientPhone.phoneNumber}
                                    onChange={e =>
                                        dispatch(
                                            updateTransferFormData({
                                                ...formData,
                                                recipientPhone: {
                                                    phoneNumber: e.target.value,
                                                    indicative:
                                                        formData.recipientPhone
                                                            .indicative
                                                }
                                            })
                                        )
                                    }
                                    error={errors.receiver_phone}
                                />
                            </div>

                            <AmountInput
                                placeholder={t("Montant reçu")}
                                disabled
                                value={amountReceived.toString()}
                                name="amount-received"
                                onValueChange={value =>
                                    dispatch(
                                        updateTransferFormData({
                                            ...formData,
                                            recipientAmount: value
                                        })
                                    )
                                }
                                error={errors?.receiveAmount}
                            />

                            {(parseInt(formData.senderAmount) >=
                                LIMIT_ENTER_RECIPIENT ||
                                (formData.senderWallet &&
                                    !formData.senderWallet?.country_id)) && (
                                <InputField
                                    placeholder={t("Nom du destinataire")}
                                    className="mt-3"
                                    name="receiver_name"
                                    value={formData.recipientName}
                                    disabled={kycActionData.transferRejected}
                                    onChange={e =>
                                        dispatch(
                                            updateTransferFormData({
                                                ...formData,
                                                recipientName: e.target.value
                                            })
                                        )
                                    }
                                    error={errors.receiver_name}
                                />
                            )}
                        </div>
                    </div>

                    <FeesPreview className="my-2" />

                    {quotaExceeded.length > 0 && (
                        <p className="text-xs text-red-500 text-center mb-4">
                            {user?.account?.transfer_kyc_status ===
                                StatusType.toValidate &&
                            user?.account?.card_kyc_status ===
                                StatusType.toValidate
                                ? quotaExceeded
                                : t(
                                      "Limite de transfert atteinte. Veuillez patienter pendant la validation de votre compte"
                                  )}
                        </p>
                    )}

                    {errorMessage.length > 0 && (
                        <p className="text-xs text-red-500 text-center mb-4">
                            {errorMessage}
                        </p>
                    )}

                    {payInChannelNotAvailable && (
                        <p className="text-xs text-red-500 text-center mb-4">
                            {t(
                                "Le moyen de paiement :method n'est pas disponible en :position",
                                {
                                    method: `${
                                        formData?.senderWallet?.label
                                    } (${
                                        getCountryById(
                                            true,
                                            formData?.senderWallet?.country_id
                                        )?.label || "---"
                                    })`,
                                    position: t("émission")
                                }
                            )}
                        </p>
                    )}

                    {payOutChannelNotAvailable && (
                        <p className="text-xs text-red-500 text-center mb-4">
                            {t(
                                "Le moyen de paiement :method n'est pas disponible en :position",
                                {
                                    method: `${
                                        formData?.recipientWallet?.label
                                    } (${
                                        getCountryById(
                                            false,
                                            formData?.recipientWallet
                                                ?.country_id
                                        )?.label || "---"
                                    })`,
                                    position: t("reception")
                                }
                            )}
                        </p>
                    )}

                    {showCGU && (
                        <CheckboxInput
                            className="justify-center mb-4"
                            id="policy-condition"
                            checked={condition}
                            name="check-condition"
                            onChange={e => setCondition(e.target.checked)}
                        >
                            {t("En envoyant, j’accepte les")}{" "}
                            <NavLink
                                to={PAGES.PRIVACY_POLICY}
                                className="text-primary hover:underline"
                            >
                                {t("conditions générales d’utilisation")}
                            </NavLink>
                            .
                        </CheckboxInput>
                    )}

                    {!(retryTransfer && !user?.account?.can_retry_transfer) && (
                        <Button
                            id="transfer-button"
                            type="submit"
                            loading={loading}
                            disabled={!condition || buttonSendDisable}
                            data-cy="submit"
                        >
                            {retryTransfer
                                ? t("Réessayer le transfert")
                                : t("Envoyer")}
                        </Button>
                    )}
                </Container>
            </form>

            <>
                {firstTransfer && (
                    <>
                        {!showRegisterAlertModal ? (
                            transfer && (
                                <DetailTransferModal
                                    isOpen={isOpen}
                                    transfer={transfer}
                                    closeModal={() => {
                                        if (!isLogin) {
                                            setShowRegisterAlertModal(true);
                                            navigate(PAGES.REGISTER);
                                        } else {
                                            handleCloseModal();
                                        }
                                    }}
                                    showRegisterButton
                                />
                            )
                        ) : (
                            <RegisterAlertModal
                                isOpen={isOpen}
                                description={t("registerAlert1")}
                                closeModal={() => {
                                    handleCloseModal();
                                }}
                            />
                        )}
                    </>
                )}

                {secondTransfer && (
                    <RegisterAlertModal
                        isOpen={isOpen}
                        description={t("registerAlert2")}
                        closeModal={() => {
                            handleCloseModal();
                        }}
                    />
                )}

                {haveAccount && (
                    <LoginFormModal
                        isOpen={isOpen}
                        closeModal={() => {
                            handleCloseModal();
                        }}
                    />
                )}

                {!!validationOtpToken && (
                    <OtpValidTransferModal
                        token={validationOtpToken}
                        isOpen={!!validationOtpToken}
                        closeModal={() => {
                            setValidationOtpToken("");
                            transfer && openModal();
                        }}
                    />
                )}

                {isConfirmModalIsOpen && (
                    <ConfirmTransfertModal
                        isOpen={isConfirmModalIsOpen}
                        closeModal={() => {
                            !loading && closeConfirmModal();
                        }}
                        onConfirm={handleConfirmTransfer}
                        number={`${formData.recipientPhone.indicative} ${formData.recipientPhone.phoneNumber}`}
                        amount={amountReceived}
                        senderWallet={formData.senderWallet}
                        receiverWallet={formData.recipientWallet}
                    />
                )}

                <TransferInLive
                    isOpen={transferInProd}
                    closeModal={() => setTransferInProd(false)}
                />

                <LimitTransferModal
                    isOpen={isLimitTransferModalOpen}
                    closeModal={closeLimitTransferModal}
                />
            </>
        </>
    );
};

export default TransferForm;
