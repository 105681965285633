import Button from "@ui/Button.tsx";
import { Modal } from "@ui/Modal.tsx";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import PAGES from "../../constants/pages.ts";
import { ModalPropsType } from "../../types";
import LoCloseIcon from "../icons/LoCloseIcon.tsx";

const LimitTransferModal = (props: ModalPropsType) => {
    const { isOpen, closeModal } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
        closeModal();
        navigate(PAGES.REGISTER);
    }, [closeModal, navigate]);

    return (
        <Modal
            isOpen={isOpen}
            closeModal={handleClose}
            hiddeCloseIcon
            className="rounded-2xl"
        >
            <span className="mx-auto h-20 w-20 rounded-full bg-red-300 flex items-center justify-center mb-5">
                <LoCloseIcon className="text-white h-12 w-12" />
            </span>

            <h3 className="text-red-500 mb-3 text-center">
                {t("Limite de transfert atteinte !!")}
            </h3>

            <p className="text-sm text-center mb-5">
                {t(
                    "Vous devez vous inscrire pour continuer à envoyer de l'argent à vos proches"
                )}
            </p>

            <Button withAuto className="px-6 mx-auto" onClick={handleClose}>
                {t("S'inscrire")}
            </Button>
        </Modal>
    );
};

export default LimitTransferModal;
