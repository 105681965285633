import { useLayoutEffect } from "react";

import { STORAGE } from "../constants";
import { storage } from "../helpers";
import useModal from "../hooks/useModal.ts";

import CardToWalletAvailable from "./CardToWalletAvailable.tsx";
import InfoDescriptionModal from "./modals/InfoDescriptionModal.tsx";
// import MaintenanceModal from "./modals/MaintenanceModal.tsx";
// import SecondaryPhoneFeatureAvailable from "./SecondaryPhoneFeatureAvailable.tsx";

const AllAlertModals = () => {
    const {
        isOpen: isInfoDesOpen,
        closeModal: closeInfoDesc,
        openModal: openInfoDesc
    } = useModal(false);

    /*const {
        isOpen: isFeatSecondInfoOpen,
        openModal: openFeatSecond,
        closeModal: closeFeatSecond
    } = useModal(false);*/

    const {
        isOpen: isCardToWalletAvailable,
        openModal: openCardWalletInfo,
        closeModal: closeCardWalletInfo
    } = useModal(false);

    /*const {
        isOpen: isAvailableInfoOpen,
        openModal: openAvailableInfo,
        closeModal: closeAvailableInfo
    } = useModal(false);*/

    useLayoutEffect(() => {
        const data = storage().getItem(STORAGE.FIRST_TIME);

        if (!data) {
            storage().setItem(STORAGE.FIRST_TIME, JSON.stringify(true));
            openInfoDesc();
        }
    }, [openInfoDesc]);

    /* useLayoutEffect(() => {
        const data = storage().getItem(STORAGE.SECONDARY_NUMBER_FEATURE);

        if (!data) {
            storage().setItem(
                STORAGE.SECONDARY_NUMBER_FEATURE,
                JSON.stringify(true)
            );
            openFeatSecond();
        }
    }, [openFeatSecond]);*/

    useLayoutEffect(() => {
        const data = storage().getItem(STORAGE.CARD_WALLET_FEATURE);

        if (!data) {
            storage().setItem(
                STORAGE.CARD_WALLET_FEATURE,
                JSON.stringify(true)
            );
            openCardWalletInfo();
        }
    }, [openCardWalletInfo]);

    /*useLayoutEffect(() => {
        const data = storage().getItem(STORAGE.APP_AVAILABLE);

        if (!data) {
            storage().setItem(STORAGE.APP_AVAILABLE, JSON.stringify(true));
            openAvailableInfo();
        }
    }, [openAvailableInfo]);*/

    return (
        <>
            <InfoDescriptionModal
                isOpen={isInfoDesOpen}
                closeModal={closeInfoDesc}
            />

            {/*{!isInfoDesOpen && (
                <SecondaryPhoneFeatureAvailable
                    isOpen={isFeatSecondInfoOpen}
                    closeModal={closeFeatSecond}
                />
            )}*/}

            {!isInfoDesOpen && (
                <CardToWalletAvailable
                    isOpen={isCardToWalletAvailable}
                    closeModal={closeCardWalletInfo}
                />
            )}

            {/*{!isInfoDesOpen && !isFeatSecondInfoOpen && (
                <MaintenanceModal
                    isOpen={isAvailableInfoOpen}
                    closeModal={closeAvailableInfo}
                />
            )}*/}
        </>
    );
};

export default AllAlertModals;
