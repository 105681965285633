import { useTranslation } from "react-i18next";

import useTransferModal from "../hooks/useTransferModal.ts";
import { formatDate } from "../libs/date.ts";
import { StatusType, TransferType } from "../types";

interface Props {
    item: TransferType;
}

const TransferItem = (props: Props) => {
    const { t } = useTranslation();
    const { item: transfer } = props;
    const { openTransferModal } = useTransferModal();

    return (
        <>
            <div
                onClick={() => openTransferModal(transfer)}
                className="cursor-pointer text-gray-700 text-sm flex items-end justify-between"
            >
                <div className="text-left">
                    <p className="text-gray-400 mb-2">
                        {formatDate(transfer.created_at, "lll")}
                    </p>
                    <p>{t("Transfert")}</p>
                    <p>{transfer.amount} Fcfa</p>
                </div>

                <div className="text-end">
                    <p className="mb-2">
                        {transfer.status === StatusType.success && t("Succès")}
                        {transfer.status === StatusType.pending &&
                            t("En attente")}
                        {transfer.status === StatusType.failed && t("Echec")}
                    </p>
                    <p>{t("Envoyé à")}</p>
                    <p>{transfer.receiver_phone}</p>
                </div>
            </div>

            <hr className="my-4" />
        </>
    );
};

export default TransferItem;
