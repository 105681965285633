import { useDispatch } from "react-redux";

import ENDPOINTS from "../constants/endpoint.ts";
import { loadTransferServiceHost } from "../helpers";
import {
    FormDataType,
    setTransferFees,
    setTransferFeesLoading
} from "../store/transferSlice.ts";

interface FetchParametersType {
    amount?: string;
    payInChannel?: number | string;
    payOutChannel?: number | string;
}

const INVALID_FEES = "0";

const useFetchTransferFees = () => {
    const dispatch = useDispatch();

    return (data: FetchParametersType, formData: FormDataType) => {
        if (
            formData.recipientWallet?.id &&
            formData.senderAmount &&
            formData?.recipientWallet?.id
        ) {
            setTimeout(() => {
                const sendData = {
                    pay_in_channel_id:
                        data.payInChannel || formData.senderWallet?.id,
                    pay_out_channel_id:
                        data.payOutChannel || formData.recipientWallet?.id,
                    amount: data.amount || formData.senderAmount
                };

                loadTransferServiceHost();
                dispatch(setTransferFeesLoading(true));
                window.axios
                    .post(ENDPOINTS.GET_TRANSFER_FEES, sendData)
                    .then(response => {
                        dispatch(
                            setTransferFees(response.data.data?.fees || 0)
                        );
                    })
                    .catch(() => {
                        dispatch(setTransferFees(INVALID_FEES));
                    })
                    .finally(() => dispatch(setTransferFeesLoading(false)));
            }, 300);
        }
    };
};

export default useFetchTransferFees;
