import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ENDPOINTS from "../constants/endpoint.ts";
import { loadTransferServiceHost } from "../helpers";
import { RootState } from "../store";
import {
    addTransferList,
    setListLoading,
    updateListFilter
} from "../store/transferSlice.ts";
import { TransferType } from "../types";

const useManageTransfer = () => {
    const { isLogin } = useSelector((state: RootState) => state.auth);
    const {
        loading,
        filter,
        value: transfers
    } = useSelector((state: RootState) => state.transfer.list);
    const [hasMore, setHasMore] = useState(false);
    const transferRef = useRef<TransferType[]>([]);
    transferRef.current = transfers;

    const dispatch = useDispatch();

    const fetchTransfer = useCallback(
        (pageIndex: number, pageSize: number, search: string | null = null) => {
            if (isLogin) {
                loadTransferServiceHost();
                search !== null && setListLoading(true);
                window.axios
                    .get(`${ENDPOINTS.LIST_TRANSFER}`, {
                        params: {
                            page: pageIndex,
                            limit: pageSize,
                            search: search
                        }
                    })
                    .then(response => {
                        const data = response.data.data;
                        const total = data.total;
                        const lastPage = Math.ceil(total / pageSize);
                        if (pageIndex === 1) {
                            dispatch(addTransferList(data.transfers));
                        } else {
                            dispatch(
                                addTransferList([
                                    ...transferRef.current,
                                    ...data.transfers
                                ])
                            );
                        }
                        setHasMore(pageIndex < lastPage);
                    })
                    .finally(() => {
                        search !== null && setListLoading(false);
                    });
            }
        },
        [dispatch, isLogin]
    );

    const updateFilter = useCallback(
        (
            value: { pageIndex?: number; pageSize?: number; search?: string },
            clearSearch = false
        ) => {
            if (!value?.pageIndex) delete value.pageIndex;
            if (!value?.pageSize) delete value.pageSize;
            if (!value?.search && !clearSearch) {
                delete value.search;
            }

            dispatch(updateListFilter({ ...filter, ...value }));
        },
        [dispatch, filter]
    );

    return {
        fetchTransfer,
        loading,
        filter,
        transfers,
        hasMore,
        updateFilter
    };
};

export default useManageTransfer;
