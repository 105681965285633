import Select, { SelectProps } from "@ui/Select.tsx";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getWalletFilePath } from "../helpers";
import useAuth from "../hooks/useAuth.ts";
import useManageWalletChannels from "../hooks/useManageWalletChannels.ts";
import { ChannelType } from "../types";

import WalletPicture from "./WalletPicture.tsx";

interface Props extends SelectProps {
    isPayInChannel?: boolean;
    "data-testid"?: string;
    country?: string;
}
interface ChannelByCountry extends ChannelType {
    id: number;
    label: string;
    value: string;
    countryCode: string;
    countryIndicative: string;
    disable: boolean;
}

const WalletSelect = (props: Props) => {
    const { t } = useTranslation();
    const { isPayInChannel = true } = props;
    const manageWalletChannel = useManageWalletChannels();
    const { isLogin } = useAuth();
    const { kycActionData } = useAuth();

    const options = useMemo(() => {
        const channels = isPayInChannel
            ? manageWalletChannel.payinChannels
            : manageWalletChannel.payoutChannels;

        /*const filterChannels = channels.filter(item => {
            const unavailableWallet = item.options.filter(
                item => !item.available_at
            );

            if (unavailableWallet.length === item.options.length) return false;

            if (props.country)
                return (
                    item.codeCountry.toLowerCase() ===
                        props.country.toLowerCase() && item.options.length
                );
            return item.options.length;
        });

        return filterChannels.map(item => {
            return {
                ...item,
                options: item.options.filter(item => item.available_at)
            };
        });*/

        const filterChannels = channels.filter(item => {
            if (props.country && isLogin) {
                return (
                    props.country?.toLowerCase() ===
                    item.codeCountry.toLowerCase()
                );
            }

            return true;
        });

        const unavailableChannels: ChannelByCountry[] = [];
        let cardUnavailable: ChannelByCountry | null = null;
        let cardAvailable: ChannelByCountry | null = null;

        const formatChannels = filterChannels.map(item => {
            return {
                ...item,
                options: item.options
                    .map(wallet => {
                        if (!wallet.available_at && wallet.country_id) {
                            unavailableChannels.push({
                                ...wallet,
                                label: `${wallet.label} (${item.label})`,
                                disable: true
                            });
                        }

                        if (
                            !wallet.available_at &&
                            !wallet.country_id &&
                            !cardUnavailable &&
                            isPayInChannel
                        ) {
                            cardUnavailable = {
                                ...wallet,
                                label: `${wallet.label} (${item.label})`,
                                disable: true
                            };
                        }

                        if (
                            wallet.available_at &&
                            !wallet.country_id &&
                            isPayInChannel
                        ) {
                            cardAvailable = {
                                ...wallet,
                                disable: !wallet.available_at
                            };
                        }

                        return {
                            ...wallet,
                            disable: !wallet.available_at
                        };
                    })
                    .filter(item => {
                        if (isPayInChannel)
                            return !item.disable && item.country_id;

                        return !item.disable;
                    })
            };
        });

        if (cardUnavailable && isLogin && kycActionData.cardValidate)
            unavailableChannels.push(cardUnavailable);

        if (isLogin && kycActionData.cardValidate) {
            formatChannels.push({
                id: formatChannels.length,
                indicative: "",
                codeCountry: "",
                label: t("Indisponible"),
                options: unavailableChannels
            });
        }

        if (isLogin && cardAvailable && kycActionData.cardValidate) {
            formatChannels.unshift({
                id: formatChannels.length + 1,
                indicative: "",
                codeCountry: "",
                label: t("International"),
                options: [cardAvailable]
            });
        }

        return formatChannels.filter(item => item.options.length > 0);
    }, [
        isLogin,
        isPayInChannel,
        kycActionData.cardValidate,
        manageWalletChannel.payinChannels,
        manageWalletChannel.payoutChannels,
        props.country,
        t
    ]);

    return (
        <div>
            <Select
                className="w-[64px] lg:w-[107px]"
                buttonClassName="flex-nowrap border h-[55px] rounded-md py-1 px-2 lg:px-3 flex items-center justify-start"
                chevronContainerClassName="flex-shrink-0"
                value={props.value}
                onChange={props.onChange}
                options={options}
                data-testid={props["data-testid"]}
                isClearable
                disabled={props.disabled}
                onlyChevronOnRight
                formatDefaultValue={() => (
                    <WalletPicture src="/images/wallet/empty-wallet.png" />
                )}
                formatValue={value => {
                    return (
                        <WalletPicture src={getWalletFilePath(value.value)} />
                    );
                }}
            />
        </div>
    );
};

export default WalletSelect;
