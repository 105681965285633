import Button from "@ui/Button.tsx";
import { Dispatch, FormEvent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import config from "../../config";
import PAGES from "../../constants/pages.ts";
import ChevronIcon from "../icons/ChevronIcon.tsx";
import InputField from "../InputField.tsx";
import PhoneInput from "../PhoneInput.tsx";

interface Props {
    loading: boolean;
    hiddeForgotLink?: boolean;
    showPhoneInput?: boolean;
    invalidCredential?: boolean;
    showTitle?: boolean;
    errors: { [key: string]: string };
    onSubmit: (e: FormEvent) => void;
    indicative: {
        value: string;
        setValue: (value: string) => void;
    };
    pin: string;
    setPin: Dispatch<SetStateAction<string>>;
    phoneWithoutIndicative: string;
    setPhoneWithoutIndicative: Dispatch<SetStateAction<string>>;
}

const LoginForm = (props: Props) => {
    const {
        showTitle = true,
        showPhoneInput = true,
        invalidCredential,
        errors,
        pin,
        setPin,
        phoneWithoutIndicative,
        setPhoneWithoutIndicative,
        onSubmit
    } = props;

    const { t } = useTranslation();

    return (
        <form id="login-form" className="space-y-5" onSubmit={onSubmit}>
            {showTitle && (
                <h4 className="text-lg text-gray-700 font-medium mt-12 text-center md:text-left">
                    {t("Connectez-vous à votre compte")}
                </h4>
            )}

            {invalidCredential && (
                <p className="bg-red-100 px-4 py-3 rounded-md text-xs mb-5 text-red-700 text-center">
                    {t("Les identifants ne sont pas correcte.")}
                </p>
            )}

            {showPhoneInput && (
                <PhoneInput
                    id="tel"
                    name="phone_with_indicative"
                    placeholder={t("Numéro")}
                    indicative={props.indicative.value}
                    onIndicativeChange={value =>
                        props.indicative.setValue(value)
                    }
                    value={phoneWithoutIndicative}
                    onChange={e => setPhoneWithoutIndicative(e.target.value)}
                    error={errors.phone_with_indicative}
                />
            )}

            <div>
                <InputField
                    isPin
                    id="pin"
                    type="password"
                    inputMode="numeric"
                    value={pin}
                    onChange={e => setPin(e.target.value)}
                    placeholder={t("Code secret")}
                    error={errors.pin}
                />

                <div className="text-end">
                    <NavLink
                        to={PAGES.FORGOT_PIN}
                        className="text-sm mt-2 text-primary hover:underline"
                    >
                        {t("Code pin oublié?")}
                    </NavLink>
                </div>
            </div>

            <Button
                type="submit"
                data-cy="submit"
                loading={props.loading}
                className="justify-start px-7"
                textContainerClassName="flex items-center justify-between w-full"
            >
                <span>{t("Connexion")}</span>
                <ChevronIcon className="w-3 h-3 -rotate-90" />
            </Button>

            {showPhoneInput && (
                <>
                    <p className="text-sm text-center lg:hidden">
                        {t("Vous n'avez pas de compte")}{" "}
                        <NavLink
                            to={PAGES.REGISTER}
                            className="text-primary hover:underline"
                        >
                            {t("Inscription")}
                        </NavLink>
                    </p>

                    <div className="hidden lg:flex items-center justify-between text-sm font-medium pt-36 pb-5">
                        <div>
                            <p>
                                {t("Nouveau sur :name", { name: config.name })}
                            </p>

                            <NavLink
                                to={PAGES.REGISTER}
                                className="text-primary hover:underline"
                            >
                                {t("Créez votre compte")}
                            </NavLink>
                        </div>

                        <div className="text-right">
                            <p>{t("Voulez vous essayer")}</p>

                            <NavLink
                                to={PAGES.HOME}
                                className="text-primary hover:underline"
                            >
                                {t("1er transfert sans connexion")}
                            </NavLink>
                        </div>
                    </div>
                </>
            )}
        </form>
    );
};

export default LoginForm;
