import { RadioGroup } from "@headlessui/react";
import Button from "@ui/Button.tsx";
import { Modal } from "@ui/Modal.tsx";
import { Fragment, ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { downloadFile } from "../../helpers";
import { ModalPropsType } from "../../types";
import BiSolidDownload from "../icons/BiSolidDownload.tsx";
import BsWhatsapp from "../icons/BsWhatsapp.tsx";
import CheckSolidCircleIcon from "../icons/CheckSolidCircleIcon.tsx";
import FaFacebook from "../icons/FaFacebook.tsx";
import FaMessenger from "../icons/FaMessenger.tsx";
import FaTwitter from "../icons/FaTwitter.tsx";
import FiMailIcon from "../icons/FiMailIcon.tsx";
import HeChatIcon from "../icons/HeChatIcon.tsx";

interface Props extends ModalPropsType {
    title: string;
    description: string;
    share: {
        link: string;
        message: string;
    };
    showDownloadReceipt?: boolean;
}

interface SocialItemProps {
    value: string;
    text: string;
    icon: ReactNode;
    index: number;
}

const SocialItem = (props: SocialItemProps) => {
    const { value, icon, text, index } = props;

    return (
        <div>
            <RadioGroup.Option
                data-testid={`social-item-${index}`}
                value={value}
                className="flex cursor-pointer items-center justify-between"
            >
                {({ checked }) => (
                    <>
                        <div className="flex items-center space-x-4">
                            {icon}
                            <p>{text}</p>
                        </div>

                        {checked ? (
                            <CheckSolidCircleIcon className="text-gray-600 h-6 w-6" />
                        ) : (
                            <span className="h-6 w-6 rounded-full border" />
                        )}
                    </>
                )}
            </RadioGroup.Option>

            <hr className="my-2" />
        </div>
    );
};

const ShareWithSocials = (props: Props) => {
    const { t } = useTranslation();
    const { isOpen, closeModal } = props;

    const [selected, setSelected] = useState("");

    const linkUrl = useMemo(() => {
        switch (selected) {
            case "gmail":
                return `mailto:?subject=Invitation&body=${props.share.message}%20${props.share.link}`;
            case "whatsapp":
                return `https://api.whatsapp.com/send?text=${props.share.message}%20${props.share.link}`;
            case "messenger":
                return `fb-messenger://share/?link=${props.share.link}`;
            case "facebook":
                return `https://www.facebook.com/sharer/sharer.php?u=${props.share.link}&quote=${props.share.message}`;
            case "twitter":
                return `https://twitter.com/intent/tweet?text=${props.share.message}%20${props.share.link}`;
            case "message":
                return `sms:?body=${props.share.message}%20${props.share.link}`;
            default:
                return "#button";
        }
    }, [props.share.link, props.share.message, selected]);

    const SOCIALS = useMemo(
        () => [
            {
                value: "download",
                text: t("Télécharger"),
                icon: <BiSolidDownload size="xl" className="text-primary" />
            },
            {
                value: "messenger",
                text: "Messenger",
                icon: (
                    <FaMessenger
                        size="xl"
                        className="text-white fill-[#0099FF]"
                    />
                )
            },
            {
                value: "whatsapp",
                text: "Whatsapp",
                icon: (
                    <BsWhatsapp
                        size="xl"
                        className="text-white fill-green-500"
                    />
                )
            },
            {
                value: "facebook",
                text: "Facebook",
                icon: (
                    <FaFacebook
                        size="xl"
                        className="text-white fill-[#4267B2]"
                    />
                )
            },
            {
                value: "twitter",
                text: "Twitter",
                icon: <FaTwitter size="xl" />
            },
            {
                value: "gmail",
                text: "Mail",
                icon: <FiMailIcon size="xl" className="text-primary" />
            },
            {
                value: "message",
                text: "Message",
                icon: <HeChatIcon size="xl" className="text-gray-600" />
            }
        ],
        [t]
    );

    return (
        <Modal isOpen={isOpen} closeModal={closeModal} className="rounded-md">
            <h5 className="text-center text-gray-700 text-lg font-medium mb-3">
                {props.title}
            </h5>
            <p className="text-sm text-center text-gray-600 mb-8">
                {props.description}
            </p>

            <RadioGroup
                value={selected}
                onChange={setSelected}
                className="mb-7 h-[239px] overflow-y-auto"
            >
                <RadioGroup.Label className="sr-only">
                    {t("Social")}
                </RadioGroup.Label>

                {SOCIALS.map((item, index) => (
                    <Fragment key={index}>
                        {item.value === "download" ? (
                            <>
                                {props.showDownloadReceipt && (
                                    <SocialItem
                                        index={index}
                                        value={item.value}
                                        text={item.text}
                                        icon={item.icon}
                                    />
                                )}
                            </>
                        ) : (
                            <SocialItem
                                index={index}
                                value={item.value}
                                text={item.text}
                                icon={item.icon}
                            />
                        )}
                    </Fragment>
                ))}
            </RadioGroup>

            <a
                href={linkUrl}
                onClick={e => {
                    if (selected === "download" && props.share.link) {
                        e.preventDefault();
                        downloadFile(props.share.link, true);
                    }
                }}
                target={selected.length > 0 ? "_blank" : "_self"}
                rel="noreferrer"
            >
                <Button>{t("Ouvrir")}</Button>
            </a>
        </Modal>
    );
};

export default ShareWithSocials;
