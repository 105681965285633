import { useTranslation } from "react-i18next";

import useModal from "../hooks/useModal.ts";

import CGUModal from "./modals/CGUModal.tsx";
import { SettingWizardButton } from "./SettingWizard.tsx";

const CGUButton = () => {
    const { t } = useTranslation();
    const { isOpen, openModal, closeModal } = useModal();

    return (
        <>
            <SettingWizardButton
                title={t("Conditions générales d’utilisation")}
                onClick={openModal}
                index={4}
            >
                {t(
                    "Lire les conditions générales d’utilisation de l’application"
                )}
            </SettingWizardButton>

            <CGUModal isOpen={isOpen} closeModal={closeModal} />
        </>
    );
};

export default CGUButton;
