import { useEffect } from "react";

const useGetCameraPermission = () => {
    useEffect(() => {
        const perm = navigator.permissions;
        if (perm && perm.query) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            perm.query({ name: "camera" })
                .then(permissionsStatus => {
                    if (permissionsStatus.state !== "granted") {
                        if (
                            navigator.mediaDevices &&
                            navigator.mediaDevices.getUserMedia
                        ) {
                            navigator.mediaDevices
                                .getUserMedia({ video: true })
                                .then()
                                .catch(error => {
                                    /* eslint-disable */
                                    console.error(
                                        "Erreur lors de l'obtention de l'accès à la camera:",
                                        error
                                    );
                                    /* eslint-enable */
                                });
                        }
                    }
                })
                .catch(error => {
                    /* eslint-disable */
                    console.log(
                        "Erreur lors de la vérification de la permissions pour utiliser la caméra: ",
                        error
                    );
                    /* eslint-enable */
                });
        }
    }, []);
};

export default useGetCameraPermission;
