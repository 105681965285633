import env from "../helpers/env";
import { SessionType } from "../types";

export interface ClientConfig {
    name: string;
    env: string;
    url: string;
    locale: "fr" | "en";
    fakerLocale: "fr" | "en";
    sessionDriver: SessionType;
    auth: {
        redirectLoginUrl: string;
        redirectLogoutUrl: string;
        apiHost: string;
        transferServiceApiHost: string;
        apiBasePath: string;
        sessionExpireTime: string;
    };
    toastTimeout: number;
    walletFilesPath: string;
    customerServiceNumber: string;
    localisationApi: string;
}

const config: ClientConfig = {
    /*
    |--------------------------------------------------------------------------
    | Application name
    |--------------------------------------------------------------------------
    |
    | This value is the name of your applications. This value is used when the
    |
    */
    name: env("APP_NAME", "React"),

    /*
    |--------------------------------------------------------------------------
    | Application Environment
    |--------------------------------------------------------------------------
    |
    | This value determines the "environment" your application is currently
    | running in. This may determine how you prefer to configure various
    | services the application utilizes. Set this in ".env" file.
    |
    */
    env: env("APP_ENV", "React"),

    /*
    |--------------------------------------------------------------------------
    | Application URL
    |--------------------------------------------------------------------------
    |
    | This value is the url of your application. This value can be used on
    | interfaces and in functions
    |
    */
    url: env("APP_URL", "React"),

    /*
    |--------------------------------------------------------------------------
    | Application Local Configuration
    |--------------------------------------------------------------------------
    |
    | The application lang determines the default lang that will be used
    | by the translation service provider. You are free to set this value
    | to any of the lang which will be supported by the application.
    |
    */
    locale: env("LOCAL", "fr"),

    /*
    |--------------------------------------------------------------------------
    | Faker Locale
    |--------------------------------------------------------------------------
    |
    | This local will be used by the Faker JS library when generating fake
    | data for your application. For example, this will be used to get
    | localized telephone numbers, street address information and more.
    |
    */
    fakerLocale: env("LOCAL_FAKER", "fr"),

    /*
    |--------------------------------------------------------------------------
    | Default Session Driver
    |--------------------------------------------------------------------------
    |
    | This value is used to indicate how the data can be persisted in session.
    |
    */
    sessionDriver: "localStorage",

    /*
    |--------------------------------------------------------------------------
    | Authentication process data
    |--------------------------------------------------------------------------
    |
    | This value is used to indicate the data used in the authentication
    | process.
    |
    */
    auth: {
        redirectLoginUrl: env("AUTH_REDIRECT_LOGIN_URL", "/"),
        redirectLogoutUrl: env("AUTH_REDIRECT_LOGOUT_URL", "/logout"),
        apiHost: env("AUTH_API_HOST", "http://localhost:8000"),
        transferServiceApiHost: env(
            "AUTH_TRANSFER_SERVICE_API_HOST",
            "http://localhost:8000"
        ),
        apiBasePath: env("AUTH_API_BASE_PATH", "/api"),
        sessionExpireTime: env("AUTH_SESSION_EXPIRE_TIME", 15)
    },

    /*
    |--------------------------------------------------------------------------
    | TOAST TIMEOUT
    |--------------------------------------------------------------------------
    |
    | This value is used to indicate the duration of a toast
    |
    */
    toastTimeout: env("TOAST_TIMEOUT", 3000),

    /*
    |--------------------------------------------------------------------------
    | Wallet File Path
    |--------------------------------------------------------------------------
    |
    | This value is used to indicate the wallet path
    |
    */
    walletFilesPath: "/images/wallet",

    /*
    |--------------------------------------------------------------------------
    | Customer Service Number
    |--------------------------------------------------------------------------
    |
    | This value is used to indicate the customer service client number
    |
    */
    customerServiceNumber: env("CUSTOMER_SERVICE_NUMBER", "+221779800000"),

    /*
    |--------------------------------------------------------------------------
    | Customer Service Number
    |--------------------------------------------------------------------------
    |
    | This value is used to indicate the localisation api
    |
    */
    localisationApi: "https://api.bigdatacloud.net/data/reverse-geocode-client"
};

export default config;
