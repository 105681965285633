import Button from "@ui/Button.tsx";
import { Modal } from "@ui/Modal.tsx";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ENDPOINTS from "../../constants/endpoint.ts";
import PAGES from "../../constants/pages.ts";
import { cn, loadTransferServiceHost } from "../../helpers";
import useManageWalletChannels from "../../hooks/useManageWalletChannels.ts";
import useToast from "../../hooks/useToast.ts";
import { RootState } from "../../store";
import {
    setRetryTransfer,
    setTransferFees,
    updateTransferFormData
} from "../../store/transferSlice.ts";
import { ModalPropsType, StatusType, TransferType } from "../../types";
import Loading from "../Loading.tsx";
import MatchStatusIcon from "../MatchStatusIcon.tsx";
import TransferDetail from "../TransferDetail.tsx";

import ShareWithSocials from "./ShareWithSocials.tsx";

interface DetailTransferModalProps extends ModalPropsType {
    title?: string;
    showRegisterButton?: boolean;
    transfer: TransferType | null;
}

const DetailTransferModal = (props: DetailTransferModalProps) => {
    const { t } = useTranslation();
    const [showShare, setShowShare] = useState(false);
    const { isOpen, closeModal, title, transfer } = props;
    const [loading, setLoading] = useState(false);

    const manageWalletChannel = useManageWalletChannels();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { successToast } = useToast();

    const { client } = useSelector((state: RootState) => state.config);

    const chargeTransferForm = useCallback(() => {
        if (transfer) {
            const receiverPhone = transfer.receiver_phone.split(" ");
            const senderPhone = transfer.sender_phone.split(" ");

            const amount =
                typeof transfer.amount === "string"
                    ? parseInt(transfer.amount)
                    : transfer.amount;
            const fees =
                typeof transfer.fees === "string"
                    ? parseInt(transfer.fees)
                    : transfer.fees;

            dispatch(
                updateTransferFormData({
                    senderAmount: amount.toString(),
                    senderWallet: manageWalletChannel.getChannelById(
                        true,
                        transfer.pay_in_channel_id
                    ),
                    senderPhone: {
                        phoneNumber: senderPhone[1],
                        indicative: senderPhone[0]
                    },
                    recipientAmount: (amount + fees).toString(),
                    recipientWallet: manageWalletChannel.getChannelById(
                        false,
                        transfer.pay_out_channel_id
                    ),
                    recipientPhone: {
                        phoneNumber: receiverPhone[1],
                        indicative: receiverPhone[0]
                    },
                    recipientName: transfer.receiver_name || undefined,
                    supportFees: !!transfer.support_fees,
                    card: null,
                    cvv: "",
                    expirationDate: ""
                })
            );
            dispatch(setRetryTransfer(transfer));
            dispatch(setTransferFees(fees.toString()));
        }
    }, [dispatch, manageWalletChannel, transfer]);

    const retryTransfer = useCallback(() => {
        chargeTransferForm();
        closeModal();
        navigate(PAGES.TRANSFER);
    }, [chargeTransferForm, closeModal, navigate]);

    const handleWindUpClaim = useCallback(() => {
        if (transfer) {
            loadTransferServiceHost();
            setLoading(true);
            window.axios
                .post(
                    `${ENDPOINTS.REWIND_TRANSFER.replace(
                        ":id",
                        transfer.id.toString()
                    )}`
                )
                .then(() => {
                    successToast(
                        t("Votre requête a été bien reçue"),
                        "",
                        15000
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [successToast, t, transfer]);

    const canRetry = useMemo(() => {
        return (
            transfer?.status_pay_in === StatusType.success &&
            transfer?.status_pay_out === StatusType.failed
        );
    }, [transfer?.status_pay_in, transfer?.status_pay_out]);

    const transferReceipt = useMemo(() => {
        if (transfer?.french_receipt_url && client.locale === "fr")
            return transfer?.french_receipt_url;

        if (transfer?.english_receipt_url && client.locale === "en")
            return transfer?.english_receipt_url;

        if (transfer?.french_receipt_url) return transfer?.french_receipt_url;

        if (transfer?.english_receipt_url) return transfer?.english_receipt_url;

        return "";
    }, [
        client.locale,
        transfer?.english_receipt_url,
        transfer?.french_receipt_url
    ]);

    return (
        <>
            {transfer && (
                <>
                    {!showShare && (
                        <Modal
                            isOpen={isOpen}
                            closeModal={closeModal}
                            className="rounded-md"
                        >
                            <h5 className="text-center text-gray-700 text-lg font-medium mb-3">
                                {title ? (
                                    title
                                ) : (
                                    <>
                                        {t("Transaction")}{" "}
                                        {transfer?.status ===
                                            StatusType.success &&
                                            t("succès")}{" "}
                                        {transfer?.status ===
                                            StatusType.pending &&
                                            t("en cours")}{" "}
                                        {transfer?.status ===
                                            StatusType.failed && t("échouée")}
                                    </>
                                )}
                            </h5>

                            <MatchStatusIcon
                                status={transfer?.status}
                                className="mx-auto my-2"
                            />

                            <div className="text-sm text-gray-700">
                                <TransferDetail
                                    service={t("Transfer")}
                                    transfer={transfer}
                                />

                                <div
                                    className={cn({
                                        "flex items-center space-x-4":
                                            (transfer?.status ===
                                                StatusType.failed ||
                                                transfer?.status ===
                                                    StatusType.success) &&
                                            (transfer?.french_receipt_url ||
                                                transfer?.english_receipt_url)
                                    })}
                                >
                                    {(transfer?.status === StatusType.failed ||
                                        transfer?.status ===
                                            StatusType.success) &&
                                        (transfer?.french_receipt_url ||
                                            transfer?.english_receipt_url) && (
                                            <>
                                                <button
                                                    onClick={() =>
                                                        setShowShare(true)
                                                    }
                                                    className="w-full space-x-3 text-gray-600 font-medium text-xs border p-3 rounded-md"
                                                >
                                                    <span>{t("Partager")}</span>
                                                </button>
                                            </>
                                        )}

                                    {canRetry &&
                                        transfer?.number_retry_authorizations >
                                            0 && (
                                            <>
                                                <button
                                                    className="w-full text-primary space-x-3 font-medium text-xs border p-3 rounded-md"
                                                    onClick={retryTransfer}
                                                >
                                                    {t("Réessayer")}
                                                </button>
                                            </>
                                        )}

                                    <button
                                        type="button"
                                        className="w-full flex items-center justify-center space-x-2 text-red-600 font-medium text-xs border p-3 rounded-md"
                                        onClick={handleWindUpClaim}
                                        disabled={loading}
                                    >
                                        {loading && (
                                            <Loading className="text-red-500 h-4 w-4" />
                                        )}

                                        <span>{t("Réclamer")}</span>
                                    </button>
                                </div>

                                {transfer?.status === StatusType.success &&
                                    props.showRegisterButton && (
                                        <Button
                                            className="mt-6"
                                            type="button"
                                            onClick={() => {
                                                navigate(PAGES.REGISTER);
                                            }}
                                        >
                                            {t("Créer son compte")}
                                        </Button>
                                    )}
                            </div>
                        </Modal>
                    )}

                    {showShare && (
                        <ShareWithSocials
                            title={t("Partager le reçu avec")}
                            description={t(
                                "Sélectionner une application puis partager le le reçu."
                            )}
                            share={{
                                link: transferReceipt || "",
                                message: encodeURI(
                                    t(
                                        "Télécharger le reçu du transfert à partir du lien"
                                    )
                                )
                            }}
                            showDownloadReceipt={transferReceipt?.length > 0}
                            isOpen={isOpen}
                            closeModal={() => {
                                setShowShare(false);
                            }}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default DetailTransferModal;
