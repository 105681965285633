import { ImgHTMLAttributes } from "react";

import { cn } from "../helpers";

type Props = ImgHTMLAttributes<HTMLImageElement>;

const MyDunyaLogo = (props: Props) => {
    const { src = "/icon-512x512.png", className, alt, ...rest } = props;
    return (
        <img
            className={cn(
                "object-cover object-center bg-white rounded-2xl w-16 mb-10",
                className
            )}
            src={src}
            {...rest}
            alt={alt}
        />
    );
};

export default MyDunyaLogo;
