import { useLayoutEffect } from "react";

import env from "../helpers/env.ts";

const usePageTitle = (title: string) => {
    useLayoutEffect(() => {
        window.document.title = `${env("APP_NAME", "Mydunya")} - ${title}`;
    }, [title]);
};

export default usePageTitle;
