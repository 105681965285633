import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ANDROID_URL, IOS_URL } from "../constants";
import { getPlatform } from "../helpers";
import { PlatformEnum } from "../types";

const Share = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const link = document.createElement("a");
        if (getPlatform() === PlatformEnum.iPhone) {
            link.href = IOS_URL;
            link.click();
        } else if (getPlatform() === PlatformEnum.android) {
            link.href = ANDROID_URL;
            link.click();
        } else {
            navigate("/");
        }
    }, [navigate]);

    return null;
};

export default Share;
