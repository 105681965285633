import { createContext } from "react";

export interface PinInputStore {
    type?: "tel" | "text" | "password" | "number";
    placeholder?: string;
    pin: string[];
    length: number;
    focusInputByIndex: (index: number) => void;
    onChangeIndex: (index: number, value: string) => void;
}

const PinInputContext = createContext<PinInputStore | null>(null);

export default PinInputContext;
