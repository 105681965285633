import { useLayoutEffect } from "react";

import { COLOR } from "../types";

const useThemeColor = (color: COLOR) => {
    useLayoutEffect(() => {
        const meta = document.querySelector("meta[name='theme-color']");
        if (meta) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            meta.content = color;
        }
    }, [color]);
};

export default useThemeColor;
