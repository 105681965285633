import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { NotificationType } from "../components/notifications";

const notifications: NotificationType[] = [];

interface NotificationStoreType {
    list: NotificationType[];
}

const initialState: NotificationStoreType = {
    list: notifications
};

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        storeNotifications: (
            state,
            action: PayloadAction<NotificationType[]>
        ) => {
            state.list = action.payload;
        },
        addNotification: (state, action: PayloadAction<NotificationType>) => {
            state.list = [action.payload, ...state.list];
        },
        readNotification: (state, action: PayloadAction<string>) => {
            state.list = state.list.map(item => ({
                ...item,
                read_at:
                    item.id === action.payload
                        ? window.dayjs().format("lll")
                        : item.read_at
            }));
        },
        resetNotificationStore: state => {
            state.list = [];
        }
    }
});

export const {
    readNotification,
    addNotification,
    storeNotifications,
    resetNotificationStore
} = notificationSlice.actions;

const notificationReducer = notificationSlice.reducer;

export default notificationReducer;
