import React, { useContext, useEffect, useMemo, useState } from "react";

import WizardContext, { WizardContextStore } from "../context/WizardContext.ts";
import { cn } from "../helpers";

import SettingButton from "./SettingButton.tsx";

interface Props {
    children?: React.ReactNode;
}

interface SettingWizardButtonProps extends Props {
    title: string;
    disabled?: boolean;
    index: number;
    onClick?: () => void;
    loading?: boolean;
}

interface SettingWizardItemProps extends Props {
    index: number;
}

const SettingWizardItem = (props: SettingWizardItemProps) => {
    const value = useContext(WizardContext) as WizardContextStore;

    return <>{props.index === value.index && props.children}</>;
};

const SettingWizardButton = (props: SettingWizardButtonProps) => {
    const { changeIndex, index } = useContext(
        WizardContext
    ) as WizardContextStore;

    const handleClickButton = () => {
        changeIndex(props.index);
        props.onClick && props.onClick();
    };

    return (
        <SettingButton
            onClick={handleClickButton}
            disabled={props.disabled}
            loading={props.loading}
            title={props.title}
            className={cn({
                "border-primary bg-gray-100": props.index === index,
                "cursor-not-allowed": props.disabled
            })}
        >
            {props.children}
        </SettingButton>
    );
};

interface SettingWizardProps extends Props {
    defaultIndex?: number;
}

const SettingWizard = (props: SettingWizardProps) => {
    const [index, setIndex] = useState(props.defaultIndex || 0);

    useEffect(() => {
        props.defaultIndex && setIndex(props.defaultIndex);
    }, [props.defaultIndex]);

    const valueContext = useMemo(() => {
        return {
            index,
            changeIndex: (index: number) => setIndex(index)
        };
    }, [index]);

    return (
        <WizardContext.Provider value={valueContext}>
            {props.children}
        </WizardContext.Provider>
    );
};

export { SettingWizard, SettingWizardButton, SettingWizardItem };
