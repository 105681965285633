import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

interface DropdownItems {
    children?: React.ReactNode;
    className?: string;
}

const Dropdown = Menu;
const DropdownButton = Menu.Button;
const DropdownItem = Menu.Item;

const DropdownItems = (props: DropdownItems) => {
    return (
        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items className={props.className}>
                {props.children}
            </Menu.Items>
        </Transition>
    );
};

export { Dropdown, DropdownButton, DropdownItems, DropdownItem };
