import { Dayjs } from "dayjs";
import { useLayoutEffect, useRef } from "react";

type DateType = string | Date | Dayjs;

interface Props {
    date: DateType;
    formatFunc?: (value: DateType) => string;
}

const INTERVAL_TIME = 60 * 1000;

const DynamicFromNow = (props: Props) => {
    const { formatFunc, date } = props;
    const ref = useRef<HTMLSpanElement>(null);

    useLayoutEffect(() => {
        const interval = setInterval(() => {
            if (ref.current) {
                ref.current.innerHTML = formatFunc
                    ? formatFunc(date)
                    : date.toString();
            }
        }, INTERVAL_TIME);

        return () => {
            clearInterval(interval);
        };
    }, [date, formatFunc]);

    return (
        <span ref={ref}>{formatFunc ? formatFunc(date) : date.toString()}</span>
    );
};

export default DynamicFromNow;
