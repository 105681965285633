import React, { InputHTMLAttributes } from "react";

import { cn } from "../../helpers";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    children?: React.ReactNode;
    className?: string;
    checkboxClassName?: string;
    id?: string;
}

const CheckboxInput = (props: Props) => {
    const { children, className, checkboxClassName, id, ...rest } = props;
    return (
        <label
            className={cn(
                "cursor-pointer flex items-center space-x-4",
                className
            )}
            htmlFor={id}
            role="label"
        >
            <input
                id={id}
                className={cn({
                    "rounded border-gray-300 text-primary shadow-sm focus:border-primary focus:ring focus:ring-offset-0 focus:ring-blue-200 focus:ring-opacity-50":
                        true,
                    "bg-gray-100": rest.disabled,
                    [checkboxClassName || ""]: true
                })}
                type="checkbox"
                {...rest}
            />
            <p className="text-xs text-gray-700 select-none">{children}</p>
        </label>
    );
};

export default CheckboxInput;
