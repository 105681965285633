import React from "react";
import { NavLink } from "react-router-dom";

interface Props {
    children: React.ReactNode;
    to: string;
}

const SimpleLink = React.forwardRef<HTMLAnchorElement, Props>((props, ref) => {
    return (
        <NavLink
            ref={ref}
            to={props.to}
            className="text-primary hover:underline"
        >
            {props.children}
        </NavLink>
    );
});

SimpleLink.displayName = "Link";

export default SimpleLink;
