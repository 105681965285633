import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../store";

const useNotification = () => {
    const [toggle, setToggle] = useState(false);

    const { list: allNotifications } = useSelector(
        (state: RootState) => state.notification
    );

    const unreadNotification = useMemo(() => {
        return allNotifications.filter(item => !item.read_at);
    }, [allNotifications]);

    const notifications = useMemo(() => {
        if (toggle) return unreadNotification;
        return allNotifications;
    }, [allNotifications, toggle, unreadNotification]);

    return {
        notifications,
        unreadNotification,
        toggle,
        setToggle
    };
};

export default useNotification;
