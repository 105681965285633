import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { RESPONSE_STATUS } from "../constants";
import ENDPOINTS from "../constants/endpoint.ts";
import { loadAuthServiceHost } from "../helpers";
import { updateAuthUser } from "../store/authSlice.ts";
import { resetSenderData } from "../store/transferSlice.ts";
import { CardType } from "../types";

import useToast from "./useToast.ts";

const KYC_NOT_VALIDATED = "kyc_not_validated";
const LIMIT_CARD = "limit_transfer_cards";
const CARD_ALREADY_REGISTERED_YOUR_ACCOUNT =
    "card_has_already_registered_for_your_account";
const CARD_ALREADY_REGISTERED_YOUR_OTHER_ACCOUNT =
    "card_has_already_registered_for_another_account";
const CARD_HAS_ALREADY_BLOCKED =
    "card_has_already_registered_and_blocked_and_deleted";
const CARDS_LIMIT_TRANSFER = "limit_transfer_cards";

const useManageCard = () => {
    const [loading, setLoading] = useState(false);
    const [selectCard, setSelectCard] = useState<CardType | null>(null);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [cardNumber, setCardNumber] = useState("");
    const [cardName, setCardName] = useState("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [kycNotValidated, setKycNotValidated] = useState(false);
    const [limitCards, setLimitCards] = useState(false);
    const [showAddForm, setShowAddForm] = useState(false);
    const [limitCardsPerMonth, setLimitCardsPerMonth] = useState(false);
    const [cardAlreadyRegisteredMyAccount, setCardAlreadyRegisteredMyAccount] =
        useState(false);
    const [
        cardAlreadyRegisteredOtherAccount,
        setCardAlreadyRegisteredOtherAccount
    ] = useState(false);
    const [cardAlreadyBlocked, setCardAlreadyBlocked] = useState(false);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { successToast, errorToast } = useToast();

    const refreshUserData = useCallback(
        (value = { add: false, remove: false }) => {
            loadAuthServiceHost();
            window.axios
                .get(ENDPOINTS.ME)
                .then(response => {
                    if (value.remove) {
                        setRemoveLoading(false);
                        setSelectCard(null);
                        successToast(t("Carte supprimée avec succès"));
                    }

                    if (value.add) {
                        setLoading(false);
                        setCardNumber("");
                        setCardName("");
                        setShowAddForm(false);
                        successToast(t("Carte ajoutée avec succès"));
                    }

                    dispatch(updateAuthUser({ user: response.data.data }));
                    dispatch(resetSenderData());
                })
                .finally(() => {
                    if (value.add) {
                        setLoading(false);
                    }

                    if (value.remove) {
                        setRemoveLoading(false);
                    }
                });
        },
        [dispatch, successToast, t]
    );

    const handleAdd = useCallback(
        (afterSuccess?: () => void) => {
            loadAuthServiceHost();
            setLoading(true);
            window.axios
                .post(ENDPOINTS.ADD_CARD, {
                    number: cardNumber.replaceAll(" ", ""),
                    name: cardName
                })
                .then(() => {
                    refreshUserData({ add: true, remove: false });
                    Object.keys(errors).length && setErrors({});
                    kycNotValidated && setKycNotValidated(false);
                    limitCards && setLimitCards(false);
                    limitCardsPerMonth && setLimitCardsPerMonth(false);
                    setSelectCard(null);
                    afterSuccess && afterSuccess();
                })
                .catch(error => {
                    errorToast("Echec de l'enregistrement de la carte");

                    Object.keys(errors).length && setErrors({});
                    kycNotValidated && setKycNotValidated(false);
                    limitCards && setLimitCards(false);
                    const status = error.response?.status;
                    const key = error.response.data.data?.key;

                    setLoading(false);

                    if (
                        error.response.status ===
                        RESPONSE_STATUS.UNPROCESSABLE_ENTITY
                    ) {
                        setErrors(error.response.data.errors);
                    }

                    if (status === RESPONSE_STATUS.CONFLICT) {
                        if (key === KYC_NOT_VALIDATED) {
                            setKycNotValidated(true);
                        }

                        if (key === LIMIT_CARD) {
                            setLimitCards(true);
                        }

                        if (key === CARD_ALREADY_REGISTERED_YOUR_ACCOUNT) {
                            setCardAlreadyRegisteredMyAccount(true);
                        }

                        if (
                            key === CARD_ALREADY_REGISTERED_YOUR_OTHER_ACCOUNT
                        ) {
                            setCardAlreadyRegisteredOtherAccount(true);
                        }

                        if (key === CARD_HAS_ALREADY_BLOCKED) {
                            setCardAlreadyBlocked(true);
                        }

                        if (key === CARDS_LIMIT_TRANSFER) {
                            setLimitCardsPerMonth(true);
                        }
                    }
                });
        },
        [
            cardName,
            cardNumber,
            errorToast,
            errors,
            kycNotValidated,
            limitCards,
            limitCardsPerMonth,
            refreshUserData
        ]
    );

    const handleRemove = useCallback(() => {
        loadAuthServiceHost();
        setRemoveLoading(true);
        window.axios
            .delete(ENDPOINTS.REMOVE_CARD.replace(":id", selectCard?.id || ""))
            .then(() => {
                refreshUserData({ add: false, remove: true });
            })
            .catch(() => setRemoveLoading(false));
    }, [refreshUserData, selectCard?.id]);

    return {
        handleAdd,
        handleRemove,
        selectCard,
        setSelectCard,
        showAddForm,
        cardNumber,
        setCardNumber,
        cardName,
        setCardName,
        adding: loading,
        removing: removeLoading,
        setShowAddForm,
        errors,
        cardAlreadyRegisteredMyAccount,
        cardAlreadyRegisteredOtherAccount,
        cardAlreadyBlocked,
        limitCardsPerMonth
    };
};

export default useManageCard;
