import RadioInput from "@ui/RadioInput.tsx";
import { TabButton } from "@ui/tab";
import { useTranslation } from "react-i18next";

import { cn } from "../../helpers";

interface Props {
    choice: string;
    setChoice: (value: string) => void;
    className?: string;
}

const SelectCardOrNumber = (props: Props) => {
    const { choice, setChoice, className } = props;

    const { t } = useTranslation();

    return (
        <div className={cn("flex space-x-6 mb-4", className)}>
            <TabButton index={"1"}>
                <RadioInput
                    value={"1"}
                    checked={choice === "1"}
                    onChange={e => setChoice(e.target.value)}
                >
                    Mobile money
                </RadioInput>
            </TabButton>

            <TabButton index={"2"}>
                <RadioInput
                    value={"2"}
                    checked={choice === "2"}
                    onChange={e => setChoice(e.target.value)}
                >
                    {t("Bank card")}
                </RadioInput>
            </TabButton>
        </div>
    );
};

export default SelectCardOrNumber;
