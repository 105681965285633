import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import PAGES from "../../constants/pages.ts";
import { cn } from "../../helpers";
import useAuth from "../../hooks/useAuth.ts";
import useThemeColor from "../../hooks/useThemeColor.ts";
import { RootState } from "../../store";
import { COLOR, KycStatusType } from "../../types";
import InfoMessage from "../InfoMessage.tsx";

import Header from "./Header.tsx";
import Nav from "./Nav.tsx";

interface Props {
    children: ReactNode;
    info?: string;
}

const ToReviewBanner = () => {
    const { t } = useTranslation();

    return (
        <InfoMessage className="text-[#E59027] bg-[#e590271a]">
            {t(
                "Certaines de vos informations de profil n'ont pas été acceptées"
            )}
            ,{" "}
            <NavLink to={PAGES.CORRECT_KYC} className="text-red-500 underline">
                {t("cliquez ici pour mettre à jour")}
            </NavLink>
        </InfoMessage>
    );
};

const Main = (props: Props) => {
    const { t } = useTranslation();
    useThemeColor(COLOR.PRIMARY);
    const { kycActionData, isLogin, user } = useAuth();
    const {
        showTransferKycValidateMessage,
        showTransferCardKycValidateMessage
    } = useSelector((state: RootState) => state.ui);

    return (
        <>
            <section role="topbar" className="fixed z-10 right-0 left-0">
                <Nav />

                <Header />
            </section>

            <main className="pt-[104px] lg:px-12 lg:pb-12">
                {props.info &&
                    (!kycActionData.oneActionIsVerify ||
                        kycActionData.validate) && (
                        <>
                            {kycActionData.validate ? (
                                <>
                                    {showTransferCardKycValidateMessage && (
                                        <InfoMessage className="text-[#077A31] bg-[#22c55e1a]">
                                            {t(
                                                "Votre compte a été validé ! Vous pouvez transférer de l'argent avec votre carte bancaire ou votre mobile money"
                                            )}
                                        </InfoMessage>
                                    )}
                                </>
                            ) : (
                                <>
                                    {kycActionData.transferValidate && (
                                        <>
                                            {showTransferKycValidateMessage &&
                                                user?.account
                                                    ?.card_kyc_status ===
                                                    KycStatusType.to_validate && (
                                                    <InfoMessage className="text-[#077A31] bg-[#22c55e1a]">
                                                        {t(
                                                            "Votre compte a été validé. Vous pouvez effectuer uniquement les transferts entre mobile money."
                                                        )}
                                                    </InfoMessage>
                                                )}

                                            {kycActionData.cardReview && (
                                                <ToReviewBanner />
                                            )}
                                        </>
                                    )}

                                    <>
                                        {kycActionData.userHaveSelfie ? (
                                            <>
                                                {!kycActionData.transferValidate && (
                                                    <InfoMessage
                                                        className={cn({
                                                            "bg-[#e736361a] text-[#E73636]":
                                                                user.user_limit_transactionnel
                                                        })}
                                                    >
                                                        {!user?.user_limit_transactionnel
                                                            ? props.info
                                                            : t(
                                                                  "Vous avez atteint la limite de transfert autorisée, vous ne pouvez plus faire de transfert"
                                                              )}
                                                    </InfoMessage>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {isLogin ? (
                                                    <>
                                                        {kycActionData.transferSubmitReview && (
                                                            <InfoMessage
                                                                className={cn({
                                                                    "text-[#E59027] bg-[#e590271a]":
                                                                        !user.user_limit_transactionnel,
                                                                    "bg-[#e736361a] text-[#E73636]":
                                                                        user.user_limit_transactionnel
                                                                })}
                                                            >
                                                                {!user.user_limit_transactionnel
                                                                    ? t(
                                                                          "Vos informations ont été reçues. Votre compte est en cours de validation, nous vous ferons un retour."
                                                                      )
                                                                    : t(
                                                                          "Vos informations ont été reçues ! Vous avez atteint la limite de transfert autorisée. Votre compte doit être validé avant de pouvoir continuer."
                                                                      )}
                                                            </InfoMessage>
                                                        )}

                                                        {!kycActionData.transferSubmitReview &&
                                                            !kycActionData.cardSubmitReview && (
                                                                <InfoMessage
                                                                    persist={
                                                                        true
                                                                    }
                                                                    showCloseIcon={
                                                                        true
                                                                    }
                                                                >
                                                                    {t(
                                                                        "Nous vous invitons à"
                                                                    )}{" "}
                                                                    <NavLink
                                                                        to={
                                                                            PAGES.CORRECT_KYC
                                                                        }
                                                                        className="text-red-500 hover:underline"
                                                                    >
                                                                        {t(
                                                                            "compléter vos informations de profil"
                                                                        )}{" "}
                                                                    </NavLink>{" "}
                                                                    {t(
                                                                        "pour profiter de toutes les options de MyDunya"
                                                                    )}
                                                                </InfoMessage>
                                                            )}
                                                    </>
                                                ) : (
                                                    <InfoMessage>
                                                        {props.info}
                                                    </InfoMessage>
                                                )}
                                            </>
                                        )}
                                    </>
                                </>
                            )}
                        </>
                    )}

                {kycActionData.rejected ? (
                    <>
                        {kycActionData.transferRejected ? (
                            <InfoMessage>
                                {t(
                                    "Votre compte a été bloqué en raison d'information de profils non conformes."
                                )}
                            </InfoMessage>
                        ) : (
                            <InfoMessage>{props.info}</InfoMessage>
                        )}
                    </>
                ) : (
                    <>{kycActionData.toReview && <ToReviewBanner />}</>
                )}

                {props.children}
            </main>
        </>
    );
};

export default Main;
