import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./authSlice.ts";
import channelReducer from "./channelsSlice.ts";
import configSlice from "./configSlice.ts";
import counterReducer from "./counterSlice.ts";
import notificationReducer from "./notificationSlice.ts";
import positionSlice from "./positionSlice.ts";
import transferReducer from "./transferSlice.ts";
import uiReducer from "./uiSlice.ts";

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        auth: authReducer,
        ui: uiReducer,
        transfer: transferReducer,
        channels: channelReducer,
        notification: notificationReducer,
        config: configSlice,
        position: positionSlice
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
