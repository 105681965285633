import { cn } from "../helpers";
import { SizeType, StatusType } from "../types";

import CheckSolidCircleIcon from "./icons/CheckSolidCircleIcon.tsx";
import LoCloseIcon from "./icons/LoCloseIcon.tsx";
import TfiReload from "./icons/TfiReload.tsx";

interface Props {
    className?: string;
    status:
        | StatusType.success
        | StatusType.pending
        | StatusType.failed
        | StatusType.toValidate;
    size?: SizeType;
}

const MatchStatusIcon = (props: Props) => {
    return (
        <>
            {props.status === StatusType.success && (
                <CheckSolidCircleIcon
                    size={props.size || "lg"}
                    className={cn("text-green-500", props.className)}
                />
            )}
            {props.status === StatusType.pending && (
                <TfiReload
                    size={props.size || "lg"}
                    className={cn("text-orange-500", props.className)}
                />
            )}
            {props.status === StatusType.failed && (
                <LoCloseIcon
                    size={props.size || "lg"}
                    className={cn("text-red-500", props.className)}
                />
            )}
        </>
    );
};

export default MatchStatusIcon;
