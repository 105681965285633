import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { cn, thousandFormater } from "../helpers";
import { RootState } from "../store";

interface Props {
    currency?: string;
    amount?: number;
    className?: string;
}

const FeesPreview = (props: Props) => {
    const { t } = useTranslation();
    const { fees } = useSelector((state: RootState) => state.transfer);

    return (
        <div
            className={cn("flex justify-end", props.className)}
            data-testid="fees-preview"
        >
            <div className="flex items-center space-x-9 text-sm text-gray-600">
                <span>{t("Frais")}</span>
                <span>
                    {thousandFormater(parseInt(fees.value || "0"))}{" "}
                    {props.currency || "Fcfa"}
                </span>
            </div>
        </div>
    );
};

export default FeesPreview;
