import { useCallback, useState } from "react";
import * as yup from "yup";

interface ErrorType {
    [key: string]: string;
}

const useShowSchemaValidationError = () => {
    const [errors, setErrors] = useState<ErrorType>({});

    const resetErrors = useCallback(() => {
        Object.keys(errors).length && setErrors({});
    }, [errors]);

    return {
        errors,
        setErrors,
        resetErrors,
        showErrors: useCallback(() => {
            return (validationErrors: yup.ValidationError) => {
                resetErrors();
                const validationErrorsMap: { [key: string]: string } = {};
                validationErrors.inner.forEach(error => {
                    if (error.path) {
                        validationErrorsMap[error.path] = error.message;
                    }
                });
                setErrors(validationErrorsMap);
            };
        }, [resetErrors])
    };
};

export default useShowSchemaValidationError;
