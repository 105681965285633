import { createContext } from "react";

export interface WizardContextStore {
    index: number;
    changeIndex: (index: number) => void;
}

const WizardContext = createContext<WizardContextStore | null>(null);

export default WizardContext;
