import { useCallback, useMemo } from "react";

import config from "../config";
import { auth } from "../helpers";

import useInactivity from "./useInactivity.ts";
import useLogout from "./useLogout.ts";
import useModal from "./useModal.ts";

const useAutomaticDisconnect = () => {
    const { isOpen, closeModal, openModal } = useModal();
    const logoutUser = useLogout();

    const showModal = useCallback(() => {
        window.setTimeout(() => {
            openModal();
        }, 200);
    }, [openModal]);

    const timeout = useMemo(() => {
        const sessionExpire = parseInt(config.auth.sessionExpireTime);

        return sessionExpire * 60 * 1000;
    }, []);

    useInactivity(timeout, () => {
        auth()?.isLogin && logoutUser(undefined, showModal);
    });

    return {
        isOpen,
        closeModal
    };
};

export default useAutomaticDisconnect;
