import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { toggleToast } from "../store/uiSlice.ts";
import { ToastTypeEnum } from "../types";

const useToast = () => {
    const dispatch = useDispatch();

    const successToast = useCallback(
        (
            title: string,
            description?: string,
            duration: undefined | number = undefined
        ) => {
            dispatch(
                toggleToast({
                    title,
                    description: description || "",
                    show: true,
                    type: ToastTypeEnum.success,
                    duration
                })
            );
        },
        [dispatch]
    );

    const errorToast = useCallback(
        (
            title: string,
            description?: string,
            duration: undefined | number = undefined
        ) => {
            dispatch(
                toggleToast({
                    title,
                    description: description || "",
                    show: true,
                    type: ToastTypeEnum.danger,
                    duration
                })
            );
        },
        [dispatch]
    );

    const warningToast = useCallback(
        (
            title: string,
            description?: string,
            duration: undefined | number = undefined
        ) => {
            dispatch(
                toggleToast({
                    title,
                    description,
                    show: true,
                    type: ToastTypeEnum.warning,
                    duration
                })
            );
        },
        [dispatch]
    );

    return {
        successToast,
        errorToast,
        warningToast
    };
};

export default useToast;
