import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { STORAGE } from "../constants";
import { phoneNumberWithIndicativeToObject, storage } from "../helpers";
import { RootState } from "../store";
import { updateTransferFormData } from "../store/transferSlice.ts";

import useAuth from "./useAuth.ts";

const useInitPayInNumber = () => {
    const { formData } = useSelector((state: RootState) => state.transfer);
    const { user, isLogin } = useAuth();
    const ref = useRef(false);

    const dispatch = useDispatch();

    useEffect(() => {
        const transferFormDataString = storage().getItem(
            STORAGE.TRANSFER_FORM_DATA
        );

        if (!ref?.current && isLogin && transferFormDataString === null) {
            if (
                user?.account?.transfer_phone_numbers?.length &&
                !formData.senderPhone.phoneNumber.length
            ) {
                ref.current = true;
                const phoneData = phoneNumberWithIndicativeToObject(
                    user?.phone_with_indicative || ""
                );

                dispatch(
                    updateTransferFormData({
                        ...formData,
                        senderPhone: {
                            indicative: phoneData.indicative,
                            phoneNumber: phoneData.phone
                        }
                    })
                );
            }
        }
    }, [
        dispatch,
        formData,
        formData.senderPhone.phoneNumber.length,
        isLogin,
        user?.account?.transfer_phone_numbers?.length,
        user?.phone_with_indicative
    ]);
};

export default useInitPayInNumber;
