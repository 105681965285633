const TransferISkeletonItem = () => {
    return (
        <>
            <div className="animate-pulse cursor-pointer text-gray-700 text-sm flex items-end justify-between text-transparent">
                <div>
                    <p className="h-2 bg-slate-200 rounded w-[125px] mb-4" />
                    <p className="h-2 bg-slate-200 rounded w-[62px] mb-1.5" />
                    <p className="h-2 bg-slate-200 rounded w-3/4" />
                </div>

                <div className="flex flex-col items-end text-end">
                    <p className="h-2 bg-slate-200 rounded w-[62px] mb-1.5" />
                    <p className="h-2 bg-slate-200 rounded w-[125px]" />
                </div>
            </div>

            <hr className="my-4" />
        </>
    );
};

export default TransferISkeletonItem;
