import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { WALLET_ENABLE_DISABLE } from "../../constants";
import useManageWalletChannels from "../../hooks/useManageWalletChannels.ts";

import { NotificationMessageType } from "./types.ts";

const TRANSFER_SUCCESS_MESSAGE = "transfer_pay_out_status_success";
const TRANSFER_FAIL_MESSAGE = "transfer_pay_out_status_failed";
const TRANSFER_CARD_ENABLE = "transfer-card-enable";
const TRANSFER_CARD_DISABLE = "transfer-card-disable";

const useNotificationMessage = (data: NotificationMessageType) => {
    const { t } = useTranslation();
    const { getCountryById } = useManageWalletChannels();

    return useMemo(() => {
        const keysMatch = data.key_correspondance;
        const country = data?.pay_channel?.country_id
            ? getCountryById(
                  data?.pay_channel_type === "in",
                  data?.pay_channel?.country_id
              )
            : null;
        const messageParameter = {
            wallet: data?.pay_channel?.name || "",
            pay: t(data.pay_channel_type === "out" ? "reception" : "émission"),
            country: country?.label
        };

        switch (data.text) {
            case TRANSFER_SUCCESS_MESSAGE:
                if (keysMatch?.name) {
                    return t(
                        "Transfert vers :name (:tel) est succès.",
                        keysMatch
                    );
                }
                return t("Transfert vers :tel est succès.", keysMatch);
            case TRANSFER_FAIL_MESSAGE:
                if (keysMatch?.name) {
                    return t(
                        "Transfert vers :name (:tel) a échoué.",
                        keysMatch
                    );
                }
                return t("Transfert vers :tel a échoué.", keysMatch);
            case WALLET_ENABLE_DISABLE:
                if (data.operation === "enable") {
                    return t(
                        ":wallet :country est à nouveau disponible en :pay",
                        messageParameter
                    );
                } else {
                    return t(
                        ":wallet :country est indisponible en :pay",
                        messageParameter
                    );
                }
            case TRANSFER_CARD_ENABLE:
                return t("La carte :card à été activée", {
                    card: data?.transfer_card?.card_number || ""
                });
            case TRANSFER_CARD_DISABLE:
                return t(
                    "Pour des raison de sécurité, votre carte :card a été bloquée.",
                    { card: data?.transfer_card?.card_number || "" }
                );
            default:
                return data.text;
        }
    }, [data, getCountryById, t]);
};

export default useNotificationMessage;
