import { useTranslation } from "react-i18next";

import Container from "../components/Container.tsx";
import AppLayout from "../components/layouts/AppLayout.tsx";
import AppLayoutSection from "../components/layouts/AppLayoutSection.tsx";
import AppLayoutTitle from "../components/layouts/AppLayoutTitle.tsx";
import Aside from "../components/layouts/Aside.tsx";
import AsideTitle from "../components/layouts/AsideTitle.tsx";
import SearchInput from "../components/SearchInput.tsx";
import usePageTitle from "../hooks/usePageTitle.ts";

const Collect = () => {
    const { t } = useTranslation();
    usePageTitle(t("Collect"));

    return (
        <AppLayout info={t("headerTitle1")}>
            <AppLayoutSection>
                <Container>
                    <AppLayoutTitle>{t("Créer une cotisation")}</AppLayoutTitle>
                </Container>
            </AppLayoutSection>

            <Aside>
                <AsideTitle>{t("Mes cotisations")}</AsideTitle>

                <SearchInput />
            </Aside>
        </AppLayout>
    );
};

export default Collect;
