import TabButton from "@ui/tab/TabButton.tsx";
import TabItem from "@ui/tab/TabItem.tsx";
import { ReactNode, useMemo, useState } from "react";

import TabContext from "../../../context/TabContext.ts";

interface Props {
    activeIndex: number | string;
    children: ReactNode;
}

const Tab = (props: Props) => {
    const [index, setIndex] = useState(props.activeIndex);

    const contextValues = useMemo(() => {
        return {
            index,
            changeIndex: (value: number | string) => {
                setIndex(value);
            }
        };
    }, [index]);

    return (
        <TabContext.Provider value={contextValues}>
            {props.children}
        </TabContext.Provider>
    );
};

export { TabButton, TabItem };

export default Tab;
