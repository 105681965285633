import { useTranslation } from "react-i18next";

import useSuspendModal from "../../hooks/useSuspendModal.ts";

import InfoModal from "./InfoModal.tsx";

const SuspendModal = () => {
    const { t } = useTranslation();
    const { isOpen, closeModal, reason } = useSuspendModal();

    return (
        <InfoModal
            message={t(
                "Votre compte a été suspendu pour la raison suivante: « :reason ». Pour réactiver votre compte, veuillez contacter le service client",
                { reason }
            )}
            isOpen={isOpen}
            closeModal={closeModal}
        />
    );
};

export default SuspendModal;
