import { useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

import { STORAGE } from "../constants";
import { auth, storage } from "../helpers";

import useAuth from "./useAuth.ts";

const REFRESH_SESSION_INTERVAL = 5;

const useRefreshSession = () => {
    const { reFetchAuthUser, isLogin } = useAuth();
    const { pathname } = useLocation();

    useEffect(() => {
        if (isLogin) {
            const timeout = REFRESH_SESSION_INTERVAL * 60 * 1000;

            const handleAction = () => {
                if (auth()?.isLogin) {
                    reFetchAuthUser();
                } else {
                    const lastIntervalTimer = storage().getItem(
                        STORAGE.INTERVAL
                    );

                    if (lastIntervalTimer) {
                        clearInterval(lastIntervalTimer);
                        storage().removeItem(STORAGE.INTERVAL);
                    }
                }
            };

            const newIntervalTimer = window.setInterval(handleAction, timeout);
            storage().setItem(STORAGE.INTERVAL, newIntervalTimer.toString());
        }
    }, [isLogin, reFetchAuthUser]);

    useLayoutEffect(() => {
        if (isLogin) {
            reFetchAuthUser();
        }
    }, [isLogin, pathname, reFetchAuthUser]);
};

export default useRefreshSession;
