const PAGES = {
    HOME: "/",
    LOGIN: "/login",
    REFRESH: "/refresh",
    REGISTER: "/register",
    FORGOT_PIN: "/forgot-pin",
    CONFIRM_ACCOUNT: "/confirm-account",
    RESET_PIN: "/reset-pin",
    SETTING: "/setting",
    SETTING_LOCATION: "/setting?location=manageNumber",
    TRANSFER: "/transfer",
    TRANSFER_DETAIL: "/transfer/:id",
    COLLECT: "/collect",
    NOTIFICATION: "/notification",
    PRIVACY_POLICY: "/privacy-policy",
    CORRECT_KYC: "/correct-kyc",
    SHARE: "/share"
};

export default PAGES;
