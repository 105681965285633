import { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import setupAxiosInterceptors from "../helpers/setupAxiosInterceptors.ts";
import { logout } from "../store/authSlice.ts";

import useToast from "./useToast.ts";

const useSetupAxiosInterceptors = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const axiosSetup = useRef(false);
    const { errorToast } = useToast();
    const [showError, setShowError] = useState(false);

    const dispatch = useDispatch();

    useLayoutEffect(() => {
        if (!axiosSetup.current) {
            axiosSetup.current = true;
            setupAxiosInterceptors(
                () => {
                    dispatch(logout());
                },
                () => {
                    setShowError(true);
                }
            );
        }
    }, [dispatch, errorToast, navigate, t]);

    return {
        errorTitle: t("Oups !!!"),
        errorMessage: t("Une erreur s'est produite."),
        showError,
        closeError: () => setShowError(false)
    };
};

export default useSetupAxiosInterceptors;
