import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import config, { ClientConfig } from "../config";
import { STORAGE } from "../constants";
import { storage } from "../helpers";

interface ConfigStoreType {
    client: ClientConfig;
}

const storeConfig = storage().getItem(STORAGE.CLIENT_CONFIG);

const initialState: ConfigStoreType = {
    client: storeConfig ? JSON.parse(storeConfig) : config
};

const configSlice = createSlice({
    name: "config",
    initialState,
    reducers: {
        changeLang: (state, action: PayloadAction<"fr" | "en">) => {
            state.client.locale = action.payload;
            state.client.fakerLocale = action.payload;
            storage().setItem(
                STORAGE.CLIENT_CONFIG,
                JSON.stringify(state.client)
            );
        }
    }
});

export const { changeLang } = configSlice.actions;

const configReducer = configSlice.reducer;

export default configReducer;
