import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ENDPOINTS from "../constants/endpoint.ts";
import { loadAuthServiceHost, loadTransferServiceHost } from "../helpers";
import { RootState } from "../store";
import { addChannels, ChannelCountryType } from "../store/channelsSlice.ts";

const useFetchCountryChannels = () => {
    const dispatch = useDispatch();
    const { list: channelCountries } = useSelector(
        (state: RootState) => state.channels
    );

    const fetchCountryChannel = useCallback(() => {
        loadTransferServiceHost();
        window.axios.get(ENDPOINTS.CHANNELS).then(response => {
            const data = response.data.data;
            const payInData = data.pay_in.map((item: ChannelCountryType) => {
                const formatItem = { ...item };
                delete formatItem.pay_in_channels;

                return {
                    ...formatItem,
                    channels: item.pay_in_channels || []
                };
            });

            const payOutData = data.pay_out.map((item: ChannelCountryType) => {
                const formatItem = { ...item };
                delete formatItem.pay_out_channels;

                return {
                    ...formatItem,
                    channels: item.pay_out_channels || []
                };
            });

            dispatch(
                addChannels({
                    payIn: payInData,
                    payOut: payOutData
                })
            );
            loadAuthServiceHost();
        });
    }, [dispatch]);

    useEffect(() => {
        if (!(channelCountries?.payIn && channelCountries.payOut)) {
            fetchCountryChannel();
        }
    }, [
        channelCountries?.payIn,
        channelCountries.payOut,
        dispatch,
        fetchCountryChannel
    ]);

    return {
        fetchCountryChannel
    };
};

export default useFetchCountryChannels;
