import Button from "@ui/Button.tsx";
import { Modal } from "@ui/Modal.tsx";
import { useTranslation } from "react-i18next";

import { ModalPropsType } from "../types";

const CardToWalletAvailable = (props: ModalPropsType) => {
    const { isOpen, closeModal } = props;
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            hiddeCloseIcon
            className="rounded-2xl"
        >
            <img
                className="mx-auto"
                src="/images/illustration-annonce.png"
                alt=""
            />

            <p className="text-sm mb-4 text-center">
                {t("Désormais envoyez de l'argent depuis votre")}{" "}
                <strong>{t("Carte bancaire").toLowerCase()}</strong> .
            </p>

            <p className="text-sm text-center mb-5">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                {t("Cliquez sur")}{" "}
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <strong>"{t("ajouter numéro/carte")}"</strong>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                {t("sur la page d'accueil, puis")} "{t("Carte bancaire")}"{" "}
                {t(
                    "ou rendez vous dans les paramètres. Votre profil doit être à jour pour profiter de ce service!"
                )}
            </p>

            <Button withAuto className="px-6 mx-auto" onClick={closeModal}>
                {t("D'accord")}
            </Button>
        </Modal>
    );
};

export default CardToWalletAvailable;
