import {
    Dropdown,
    DropdownButton,
    DropdownItem,
    DropdownItems
} from "@ui/Dropdown.tsx";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { cn } from "../../helpers";
import ChevronIcon from "../icons/ChevronIcon.tsx";
import SearchIcon from "../icons/SearchIcon.tsx";
import XMarkIcon from "../icons/XMarkIcon.tsx";

export interface SelectItemType {
    value: string;
    label: string;
    /* eslint-disable */
    [key: string]: any;
    /* eslint-enable */
}

export interface SelectGroupItemType {
    label: string;
    [key: string]: string | number | SelectItemType[];
    options: SelectItemType[];
}

export interface SelectProps {
    value: SelectItemType | null;
    onChange: (value: SelectItemType | null) => void;
    options?: Array<SelectItemType | SelectGroupItemType>;
    disabled?: boolean;
    isClearable?: boolean;
    isRequired?: boolean;
    placeholder?: string;
    onlyChevronOnRight?: boolean;
    className?: string;
    buttonClassName?: string;
    chevronContainerClassName?: string;
    noOptionMessage?: string;
    formatValue?: (value: SelectItemType) => React.ReactNode;
    formatDefaultValue?: (value?: string) => React.ReactNode;
    "data-testid"?: string;
}

export interface SelectItemProps {
    onClick: (value: SelectItemType | null) => void;
    item: SelectItemType;
}

export const SelectItem = (props: SelectItemProps) => (
    <DropdownItem>
        {({ active }) => (
            <button
                className={cn({
                    "w-full text-left cursor-pointer": true,
                    "rounded-md px-3 py-3": true,
                    "whitespace-nowrap text-gray-500": true,
                    "hover:bg-gray-100 hover:text-gray-700":
                        !props.item?.disable,
                    "cursor-not-allowed text-gray-300": props.item?.disable,
                    "bg-gray-100 text-gray-700": active && !props.item?.disable
                })}
                type="button"
                disabled={!!props.item?.disable}
                onClick={() =>
                    !props.item?.disable && props.onClick(props.item)
                }
            >
                {props.item.label}
            </button>
        )}
    </DropdownItem>
);

const Select = (props: SelectProps) => {
    const { t } = useTranslation();
    const {
        value = null,
        onChange,
        options = [],
        disabled,
        isClearable,
        placeholder = t("Select items..."),
        onlyChevronOnRight = false
    } = props;
    const [searchValue, setSearchValue] = useState("");

    const filterOptions = useMemo(() => {
        if (searchValue) {
            let result = options.map(item => {
                if (Array.isArray(item?.options)) {
                    return {
                        label: item.label,
                        options: item.options.filter((item: SelectItemType) =>
                            item.label
                                .toLowerCase()
                                .includes(searchValue.toLowerCase())
                        )
                    };
                }
                return item;
            });

            result = result.filter(item => {
                if (!("options" in item)) {
                    return item.label
                        .toLowerCase()
                        .includes(searchValue.toLowerCase());
                }
                return Array.isArray(item?.options) && item?.options?.length;
            });

            return result;
        }
        return options;
    }, [options, searchValue]);

    return (
        <Dropdown
            as="div"
            className={cn("relative", props.className)}
            data-testid={`custom-select${
                props["data-testid"] ? "-" + props["data-testid"] : ""
            }`}
        >
            <DropdownButton
                as="button"
                className={cn({
                    "relative w-full rounded-md border border-gray-300 text-left":
                        true,
                    "flex cursor-pointer flex-wrap justify-between": true,
                    "py-4 pl-4 text-sm focus:outline-none": true,
                    "focus:border-primary focus:ring focus:ring-primary/20":
                        true,
                    "bg-gray-50": !!disabled,
                    [props.buttonClassName || ""]: true
                })}
                disabled={!!disabled}
            >
                <>
                    <div
                        className={cn({
                            "flex-1 text-gray-400 lg:truncate": true,
                            "text-gray-500": !!value?.label,
                            "truncate ": !value
                        })}
                    >
                        {value ? (
                            <>
                                {props.formatValue
                                    ? props.formatValue(value)
                                    : value.label}
                            </>
                        ) : (
                            <>
                                {props.formatDefaultValue
                                    ? props.formatDefaultValue(placeholder)
                                    : placeholder}
                            </>
                        )}
                    </div>

                    {!props.formatDefaultValue && (
                        <div
                            className={cn(
                                "flex items-center flex-shrink",
                                props.chevronContainerClassName
                            )}
                            data-testid="custom-select-chevron-container"
                        >
                            {!onlyChevronOnRight && (
                                <>
                                    {value && isClearable && (
                                        <span
                                            data-testid="custom-select-clear"
                                            className="rounded-full ring-primary"
                                            onClick={e => {
                                                e.stopPropagation();
                                                onChange(null);
                                            }}
                                        >
                                            <XMarkIcon className="mx-2 p-0.5 text-gray-500" />
                                        </span>
                                    )}

                                    <div className="mx-1 h-full w-px border-l text-transparent">
                                        .
                                    </div>
                                </>
                            )}

                            <ChevronIcon
                                className={cn({
                                    "mx-2 text-gray-300 p-1": true
                                })}
                            />
                        </div>
                    )}
                </>
            </DropdownButton>

            <DropdownItems
                data-testid="custom-select-items"
                className="absolute mt-2 z-[9] w-[17rem] rounded-md border bg-white p-2 text-sm shadow-sm focus:outline-none"
            >
                <div className="relative">
                    <input
                        type="search"
                        aria-label="search"
                        role="searchbox"
                        className={cn(
                            "w-full rounded-md border-transparent bg-gray-100 text-gray-600 pl-8",
                            "focus:border-transparent focus:ring-0"
                        )}
                        placeholder="Rechercher..."
                        value={searchValue}
                        onKeyDown={e => {
                            e.stopPropagation();
                        }}
                        onChange={e => {
                            setSearchValue(e.target.value);
                        }}
                    />

                    <SearchIcon className="absolute left-2 top-[0.69rem] text-gray-400" />
                </div>

                <div className="mt-3 max-h-72 space-y-1 overflow-y-auto text-sm">
                    {filterOptions.length ? (
                        filterOptions.map((item, index) => (
                            <div key={index}>
                                {item?.options &&
                                Array.isArray(item.options) ? (
                                    <>
                                        <div className="font-medium mb-0.5">
                                            {item.label}
                                        </div>

                                        {item.options.map(
                                            (
                                                item: SelectItemType,
                                                index: number
                                            ) => (
                                                <SelectItem
                                                    key={index}
                                                    onClick={onChange}
                                                    item={item}
                                                />
                                            )
                                        )}
                                    </>
                                ) : (
                                    <SelectItem
                                        key={index}
                                        onClick={onChange}
                                        item={item as SelectItemType}
                                    />
                                )}
                            </div>
                        ))
                    ) : (
                        <div className="cursor-default px-3 py-2 font-medium text-gray-400">
                            {props.noOptionMessage
                                ? props.noOptionMessage
                                : "Pas d'option disponible"}
                        </div>
                    )}
                </div>
            </DropdownItems>
        </Dropdown>
    );
};

export default Select;
