import { useSelector } from "react-redux";

import { RootState } from "../store";

const useGpsPosition = () => {
    const { position } = useSelector((state: RootState) => state.position);

    return {
        position,
        country: null
    };
};

export default useGpsPosition;
