import React from "react";

interface Props {
    children: React.ReactNode;
}

const DirectionCircle = (props: Props) => {
    return (
        <div className="flex items-center font-bold text-gray-600 justify-center w-10 h-10 text-sm lg:text-base lg:h-16 lg:w-16 bg-gray-100 border rounded-full">
            {props.children}
        </div>
    );
};

export default DirectionCircle;
