import React from "react";
import { NavLink } from "react-router-dom";

import { cn } from "../../helpers";

interface Props {
    children?: React.ReactNode;
    to: string;
}

const MobileLink = (props: Props) => {
    return (
        <NavLink
            to={props.to}
            className={({ isActive }) => {
                return cn({
                    "relative text-gray-400 flex items-center": true,
                    "group px-3 pb-2 pt-3": true,
                    "hover:text-gray-700": true,
                    "text-gray-700": isActive
                });
            }}
        >
            {({ isActive }) => (
                <>
                    {props.children}

                    <span
                        className={cn({
                            "rounded-full inline-block h-[4px] bg-gray-700":
                                true,
                            "absolute bottom-0 left-0 right-0": true,
                            "hidden group-hover:inline-block": !isActive
                        })}
                    />
                </>
            )}
        </NavLink>
    );
};

export default MobileLink;
