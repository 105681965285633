import { useTranslation } from "react-i18next";

import useModal from "../hooks/useModal.ts";

import RemoveAccountModal from "./modals/remove-account-modal";
import SettingButton from "./SettingButton.tsx";

const RemoveAccountButton = () => {
    const { t } = useTranslation();
    const { isOpen, openModal, closeModal } = useModal();

    return (
        <>
            <SettingButton onClick={openModal} title={t("Supprimer le compte")}>
                {t("Supprimer définitivement le compte")}
            </SettingButton>

            <RemoveAccountModal isOpen={isOpen} closeModal={closeModal} />
        </>
    );
};

export default RemoveAccountButton;
