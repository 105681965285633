import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { STORAGE } from "../constants";
import { storage } from "../helpers";

import useRedirectConfirmAccount from "./useRedirectConfirmAccount.ts";
import useToast from "./useToast.ts";

const ACCOUNT_NOT_VALIDE_KEY = "account_not_validated";

const useShowInvalidAccountMessage = () => {
    const { t } = useTranslation();
    const { errorToast } = useToast();
    const { redirect } = useRedirectConfirmAccount();

    return useCallback(
        (phone: string, key: string) => {
            if (key === ACCOUNT_NOT_VALIDE_KEY && phone.length) {
                errorToast(
                    t("Échec de la connexion"),
                    t("Votre compte n'a pas encore été validé"),
                    10000
                );
                storage().setItem(STORAGE.CONFIRM_ACCOUNT_TEL, phone);
                redirect(phone);
            }
        },
        [errorToast, redirect, t]
    );
};

export default useShowInvalidAccountMessage;
