import Button from "@ui/Button.tsx";
import { Modal } from "@ui/Modal.tsx";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../store";
import { setStoreShowTransferLimitModal } from "../../store/uiSlice.ts";

const LimitTransferModalWithoutAccount = () => {
    const { t } = useTranslation();
    const { showTransferLimitModal } = useSelector(
        (state: RootState) => state.ui
    );
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
        dispatch(setStoreShowTransferLimitModal(false));
    }, [dispatch]);

    return (
        <Modal
            isOpen={showTransferLimitModal}
            closeModal={handleClose}
            hiddeCloseIcon
            className="rounded-2xl"
        >
            <div className="mx-auto w-[180px] h-[180px]">
                <img
                    src="/images/illustration-1.png"
                    className="object-center object-cover mb-5 w-[180px] mx-auto"
                    alt=""
                />
            </div>

            <p className="text-sm text-center mb-5">
                {t(
                    "Vos documents sont actuellement en cours de validation et le délai estimé est de"
                )}{" "}
                <strong>{t("24 heures ouvrées")}</strong>.{" "}
                {t("Nous vous remercions de votre patience !")}
            </p>

            <Button withAuto className="px-6 mx-auto" onClick={handleClose}>
                {t("Compris")}
            </Button>
        </Modal>
    );
};

export default LimitTransferModalWithoutAccount;
