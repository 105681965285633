import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import ENDPOINTS from "../constants/endpoint.ts";
import { loadTransferServiceHost } from "../helpers";
import { RootState } from "../store";
import {
    addCurrentTransfer,
    clearCurrentTransfer
} from "../store/transferSlice.ts";
import { closeTransfer, openTransfer } from "../store/uiSlice.ts";
import { TransferType } from "../types";

const useTransferModal = () => {
    const { currentTransfer } = useSelector(
        (state: RootState) => state.transfer
    );
    const { transfer } = useSelector((state: RootState) => state.ui);
    const dispatch = useDispatch();

    const updateTransfer = useCallback(
        (id: number) => {
            if (id) {
                loadTransferServiceHost();
                window.axios
                    .get(
                        ENDPOINTS.TRANSFER_DETAIL.replace(
                            ":id",
                            id.toString() || ""
                        )
                    )
                    .then(response => {
                        dispatch(addCurrentTransfer(response.data.data));
                    });
            }
        },
        [dispatch]
    );

    return {
        transfer: currentTransfer,
        isOpen: transfer.show,
        openTransferModal: (transferValue: TransferType) => {
            dispatch(openTransfer());
            dispatch(addCurrentTransfer(transferValue));
        },
        closeTransferModal: () => {
            dispatch(closeTransfer());
            dispatch(clearCurrentTransfer());
        },
        updateTransfer
    };
};

export default useTransferModal;
